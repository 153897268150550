import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add country dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

///////API///////
import { adminBackgroundDistData } from '../../../modals/adminbackground'
import { insertDist } from '../../../modals/admindistinfo';

/* color */
const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

function EditDist() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [distShow, setDistShow] = useState(false)

  const [maxDistID, setMaxDistID] = useState()
  const [distData, setDistData] = useState([])

  const [formData, setFormData] = useState(
    {
      DIS_ID: '',
      DIS_NAME: '',
      DIS_DESC: ''
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    maxDistIDCal()

  }, [])

  const maxDistIDCal = async () => {
    setLoading(true)
    setFormData([])
    let distIDData = await adminBackgroundDistData()
    if (distIDData) {
      let distID = distIDData.data.data.adminBackgroundDistData
      let idList = distID.map((id) => id.DIS_ID.replace("DIS-", ""))
      let maxID = Math.max(...idList)
      let finalMaxID = maxID + 1
      let maxIDString = ''
      for (let i = 0; i < 4; i++) {
        if (finalMaxID < 10) { maxIDString = '00' + finalMaxID }
        else if (finalMaxID < 99) { maxIDString = '0' + finalMaxID }
        else maxIDString = (finalMaxID) + ''
        setMaxDistID('DIS-' + maxIDString)
        console.log('Max ID => ', maxIDString)
      }
      setDistData(distID)
    }
    setLoading(false)
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showHandle = (home, dist) => {
    setHomeShow(home)
    setDistShow(dist)
  }

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminDist', 'false')
  }

  const save = async () => {
    let valid = !formData.DIS_NAME ? 'Please Fill District Name!' : 'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        DIS_ID: maxDistID,
        DIS_NAME: formData.DIS_NAME,
        DIS_DESC: formData.DIS_DESC
      }
      const res = await insertDist(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new district");
        maxDistIDCal()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (
    <>
    <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminDist') == 'true') && <Grid container >
        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
          <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                Go Back
              </Button>
              <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                Add New District
              </Button>

              {/* dialog */}
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
              /*  onClose={handleClose} */
              >
                <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New District</DialogTitle>
                <DialogContent>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      m: 'auto',
                      width: 'fit-content',
                    }}>

                    {/* list */}
                    <Grid container
                      direction="column"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={6} md={3} margin={'10px'} marginTop={'30px'} >
                        <TextField disabled
                          defaultValue={maxDistID} id="outlined-basic" label="Dis Code" variant="outlined" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} margin={'10px'}>
                        <TextField required id="outlined-basic" label="Dis Name" variant="outlined" 
                        onChange={e => {
                          setFormData({ ...formData, DIS_NAME: e.target.value })
                        }} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} margin={'10px'}>
                        <TextField id="outlined-basic" label="Dis Description" variant="outlined" 
                        onChange={e => {
                          setFormData({ ...formData, DIS_DESC: e.target.value })
                        }} />
                      </Grid>
                    </Grid>

                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                  <Button onClick={save} variant="contained" color="success">Save</Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          </Stack>
        </Grid>

        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
          <Stack direction="row" spacing={2} justifyContent={'center'} >
            <TableContainer style={{ width: '93%' }} >
              <Table
                aria-label="spanning table"
                size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Dis Code</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Dis Name</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Dis Description</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Delete</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody >

                {distData.length ?
                      distData.map((row) => (
                        <TableRow>
                          <TableCell align="center" >{row.DIS_ID}</TableCell>
                          <TableCell align="center" >{row.DIS_NAME}</TableCell>
                          <TableCell align="center" >{row.DIS_DESC}</TableCell>
                          <TableCell align="center">
                            <IconButton >
                              <EditIcon style={{ color: "#41273b" }} />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton >
                              <DeleteIcon style={{ color: "#d91d4c" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                      : null}

                </TableBody>



              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid >}



    </>
  )
}

export default EditDist