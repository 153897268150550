import React from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add div dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

const theme = createTheme({
    palette: {
      primary: {
        main: '#41273b',
      
      },
      secondary: {
        main: '#d91e4d',
      },
    },
  });

function AddProject() {
/* dialog */
const [open, setOpen] = React.useState(false);
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth, setMaxWidth] = React.useState('sm');
const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};


/* nev */
const navigate = useHistory();

const GoHome = async () => {
  navigate.push('/') 
}

  return (
    <>
    <Grid container >
     <Grid item xs={12} ms={12} md={12} textAlign='center' style={{textAlign:'center',marginTop:'20px',marginRight:'3%'}}>
     <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{margin:'10px'}}>
      <ThemeProvider theme={theme}>
       <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
       Home
     </Button>
     <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
       Add New Project
     </Button>
 
     {/* dialog */}
     <Dialog
         fullWidth={fullWidth}
         maxWidth={maxWidth}
         open={open}
         onClose={handleClose}
       >
         <DialogTitle align='center' style={{backgroundColor:'#41273b',color:'#f8dadd'}}>Add New Project</DialogTitle>
         <DialogContent>
          <Box
             noValidate
             component="form"
             sx={{
               display: 'flex',
               flexDirection: 'row',
               m: 'auto',
               width: 'fit-content',}}>


    {/* list */}
    <Grid container 
    direction="row"
   justifyContent="center"
   alignItems="center">
     <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'} >
     <TextField 
         id="outlined-basic" label="Donor Code" variant="outlined" />
     </Grid>
     <Grid item xs={12} sm={12} md={12} margin={'10px'}>
     <TextField id="outlined-basic" label="Donor Name" variant="outlined" />
     </Grid>
     <Grid item xs={12} sm={12} md={12} margin={'10px'}>
     <TextField id="outlined-basic" label="Donor ShortName" variant="outlined" />
     </Grid>
     <Grid item xs={12} sm={12} md={12} margin={'10px'}>
     <TextField id="outlined-basic" label="Donor ShortName" variant="outlined" />
     </Grid>


     </Grid>
     
     <Grid container 
    direction="row"
   justifyContent="center"
   alignItems="center">
    <Grid item xs={12} sm={12} md={12} margin={'10px'}>
     <TextField id="outlined-basic" label="Grant Name" variant="outlined" type='number' 
     InputProps={{
         inputProps: { min: 0 }
       }}/>
     </Grid>
     <Grid item xs={12} sm={12} md={12} margin={'10px'}>
     <TextField id="outlined-basic" label="START_DATE" variant="outlined" type='number' 
     InputProps={{
         inputProps: { min: 0 }
       }}/>
     </Grid>
     <Grid item xs={12} sm={12} md={12} margin={'10px'}>
     <TextField id="outlined-basic" label="END_DATE" variant="outlined" type='number' 
     InputProps={{
         inputProps: { min: 0 }
       }}/>
     </Grid>
     </Grid> 
 
           </Box>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleClose} variant="contained" color="error">Close</Button>
           <Button onClick={handleClose} variant="contained" color="success">Save</Button>
         </DialogActions>
       </Dialog>
     </ThemeProvider>
   </Stack>
        </Grid>
 
        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{textAlign:'center',marginTop:'20px'}} >
   <Stack direction="row" spacing={2} justifyContent={'center'} >
   <TableContainer style={{width:'93%'}} >
                 <Table 
                     aria-label="spanning table"
                     size='small'>
                     <TableHead>
                         <TableRow>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Donor Code</TableCell>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Donor Name</TableCell>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Donor ShortName</TableCell>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Grant Name</TableCell>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>START_DATE</TableCell>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>END_DATE</TableCell>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Delete</TableCell>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                             <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Delete</TableCell>
                             
                         </TableRow>
                     </TableHead>
 
                     <TableBody >
                  
                                 <TableRow>
                                     <TableCell align="center" >D-990</TableCell>
                                     <TableCell align="center" >Secondary Level</TableCell>
                                     <TableCell align="center" >HPTL</TableCell>
                                     <TableCell align="center" >-</TableCell>
                                     <TableCell align="center" >01-Aug-21</TableCell>
                                     <TableCell align="center" >01-Aug-23</TableCell>
                                     <TableCell align="center" >01-Aug-23</TableCell>
                                    <TableCell align="center">
                                          <IconButton >
                                                 <EditIcon style={{ color: "#41273b" }} />
                                             </IconButton>
                                     </TableCell>
                                     <TableCell align="center">
                                        <IconButton >
                                                 <DeleteIcon style={{ color: "#d91d4c" }} />
                                                 </IconButton>
                                     </TableCell>
                                 </TableRow>
 
                           </TableBody>
 
 
 
                 </Table>
             </TableContainer>
             </Stack>
 </Grid>
     </Grid>
 
 
 
    </>
  )
}

export default AddProject