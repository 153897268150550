import React, { useState } from "react";
import "./SearchBar.css";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Grid, List, ListItem } from "@material-ui/core";
import CustomPatientTable from './CustomPatientTable'
import * as edit from "../../modals/editShow"
import Modals from "../../components/modal";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

function PatientSearchBar({ searchData,nameData, tableData }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [filteredNameData, setFilteredNameData] = useState([]);
  const [wordNameEntered, setWordNameEntered] = useState("");
  //const [tableData, setTableData] = useState(tableData)
  const [selectedPatient, setSelectedPatient] = useState([])
  const [selectedPatientID, setSelectedPatientID] = useState("")
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('1')

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    const newFilter = searchData.filter((value) => {
      return (value.PATIENTNAME.toLowerCase() + value.REGID.toLowerCase()).includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
    setSelectedPatient([])

  };

  /* const handleNameFilter = (event) => {
    const searchWord = event.target.value;
    setWordNameEntered(searchWord);
    
    const newFilter = nameData.filter((value) => {
      return (value.PATIENTNAME.toLowerCase()).includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredNameData([]);
    } 
    else {
      setFilteredNameData(newFilter);
    }
    setSelectedPatient([])

  }; */
  const handleNameEnter = async(event) => {
    console.log('enter key => ', event.target.value)
    setLoading(true)
    const a = {regName: event.target.value,orgID:sessionStorage.getItem('org')}
    let patient = await edit.getSearchPatientName(a)
    if (patient) setSelectedPatient(patient.data.data.getSearchPatientName)
    setLoading(false)
  }

  const clearInput = () => {
    setSelectedPatient([]);
    setFilteredData([]);
    setWordEntered("");
    setFilteredNameData([]);
    setWordNameEntered("");
  };

  const patientClickHandle = async (e) => {
    //console.log(e.target.outerText)
    setLoading(true)
    const name = e.target.outerText
    const patientName = await name.substring(0, (name.length - 12));
    const patientID = await name.substring((patientName.length + 2), (name.length - 1))
    await setSelectedPatientID(patientID)
    await setWordEntered(patientName)
    await setFilteredData([])
    await sessionStorage.setItem('searchPatientBtn', patientID)
    let patient = await edit.getSearchPatient()
    if (patient) setSelectedPatient(patient.data.data.getSearchPatient)
    setLoading(false)
  }

  const patientNameClickHandle = async (e) => {
    console.log(e.target.outerText)
    setLoading(true)
    const name = e.target.outerText
    const patientName = await name
    await setSelectedPatientID('')
    await setWordEntered(patientName)
    await setFilteredNameData([])
    //await sessionStorage.setItem('searchPatientBtn', patientID)
    const a = {regName: name,orgID:sessionStorage.getItem('org')}
    let patient = await edit.getSearchPatientName(a)
    if (patient) setSelectedPatient(patient.data.data.getSearchPatientName)
    setLoading(false)
  }

  const searchButtonHandle = async () => {
    await setLoading(true)
    sessionStorage.setItem('searchPatientBtn', selectedPatientID)
    let patient = await edit.getSearchPatient()
    if (patient) await setSelectedPatient(patient.data.data.getSearchPatient)
    await setLoading(false)
    console.log("SelectPatient => ", selectedPatient)
  }

  return (

    <div>
      <Modals open={loading} />
      <Grid container spacing={1} style={{ paddingLeft: '1%', paddingRight: '1%', marginBottom: '10px' }}>
        <Grid item xs={1} style={{marginTop:'13px',marginBottom:'5px'}}>
          <FormControl variant="standard" style={{width:'100%'}} >
            <InputLabel id="demo-simple-select-filled-label">Search Type</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={type}
              onChange={e => {setType(e.target.value);
                setSelectedPatient([]);
                setFilteredData([]);
                setWordEntered("");
                setFilteredNameData([]);
                setWordNameEntered("");
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                style: {
                  maxHeight: 300,
                },
                getContentAnchorEl: null
              }}>
              <MenuItem value={'1'}>ID</MenuItem>
              <MenuItem value={'2'}>Name</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {type==='1' ? 
        <>
        <Grid item xs={11} style={{marginTop:'20px',marginBottom:'5px'}}>
          <div style={{ display: 'flex' }}>
            <input
              style={{ width: '100%', height: '40px' }}
              type="text"
              placeholder='Enter Patient ID...'
              value={wordEntered}
              onChange={handleFilter}
            />
            {filteredData.length === 0 ? (
              <SearchIcon style={{ placeSelf: 'center' }} />
            ) : (
              <CloseIcon style={{ placeSelf: 'center', alignSelf: 'center' }} id="clearBtn" onClick={clearInput} />
            )}
          </div>


        </Grid>

        {filteredData.length != 0 && (
          <div className="dataResult">
            {filteredData.slice(0, 15).map((value, key) => {
              return (
                <List >
                  <ListItem button onClick={patientClickHandle} >{value.PATIENTNAME + " " + " (" + value.REGID + ")"} </ListItem>
                </List>
              );
            })}
          </div>
        )}
        <CustomPatientTable searchData={selectedPatient} tdata={tableData} />
        </> : 
        <>
        <Grid item xs={11} style={{marginTop:'20px',marginBottom:'5px'}}>
          <div style={{ display: 'flex' }}>
            <input
              style={{ width: '100%', height: '40px' }}
              type="text"
              placeholder='Enter Patient Name...'
              /* value={wordNameEntered}
              onChange={e => setWordEntered(e.target.value)} */
              onKeyDown={e => e.key === 'Enter' && handleNameEnter(e)}/>
           {/*  {filteredNameData.length === 0 ? (
              <SearchIcon style={{ placeSelf: 'center' }} />
            ) : (
              <CloseIcon style={{ placeSelf: 'center', alignSelf: 'center' }} id="clearBtn" onClick={clearInput} />
            )} */}
          </div>


        </Grid>

      {/*   {filteredNameData.length != 0 && (
          <div className="dataResult">
            {filteredNameData.slice(0, 15).map((value, key) => {
              return (
                <List >
                  <ListItem button onClick={patientNameClickHandle} >{value.PATIENTNAME} </ListItem>
                </List>
              );
            })}
          </div>
        )} */}
        <CustomPatientTable searchData={selectedPatient} tdata={tableData} />
        </>}
      </Grid> 
      
     
      
      
    </div>
  );
}

export default PatientSearchBar;