
import React, { useEffect, useState } from 'react'

import { Button, createTheme, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, ThemeProvider, Typography } from '@material-ui/core'

import CustomUnicefTextField from '../../components/controls/CustomUnicefTextField'
import CustomSnackBar from "../../components/controls/CustomSnackBar";
import Modals from "../../components/modal";
import XLSX from 'xlsx'
import moment from 'moment';

/////////////////API////////////////////////
import { exportCFRM } from '../../modals/exportcfrm';
import { exportHE } from '../../modals/exporthe';
import { getAllHEDivision, getHETsp } from '../../modals/hedata'

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      flexGrow: 1
    }
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#fcf0f2"
  },
  fontSize: {
    "& span:last-child": {
      fontSize: 13
    }
  },
  cardStyle: {

    marginTop: theme.spacing(0.9),
    marginBottom: theme.spacing(1),
  },
  cardStyleTwo: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.9),
    borderRadius: "3px"
  },
  formControl: {
    margin: theme.spacing(1),
  },

  table: {
    width: '100%',
    background: '#fcf0f2',
    height: '10px',


  },
  cellOne: {
    borderBottom: 'none',
    color: '#808080',
    fontSize: '9pt',

  },
  cellTwo: {
    borderBottom: 'none',
    color: '#53344d',
    fontSize: '12pt',
    fontWeight: 'bold',


  },
  selected: {
    backgroundColor: "#DED4DA !important",
    color: '#482642'
  }
}));

const radioTheme = createTheme({
  palette: {
    primary: {
      main: "#482642"
    },
    secondary: {
      main: "#ffff"
    }
  }
});

export default function ExportHE() {

  const classes = useStyles();

  const [typee, setType] = useState(1)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [state, setState] = useState('');
  const [stateID,setStateID] = useState('')
  const [tsp, setTsp] = useState([]);
  const [selectedTsp, setSelectedTsp] = useState('')
  const [loading, setLoading] = useState(false)
  const [select, setSelect] = useState([])
  const [error, setError] = useState('')
  const [openSnack, setOpenSnack] = useState(false)

  const tspHandle = async (event) => {
    setSelectedTsp(event.target.value);
  };

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const exportBtn = async () => {
    const a = {
      orgID: sessionStorage.getItem('org'),
      projID: sessionStorage.getItem('project'),
      HE_DIV: stateID,
      HE_TSP: selectedTsp,
      sDate: startDate,
      eDate: endDate,
    }
    var allTableRes = []
    let valid = !startDate ? "Please Choose Start Date" :
      !endDate ? "Please Choose End Date" :
        /* (sessionStorage.getItem('org')==='CPI-165' && !selectedTsp) ? "Please Choose Township" : */
          'valid';
    if (valid === 'valid') {
      setLoading(true)
      var wb = await XLSX.utils.book_new();
      allTableRes = await exportHE(a);

      if (allTableRes) {
        console.log("Table res =>", allTableRes)
        var cfrm = await XLSX.utils.json_to_sheet(allTableRes.data.data.exportHE);
        XLSX.utils.book_append_sheet(wb, cfrm, "HE");
      }
      else {
        var empty = []
        XLSX.utils.book_append_sheet(wb, empty, "Empty");
      }
      XLSX.writeFile(wb, "InfoMx_HE_ExportDataset_" + `${moment(new Date()).format('DD-MM-YYYY')}` + ".xlsx");
      setLoading(false)
    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  const clear = () => {
    setStartDate('')
    setEndDate('')
  }

  useEffect(async () => {
    setLoading(true)
    let a = { orgID: sessionStorage.getItem('org'), projID: sessionStorage.getItem('project') }
    let div = await getAllHEDivision(a);
    if (div) {
      setState(div.data.data.getAllHEDivision[0].STATEREGION)
      setStateID(div.data.data.getAllHEDivision[0].DIV_ID)
      console.log('===.', div)
      /* const a =
      {
        projID: sessionStorage.getItem('project'),
        orgID: sessionStorage.getItem('org'),
        divID: div.data.data.getAllHEDivision[0].DIV_ID
      }
      let tspData = await getHETsp(a)
      if(tspData)
      {
        setTsp(tspData.data.data.getHETsp)
        console.log('===.tsp', tspData)
      } */
      
    }
    
    setLoading(false)

  }, [])

  return (
    <div style={{ width: '100%', height: '82.5vH', background: '#ffffff' }}>
      <Modals open={loading} />
      {openSnack && <CustomSnackBar open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
     
        <Typography variant="h5" align="center" style={{ color: '#53344d', background: '#ffffff', fontWeight: 'bold', padding: '1%' }}>
          Export Health Education </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '6%' }}>
          <Grid item xs={12} sm={6} md={3} >
            <CustomUnicefTextField
              id="filled-basic"
              type="date"
              style={{ width: '90%' }}
              label={<Grid row container><Typography color="#482642">Start Date</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642' },
                shrink: true
              }}
              onChange={e => setStartDate(e.target.value)}
              value={startDate} />
          </Grid>

          <Grid item xs={12} sm={6} md={3} >
            <CustomUnicefTextField
              id="filled-basic"
              type="date"
              style={{ width: '90%' }}
              label={<Grid row container><Typography color="#482642">End Date </Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642' },
                shrink: true
              }}
              onChange={e => setEndDate(e.target.value)}
              value={endDate} />
          </Grid>

        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '30px' }}>
          <Grid item xs={12} sm={6} md={3} >
            <CustomUnicefTextField
              id="filled-basic"
              style={{ width: '90%' }}
              label={<Grid row container><Typography color="#482642">State/Region</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642' },
              }}
              disabled
              value={state} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3} >
          <ThemeProvider theme={radioTheme}>
            <FormControl variant="filled" style={{ width: '90%' }}>
              <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Township</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                style={{ width: '100%' }}
                value={selectedTsp}
                onChange={tspHandle}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  style: {
                    maxHeight: 400,
                  },
                  getContentAnchorEl: null
                }}>

                {tsp.length &&
                  tsp.map((option) => (
                    <MenuItem value={option.TSP_ID} key={option.TSP_ID} classes={{ selected: classes.selected }}>
                      {option.TSPNAME}
                    </MenuItem>
                  ))}

              </Select>
            </FormControl>
            </ThemeProvider>
          </Grid> */}
          
          
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid container alignItems="center" justifyContent="center" style={{ padding: '20px', background: '#ffffff', marginTop: '30px' }} row>
            <Grid item xs={2} sm={2} md={2} style={{ width: '13%' }}>
              <Button
                variant="contained"
                style={{ background: '#482642', color: '#fff', width: '50%' }}
                onClick={exportBtn}  >Export</Button>
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ width: '13%' }}>
              <Button
                variant="contained"
                style={{ background: '#482642', color: '#fff', width: '50%' }}
                onClick={clear} >Clear</Button>
            </Grid>
          </Grid>
        </Grid>
    </div>
  )
}