import React from 'react'
import AdminHome from './AdminHome'

function AdminPage() {
  return (
    <>
    <AdminHome/>
   
    </>
  )
}

export default AdminPage