import axios from 'axios';
import {api} from '../utils/helper';

//****************** Insert dist ******************//
export const insertDist = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertdist`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertDist controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertDist controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }


//****************** Update dist ******************//
export const updateDist = async (a) => {
    try{
        const resrep = await axios.put(`${api}/updatedist`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('updateDist controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('updateDist controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }

//****************** Delete dist ******************//
export const deleteDist = async (a) => {
    try{
        const resrep = await axios.put(`${api}/deletedist`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('deleteDist controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('deleteDist controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }