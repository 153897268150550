import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add div dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

///////API///////
import { adminBackgroundDivData } from '../../../modals/adminbackground'
import { insertDiv } from '../../../modals/admindivinfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

function EditDivision() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [divShow, setDivShow] = useState(false)

  const [maxDivID, setMaxDivID] = useState()
  const [divData, setDivData] = useState([])

  const [formData, setFormData] = useState(
    {
      DIV_ID: '',
      DIV_NAME: '',
      DIV_SHORTNAME: '',
      DIV_NAMEMM: '',
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    maxDivIDCal()

  }, [])

  const maxDivIDCal = async () => {
    setLoading(true)
    setFormData([])
    let divIDData = await adminBackgroundDivData()
    if (divIDData) {
      let divID = divIDData.data.data.adminBackgroundDivData
      let idList = divID.map((id) => id.DIV_ID.replace("DIV-", ""))
      let maxID = Math.max(...idList)
      //console.log('Max ID => ',maxID+1)
      setDivData(divID)
      setMaxDivID('DIV-' + (maxID + 1))
    }
    setLoading(false)
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showHandle = (home, ctry) => {
    setHomeShow(home)
    setDivShow(ctry)
  }

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminDiv', 'false')
  }

  const save = async () => {
    let valid = !formData.DIV_NAME ? 'Please Fill Division Name!' : 
    !formData.DIV_SHORTNAME ? 'Please Fill Division Short Name!' :'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        DIV_ID: maxDivID,
        DIV_NAME: formData.DIV_NAME,
        DIV_NAMEMM: formData.DIV_NAMEMM,
        DIV_SHORTNAME: formData.DIV_SHORTNAME
      }
      const res = await insertDiv(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new division");
        maxDivIDCal()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (
    < >
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminDiv') == 'true') &&
        <Grid container >
          <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
            <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
              <ThemeProvider theme={theme}>
                <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                  Go Back
                </Button>
                <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                  Add New Division
                </Button>

                {/* dialog */}
                <Dialog
                  fullWidth={fullWidth}
                  maxWidth={maxWidth}
                  open={open}
                /* onClose={handleClose} */
                >
                  <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New Division</DialogTitle>
                  <DialogContent>
                    <Box
                      noValidate
                      component="form"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                      }}>

                      {/* list */}
                      <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item xs={12} sm={6} md={3} margin={'10px'} marginTop={'30px'} >
                          <TextField disabled
                            defaultValue={maxDivID} id="outlined-basic" label="Div Code" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} margin={'10px'}>
                          <TextField required id="outlined-basic" label="Div Name" variant="outlined"
                          value={formData.DIV_NAME}
                            onChange={e => {
                              setFormData({ ...formData, DIV_NAME: e.target.value })
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} margin={'10px'}>
                          <TextField required id="outlined-basic" label="Division Short Name" variant="outlined"
                          value={formData.DIV_SHORTNAME}
                            onChange={e => {
                              setFormData({ ...formData, DIV_SHORTNAME: e.target.value })
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} margin={'10px'}>
                          <TextField id="outlined-basic" label="Div NameMM" variant="outlined"
                          value={formData.DIV_NAMEMM}
                            onChange={e => {
                              setFormData({ ...formData, DIV_NAMEMM: e.target.value })
                            }} />
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                    <Button onClick={save} variant="contained" color="success">Save</Button>
                  </DialogActions>
                </Dialog>
              </ThemeProvider>
            </Stack>
          </Grid>

          <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
            <Stack direction="row" spacing={2} justifyContent={'center'} >
              <TableContainer style={{ width: '93%', maxHeight: '59.5vh', }} >
                <Table
                  stickyHeader
                  aria-label="spanning table"
                  size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Div Code</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Div Name</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Div Short Name</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Div NameMM</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody >
                    {divData.length ?
                      divData.map((row) => (
                        <TableRow>
                          <TableCell align="center" >{row.DIV_ID}</TableCell>
                          <TableCell align="center" >{row.DIV_NAME}</TableCell>
                          <TableCell align="center" >{row.DIV_SHORTNAME}</TableCell>
                          <TableCell align="center" >{row.DIV_NAMEMM}</TableCell>
                          <TableCell align="center">
                            <IconButton >
                              <EditIcon style={{ color: "#41273b" }} />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton >
                              <DeleteIcon style={{ color: "#d91d4c" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>}

    </>
  )
}

export default EditDivision