import React from 'react'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

function AddDonor() {
  return (
    <>
    <Grid container  direction="row"
    justifyContent="space-evenly"
    alignItems="center"
    marginTop={"30px"}>
     <Grid item xs={12} sm={6} md={3} margin={'10px'}  >
     <TextField disabled
          defaultValue="P-033" id="outlined-basic" label="Donor Code" variant="outlined" />
     </Grid>

     <Grid item xs={12} sm={6} md={3} margin={'10px'}>
    <TextField id="outlined-basic" label="Donor Name" variant="outlined" />
    </Grid>
     
    <Grid item xs={12} sm={6} md={3} margin={'10px'}>
    <TextField id="outlined-basic" label="Donor ShortName" variant="outlined" />
    </Grid>
     
    <Grid item xs={12} sm={6} md={3} margin={'10px'}>
    <TextField id="outlined-basic" label="Grant Name" variant="outlined" />
    </Grid>
     
     
     
     
     
     
     </Grid>

     </>
  )
}

export default AddDonor