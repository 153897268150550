import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add div dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

///////API///////
import { adminBackgroundDivData, adminBackgroundTspDivData, adminBackgroundVillageData } from '../../../modals/adminbackground'
import { insertVillage } from '../../../modals/adminvillageinfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

const options = [
  { value: 'on', label: 'one' },
  { value: 'tw', label: 'two' },
  { value: 'thr', label: 'three' },
  { value: 'onon', label: 'oneone' },
]

function EditVillage() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [vilShow, setVilShow] = useState(false)

  const [maxVillageID, setMaxVillageID] = useState()
  const [villageData, setVillageData] = useState([])
  const [divData, setDivData] = useState([])
  const [selectedDiv, setSelectedDiv] = useState('')
  const [tspData, setTspData] = useState([])
  const [selectedTsp, setSelectedTsp] = useState('')

  const [formData, setFormData] = useState(
    {
      VILLAGE_CODE: '',
      VILLAGE_NAME: '',
      VILLAGE_NAMEMM: '',
      VILLAGE_MALEPOP: '',
      VILLAGE_FEMALEPOP: '',
      VILLAGE_LATITUDE: '',
      VILLAGE_LONGITUDE: '',
      VILLAGE_HOUSEHOLD: '',
      TSP_ID: '',
      TSP_IDEHO: '',
      VILLAGE_STATUS: '',
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    fetchDivData()

  }, [])

  const fetchDivData = async () => {
    setLoading(true)
    setFormData([])
    setSelectedDiv('')
    setSelectedTsp('')
    setMaxVillageID('')
    let divIDData = await adminBackgroundDivData()
    let villageIDData = await adminBackgroundVillageData()
    if (divIDData && villageIDData) {
      let divID = divIDData.data.data.adminBackgroundDivData
      let villageID = villageIDData.data.data.adminBackgroundVillageData
      setVillageData(villageID)
      setDivData(divID)
    }
    setLoading(false)
  }

  const divisionHandle = async (e) => {
    let div = e.target.value
    setSelectedDiv(div)
    setMaxVillageID('')
    //console.log('div=>', div)
    let a = { divID: div }
    let tspIDData = await adminBackgroundTspDivData(a)
    if (tspIDData) {
      let tsp = tspIDData.data.data.adminBackgroundTspDivData
      //console.log('tsp => ', tsp)
      setTspData(tsp)
    }
  }

  const tspHandle = async (e) => {
    let tsp = e.target.value
    setSelectedTsp(tsp)
    let tspRemove = tsp.indexOf("-TSP-")
    let firstWord = tsp.slice(0, tspRemove)
    let secondWord = tsp.slice((tspRemove) + 5, tsp.length)
    //console.log('village => ', firstWord+'-'+secondWord+'-VIL-')
    let tspIDCompare = firstWord + '-TSP-' + secondWord
    let villageFilterData = villageData.filter((data) => { return data.TSP_ID === tspIDCompare })
    //console.log('Village Filter Array =>',villageFilterData)
    let villageStringCode = firstWord + '-' + secondWord + '-VIL-'
    let idList = villageFilterData.map((id) => id.VILLAGE_CODE.replace(villageStringCode, ""))
    let maxID = Math.max(...idList)
    //console.log('Max ID => ',maxID+1)
    //console.log('village => ', villageStringCode + (maxID + 1))
    setMaxVillageID(villageStringCode + (maxID + 1))

  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const showHandle = (home, ctry) => {
    setHomeShow(home)
    setVilShow(ctry)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminVil', 'false')
  }

  const save = async () => {
    let valid = (!selectedDiv ) ? 'Please select Division!' :
    ( !selectedTsp ) ? 'Please select Township!' :
    !formData.VILLAGE_NAME ? 'Please Fill Village Name!' : 'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        VILLAGE_CODE: maxVillageID,
      VILLAGE_NAME: formData.VILLAGE_NAME,
      VILLAGE_NAMEMM: formData.VILLAGE_NAMEMM,
      VILLAGE_MALEPOP: formData.VILLAGE_MALEPOP,
      VILLAGE_FEMALEPOP: formData.VILLAGE_FEMALEPOP,
      VILLAGE_LATITUDE: formData.VILLAGE_LATITUDE,
      VILLAGE_LONGITUDE: formData.VILLAGE_LONGITUDE,
      VILLAGE_HOUSEHOLD: formData.VILLAGE_HOUSEHOLD,
      TSP_ID: selectedTsp,
      TSP_IDEHO: formData.TSP_IDEHO,
      VILLAGE_STATUS: formData.VILLAGE_STATUS,
      }
      const res = await insertVillage(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new village");
        fetchDivData()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (
    <>
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminVil') == 'true') && <Grid container >
        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
          <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                Go Back
              </Button>
              <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                Add New Village
              </Button>

              {/* dialog */}
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
              /* onClose={handleClose} */
              >
                <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New Village</DialogTitle>
                <DialogContent>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      m: 'auto',
                      width: 'fit-content',
                    }}>

                    {/* list */}
                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "90%" }}
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              DIV id
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={selectedDiv}
                              onChange={(e) => {
                                divisionHandle(e);
                              }}
                              MenuProps={{
                                style: {
                                  maxHeight: 400,
                                },
                              }}>
                              {divData.length &&
                                divData.map((option) => (
                                  <MenuItem value={option?.DIV_ID}>
                                    {option?.DIV_NAME}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "90%" }}
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              TSP id
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              disabled={!selectedDiv}
                              value={selectedTsp}
                              onChange={(e) => {
                                tspHandle(e);
                              }}
                            >
                              {tspData.length &&
                                tspData.map((option) => (
                                  <MenuItem value={option?.TSP_ID}>
                                    {option?.TSP_NAME}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}  >
                        <TextField disabled
                          value={maxVillageID} id="outlined-basic" label="VILLAGE_CODE" variant="outlined" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField required id="outlined-basic" label="VILLAGE_NAME" variant="outlined"
                          value={formData.VILLAGE_NAME}
                          onChange={e => {
                            setFormData({ ...formData, VILLAGE_NAME: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="VILLAGE_NAMEMM" variant="outlined"
                          value={formData.VILLAGE_NAMEMM}
                          onChange={e => {
                            setFormData({ ...formData, VILLAGE_NAMEMM: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="VILLAGE_MALEPOP" variant="outlined"
                          value={formData.VILLAGE_MALEPOP}
                          onChange={e => {
                            setFormData({ ...formData, VILLAGE_MALEPOP: e.target.value })
                          }} />
                      </Grid>
                    </Grid>

                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'}>
                        <TextField id="outlined-basic" label="VILLAGE_FEMALEPOP" variant="outlined" 
                        type='number'
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                        value={formData.VILLAGE_FEMALEPOP}
                        onChange={e => {
                          setFormData({ ...formData, VILLAGE_FEMALEPOP: e.target.value })
                        }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="VILLAGE_LATITUDE" variant="outlined" 
                        type='number'
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          value={formData.VILLAGE_LATITUDE}
                          onChange={e => {
                            setFormData({ ...formData, VILLAGE_LATITUDE: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="VILLAGE_LONGITUDE" variant="outlined"
                        type='number'
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                          value={formData.VILLAGE_LONGITUDE}
                          onChange={e => {
                            setFormData({ ...formData, VILLAGE_LONGITUDE: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="VILLAGE_HOUSEHOLD" variant="outlined" 
                        type='number'
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          value={formData.VILLAGE_HOUSEHOLD}
                          onChange={e => {
                            setFormData({ ...formData, VILLAGE_HOUSEHOLD: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="TSP_IDEHO" variant="outlined"
                          value={formData.TSP_IDEHO}
                          onChange={e => {
                            setFormData({ ...formData, TSP_IDEHO: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "90%" }}
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              Village Status
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={formData.VILLAGE_STATUS}
                              onChange={e => {
                                setFormData({ ...formData, VILLAGE_STATUS: e.target.value })
                              }}
                            >
                              <MenuItem value={1}> Active </MenuItem>
                              <MenuItem value={0}> Not Active </MenuItem>
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                    </Grid>

                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                  <Button onClick={save} variant="contained" color="success">Save</Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          </Stack>
        </Grid>

        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
          <Stack direction="row" spacing={2} justifyContent={'center'} >
            <TableContainer style={{ width: '93%', maxHeight: '59.5vh', }} >
              <Table
                stickyHeader
                aria-label="spanning table"
                size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_CODE</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_NAME</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_NAMEMM</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_MALEPOP</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_FEMALEPOP</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_LATITUDE</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_LONGITUDE</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_HOUSEHOLD</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>TSP_ID</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>TSP_IDEHO</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>VILLAGE_STATUS</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody >

                  {villageData.length ?
                    villageData.map((row) => (
                      <TableRow>
                        <TableCell align="center" >{row.VILLAGE_CODE}</TableCell>
                        <TableCell align="center" >{row.VILLAGE_NAME}</TableCell>
                        <TableCell align="center" >{row.VILLAGE_NAMEMM}</TableCell>
                        <TableCell align="center" >{row.VILLAGE_MALEPOP}</TableCell>
                        <TableCell align="center" >{row.VILLAGE_FEMALEPOP}</TableCell>
                        <TableCell align="center" >{row.VILLAGE_LATITUDE}</TableCell>
                        <TableCell align="center" >{row.VILLAGE_LONGITUDE}</TableCell>
                        <TableCell align="center" >{row.VILLAGE_HOUSEHOLD}</TableCell>
                        <TableCell align="center" >{row.TSP_ID}</TableCell>
                        <TableCell align="center" >{row.TSP_IDEHO}</TableCell>
                        <TableCell align="center" >{(row.VILLAGE_STATUS === 1 ? 'Active' : 'Not Active')}</TableCell>
                        <TableCell align="center">
                          <IconButton >
                            <EditIcon style={{ color: "#41273b" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    : null}

                </TableBody>



              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>}




    </>
  )
}

export default EditVillage