import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add Clinic dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

///////API///////
import { adminBackgroundDivData, adminBackgroundTspDivData, adminBackgroundClnData, adminBackgroundTspOrgData, adminBackgroundOrgFilterData, adminBackgroundOrgData } from '../../../modals/adminbackground'
import { insertClinic } from '../../../modals/adminclinicinfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

function EditClinic() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [clnShow, setClnShow] = useState(false)

  const [maxClnID, setMaxClnID] = useState()
  const [clnData, setClnData] = useState([])
  const [clnMaxIDData, setClnMaxIDData] = useState([])
  const [divData, setDivData] = useState([])
  const [selectedDiv, setSelectedDiv] = useState('')
  const [tspData, setTspData] = useState([])
  const [selectedTsp, setSelectedTsp] = useState('')
  const [orgData, setOrgData] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')

  const [formData, setFormData] = useState(
    {
      CLN_ID: '',
      CLN_NAME: '',
      CLN_TSP: '',
      EHO_TSP: '',
      CLN_POP: '',
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    fetchDivData()

  }, [])

  const fetchDivData = async () => {
    setLoading(true)
    setFormData([])
    setSelectedDiv('')
    setSelectedTsp('')
    setSelectedOrg('')
    setMaxClnID('')
    let divIDData = await adminBackgroundDivData()
    let clnIDData = await adminBackgroundClnData()
    if (divIDData && clnIDData) {
      let divID = divIDData.data.data.adminBackgroundDivData
      let clnID = clnIDData.data.data.adminBackgroundClnData
      setClnData(clnID)
      setDivData(divID)
    }
    setLoading(false)
  }

  const divisionHandle = async (e) => {
    let div = e.target.value
    setSelectedDiv(div)
    setMaxClnID('')
    //console.log('div=>', div)
    let a = { divID: div }
    let tspIDData = await adminBackgroundTspDivData(a)
    if (tspIDData) {
      let tsp = tspIDData.data.data.adminBackgroundTspDivData
      //console.log('tsp => ', tsp)
      setTspData(tsp)
    }
  }

  const tspHandle = async (e) => {
    let tsp = e.target.value
    setSelectedTsp(tsp)
    let tspRemove = tsp.indexOf("-TSP-")
    let firstWord = tsp.slice(0, tspRemove)
    let secondWord = tsp.slice((tspRemove) + 5, tsp.length)
    //console.log('village => ', firstWord+'-'+secondWord+'-VIL-')
    let tspIDCompare = firstWord + '-TSP-' + secondWord
    let clnFilterData = clnData.filter((data) => { return data.CLN_TSP === tspIDCompare })
    let a = { tspID: tsp }
    let orgIDData = await adminBackgroundTspOrgData(a)
    if (orgIDData) {
      let orgList = orgIDData.data.data.adminBackgroundTspOrgData
      let data = orgList.map((i) => { return i.ORGID })
      let a = { orgListData: data }
      let orgFilterDataList = await adminBackgroundOrgFilterData(a)
      if (orgFilterDataList) {
        let orgName = orgFilterDataList.data.data.adminBackgroundOrgFilterData
        //console.log('data =>', orgFilterDataList)
        setOrgData(orgName)
      }

    }
    setClnMaxIDData(clnFilterData)
    console.log('cln => ', clnFilterData)
  }

  const orgHandle = async (e) => {
    let org = e.target.value
    setSelectedOrg(org)
    console.log('org =>', org)
    let orgName = org + "-"
    let list = []
    let idList = clnMaxIDData.map((id) => {
      if (id.CLN_ID.includes(orgName)) { list.push(id.CLN_ID.replace(orgName, "")) }
    })
    let maxID = Math.max(...list)
    let finalMaxID = maxID + 1
    let maxIDString = ''
    for (let i = 0; i < 4; i++) {
      if (finalMaxID < 10) { maxIDString = '00' + finalMaxID }
      else if (finalMaxID < 99) { maxIDString = '0' + finalMaxID }
      else maxIDString = (finalMaxID) + ''
      setMaxClnID(orgName + maxIDString)
      console.log('Max ID => ', orgName + maxIDString)
    }
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const showHandle = (home, ctry) => {
    setHomeShow(home)
    setClnShow(ctry)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminCln', 'false')
  }

  const save = async () => {
    let valid = (!selectedDiv) ? 'Please select Division!' :
      (!selectedTsp) ? 'Please select Township!' :
        (!selectedOrg) ? 'Please select Organization!' :
          !formData.CLN_NAME ? 'Please Fill Clinic Name!' : 'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        CLN_ID: maxClnID,
        CLN_NAME: formData.CLN_NAME,
        CLN_TSP: selectedTsp,
        EHO_TSP: formData.EHO_TSP,
        CLN_POP: formData.CLN_POP,
      }
      const res = await insertClinic(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new clinic");
        fetchDivData()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (
    < >
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminCln') == 'true') && <Grid container >
        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
          <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                Go Back
              </Button>
              <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                Add New Clinic
              </Button>

              {/* dialog */}
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
              /*  onClose={handleClose} */
              >
                <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New Clinic</DialogTitle>
                <DialogContent>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      m: 'auto',
                      width: 'fit-content',
                    }}>

                    {/* list */}
                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            style={{ width: 230 }}>
                            <InputLabel id="demo-simple-select-filled-label">
                              DIV id
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={selectedDiv}
                              onChange={(e) => {
                                divisionHandle(e);
                              }}
                              MenuProps={{
                                style: {
                                  maxHeight: 400,
                                  maxWidth: 300
                                },
                              }}>
                              {divData.length &&
                                divData.map((option) => (
                                  <MenuItem value={option?.DIV_ID}>
                                    {option?.DIV_NAME}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            style={{ width: 230 }}
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              TSP id
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              disabled={!selectedDiv}
                              value={selectedTsp}
                              onChange={(e) => {
                                tspHandle(e);
                              }}
                              MenuProps={{
                                style: {
                                  maxHeight: 400,
                                  maxWidth: 300
                                },
                              }}>
                              {tspData.length &&
                                tspData.map((option) => (
                                  <MenuItem value={option?.TSP_ID}>
                                    {option?.TSP_NAME}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            style={{ width: 230 }}>
                            <InputLabel id="demo-simple-select-filled-label">
                              Organization
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={selectedOrg}
                              disabled={!selectedTsp}
                              onChange={(e) => {
                                orgHandle(e);
                              }}
                              MenuProps={{
                                style: {
                                  maxHeight: 400,
                                  maxWidth: 300
                                },
                              }}>
                              {orgData.length &&
                                orgData.map((option) => (
                                  <MenuItem value={option?.ORG_SHORTNAME}>
                                    {option?.ORG_SHORTNAME}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} >
                        <TextField disabled
                          value={maxClnID} id="outlined-basic" label="Clinic Code" variant="outlined" />
                      </Grid>
                    </Grid>
                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'}>
                        <TextField required
                          id="outlined-basic" label="Clinic Name" variant="outlined"
                          value={formData.CLN_NAME}
                          onChange={e => {
                            setFormData({ ...formData, CLN_NAME: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="Clinic EHO Tsp" variant="outlined"
                          value={formData.EHO_TSP}
                          onChange={e => {
                            setFormData({ ...formData, EHO_TSP: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="Clinic Pop" variant="outlined"
                          type='number'
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          value={formData.CLN_POP}
                          onChange={e => {
                            setFormData({ ...formData, CLN_POP: e.target.value })
                          }} />
                      </Grid>
                    </Grid>

                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                  <Button onClick={save} variant="contained" color="success">Save</Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          </Stack>
        </Grid>

        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
          <Stack direction="row" spacing={2} justifyContent={'center'} >
            <TableContainer style={{ width: '93%', maxHeight: '59.5vh', }} >
              <Table
                stickyHeader
                aria-label="spanning table"
                size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Clinic Code</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Clinic Name</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Clinic Tsp</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Clinic EHO Tsp</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Clinic Pop</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody >

                  {clnData.length ?
                    clnData.map((row) => (
                      <TableRow>
                        <TableCell align="center" >{row.CLN_ID}</TableCell>
                        <TableCell align="center" >{row.CLN_NAME}</TableCell>
                        <TableCell align="center" >{row.CLN_TSP}</TableCell>
                        <TableCell align="center" >{row.EHO_TSP}</TableCell>
                        <TableCell align="center" >{row.CLN_POP}</TableCell>
                        <TableCell align="center">
                          <IconButton >
                            <EditIcon style={{ color: "#41273b" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    : null}

                </TableBody>



              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>}

    </>
  )
}

export default EditClinic