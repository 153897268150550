import axios from 'axios';
import { api } from '../utils/helper';

//****************** GET Background Data for Admin ******************//
export const adminBackgroundCtryData = async () => {
  
  try {

    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgroundctrydata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundCtryData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundCtryData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Background Data for Admin ******************//
export const adminBackgroundDivData = async () => {
  
  try {

    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgrounddivdata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundDivData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundDivData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Background Data for Admin ******************//
export const adminBackgroundDistData = async () => {
  
  try {

    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgrounddistdata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundDistData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundDistData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Background Data for Admin ******************//
export const adminBackgroundTspData = async () => {
  
  try {

    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgroundtspdata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundTspData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundTspData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Tsp data depend on div selected ******************//
export const adminBackgroundTspDivData = async (a) => {
  
  try {

    const resrep = await axios.post(
      `${api}/adminbackgroundtspdivdata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundTspDivData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundTspDivData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Org data depend on tsp selected ******************//
export const adminBackgroundTspOrgData = async (a) => {
  
  try {

    const resrep = await axios.post(
      `${api}/adminbackgroundtsporgdata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundTspOrgData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundTspOrgData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }
}

//****************** GET Org data depend on org id list selected ******************//
export const adminBackgroundOrgFilterData = async (a) => {
  
  try {

    const resrep = await axios.post(
      `${api}/adminbackgroundorgfilterdata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundOrgFilterData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundOrgFilterData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Village BackgroundData ******************//
export const adminBackgroundVillageData = async () => {
  
  try {
    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgroundvillagedata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundVillageData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundVillageData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Background Data for Admin ******************//
export const adminBackgroundOrgData = async () => {
  
    try {
  
      let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
      const resrep = await axios.post(
        `${api}/adminbackgroundorgdata`, a,
        {
          headers: {
            authorization: sessionStorage.getItem('token'),
          },
        },
      );
  
      console.log(`adminBackgroundOrgData controller res: `, resrep);
      return resrep;
    } catch (error) {
      console.log(`adminBackgroundOrgData controller error: `, error?.response?.data);
      alert(error?.response?.data?.message);
    }
  
  }

  //****************** GET Background Data for Admin ******************//
export const adminBackgroundProjData = async () => {
  
  try {

    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgroundprojdata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundProjData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundProjData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Background Data for Admin ******************//
export const adminBackgroundClnData = async () => {
  
  try {

    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgroundclndata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundClnData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundClnData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

//****************** GET Background Data for Admin ******************//
export const adminBackgroundUserData = async () => {
  
  try {

    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgrounduserdata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundUserData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundUserData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}

 //****************** GET Background Data for Admin ******************//
 export const adminBackgroundDonorData = async () => {
  
  try {

    let a = { projID: sessionStorage.getItem('project') , orgID: sessionStorage.getItem('org') }
    const resrep = await axios.post(
      `${api}/adminbackgrounddonordata`, a,
      {
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
      },
    );

    console.log(`adminBackgroundDonorData controller res: `, resrep);
    return resrep;
  } catch (error) {
    console.log(`adminBackgroundDonorData controller error: `, error?.response?.data);
    alert(error?.response?.data?.message);
  }

}