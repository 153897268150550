import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment, { fn } from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomTextField from "../../components/controls/CustomTextFieldFilled";
import CustomUnicefTextField from '../../components/controls/CustomUnicefTextField'
import { Card } from "@mui/material";
import { Button, Chip, OutlinedInput, Snackbar, SnackbarContent, Switch, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, List, ListItem } from "@material-ui/core";
import _ from 'lodash';
import XLSX from 'xlsx'
import Modals from "../../components/modal";

import CustomSnackBar from "../../components/controls/CustomSnackBar";

/////////////////////////////API/////////////////////////////
import {exportEWAR} from '../../modals/exportewar'

export default function ExportEWAR() {

  const [month, setMonth] = useState('999')
  const [year, setYear] = useState('999')

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const exportData = async() => {
    const parameter = {
      orgID: sessionStorage.getItem('org'),
      projID: sessionStorage.getItem('project'),
      yearData: year,
      monthData: month,
    }
    var allTableRes = []
    setLoading(true)
      var wb = await XLSX.utils.book_new();
      allTableRes = await exportEWAR(parameter);
      
      if(allTableRes)
      {
        console.log("Table res =>",allTableRes)
        var ewar = await XLSX.utils.json_to_sheet(allTableRes.data.data.exportEWAR);
        XLSX.utils.book_append_sheet(wb, ewar, "EWAR");
      }
      else {
        var empty = []
        XLSX.utils.book_append_sheet(wb, empty, "Empty");
      }
      XLSX.writeFile(wb, "InfoMx_EWAR_ExportDataset_" + `${moment(new Date()).format('DD-MM-YYYY')}` + ".xlsx");
      setLoading(false)
  }

  return (
    <>
    <Modals open={loading} />
      {openSnack && <CustomSnackBar open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {successSnack && <CustomSnackBar open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      <Typography variant="h5" align="center" style={{ color: '#53344d', fontWeight: 'bold', padding: '1%' }}>
        Export EWARS Dataset</Typography>
      <Grid container row justify="center">
        <Grid item xs={12} sm={12} md={1} style={{ alignContent: 'center', alignItems: 'center', marginTop: '8%' }}>
          <CustomUnicefTextField
            label={<Grid row container><Typography color="#482642">Year</Typography>
              <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
            select
            value={year}
            onChange={(e) => { setYear(e.target.value) }}
            variantText="filled"
            style={{ width: '90%' }}
            InputLabelProps={{
              style: { color: '#482642' },
              shrink: true
            }}
            SelectProps={{
              native: true
            }}>
            <option value={999}>-</option>
            <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
            <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
            <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
            <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
            <option value={new Date().getFullYear() + 2}>{new Date().getFullYear() + 2}</option>
          </CustomUnicefTextField>
        </Grid>
        <Grid item xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center', marginTop: '8%' }}>
          <CustomUnicefTextField
            label={<Grid row container><Typography color="#482642">Month</Typography>
              <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
            select
            value={month}
            onChange={(e) => { setMonth(e.target.value) }}
            variantText="filled"
            style={{ width: '90%' }}
            InputLabelProps={{
              style: { color: '#482642' },
              shrink: true
            }}
            SelectProps={{
              native: true
            }}>
            <option value={999}>-</option>
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </CustomUnicefTextField>
        </Grid>
        <Grid item xs={12} sm={2} md={1} style={{ alignContent: 'center', alignSelf: 'center', marginTop: '8%' }}>
        <Button variant="contained" 
        style={{ backgroundColor: (month === '999' || year === '999') ? '#DDD3D5' : '#482642', color: (month === '999' || year === '999') ? '#a49c9e' : 'white' }}
        onClick={exportData} disabled={month === '999' || year === '999'}>Export</Button>
      </Grid>
      </Grid>
      
    </>
  )
}
