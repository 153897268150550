import axios from 'axios';
import {api} from '../utils/helper';

//****************** Insert org ******************//
export const insertOrg = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertorg`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertOrg controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertOrg controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }


//****************** Update org ******************//
export const updateOrg = async (a) => {
    try{
        const resrep = await axios.put(`${api}/updateorg`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('updateOrg controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('updateOrg controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }

//****************** Delete Org ******************//
export const deleteOrg = async (a) => {
    try{
        const resrep = await axios.put(`${api}/deleteorg`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('deleteOrg controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('deleteOrg controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }