import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography } from "@material-ui/core";

import { Card } from '@material-ui/core';



const useStyles = makeStyles({
    container: {
        maxHeight: '400px',
        width: '98%',
        borderRadius: '5px',
        position: 'revert',
        background: '#fcf0f2',
        marginLeft: '1%',
        marginRight: '1%'
    },
});



export default function CustomEWARTable(props) {

    const classes = useStyles();
    const { tableID, searchData, tdata } = props
    

    return (
        <>
            <TableContainer
                className={classes.container}>
                <Table
                    id={tableID}
                    aria-label="spanning table"
                    size='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>ID</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Year</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Month</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Dis_Code</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Code Label</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Case Total</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Death Total</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Division</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Township</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Clinic</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#f8dadd', fontWeight: 'bold' }}>Organization</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {searchData.length ?

                            searchData.map((row) => (
                                <TableRow>
                                    <TableCell align="center" >{row.ID}</TableCell>
                                    <TableCell align="center" >{row.YEAR}</TableCell>
                                    <TableCell align="center" >{row.MONTH}</TableCell>
                                    <TableCell align="center" >{row.DIS_CODE}</TableCell>
                                    <TableCell align="center" >{row.CODELABEL}</TableCell>
                                    <TableCell align="center" >{row.CASETOTAL}</TableCell>
                                    <TableCell align="center" >{row.DEATHTOTAL}</TableCell>
                                    <TableCell align="center" >{row.DIV_NAME}</TableCell>
                                    <TableCell align="center" >{row.TSP_NAME}</TableCell>
                                    <TableCell align="center" >{row.CLN_NAME}</TableCell>
                                    <TableCell align="center" >{row.ORGNAME}</TableCell>
                                </TableRow>
                            )): null}
                    </TableBody>

                </Table>
            </TableContainer>
            {!searchData.length && <Typography variant="body" align="center" style={{ color: '#53344d', background: '#fcf0f2', padding: '1%' }}>
                No Data</Typography>}


        </>
    );
}
