import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add div dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

import moment from "moment";

///////API///////
import { adminBackgroundDonorData } from '../../../modals/adminbackground'
import { insertDonor } from '../../../modals/admindonorinfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

function EditDonor() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [donorShow, setDonorShow] = useState(false)

  const [maxDonorID, setMaxDonorID] = useState()
  const [donorData, setDonorData] = useState([])

  const [formData, setFormData] = useState(
    {
      DONOR_ID: '',
      DONOR_NAME: '',
      DONOR_SHORTNAME: '',
      GRANT_NAME: '',
      START_DATE: '',
      END_DATE: '',
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    maxDonorIDCal()

  }, [])

  const maxDonorIDCal = async () => {
    setLoading(true)
    setFormData([])
    let donorIDData = await adminBackgroundDonorData()
    if (donorIDData) {
      let donorID = donorIDData.data.data.adminBackgroundDonorData
      let idList = donorID.map((id) => id.DONOR_ID.replace("D-", ""))
      let maxID = Math.max(...idList)
      //console.log('Max ID => ',maxID+1)
      setDonorData(donorID)
      setMaxDonorID('D-' + (maxID + 1))
    }
    setLoading(false)
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const showHandle = (home, ctry) => {
    setHomeShow(home)
    setDonorShow(ctry)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminDonor', 'false')
  }

  const save = async () => {
    let valid = !formData.DONOR_NAME ? 'Please Fill Donor Name!' : 'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        DONOR_ID: maxDonorID,
        DONOR_NAME: formData.DONOR_NAME,
        DONOR_SHORTNAME: formData.DONOR_SHORTNAME,
        GRANT_NAME: formData.GRANT_NAME,
        START_DATE: formData.START_DATE,
        END_DATE: formData.END_DATE,
      }
      const res = await insertDonor(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new donor");
        maxDonorIDCal()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (

    <>
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminDonor') == 'true') && <Grid container >
        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
          <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                Go Back
              </Button>
              <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                Add New Donor
              </Button>

              {/* dialog */}
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
              >
                <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New Donor</DialogTitle>
                <DialogContent>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      m: 'auto',
                      width: 'fit-content',
                    }}>


                    {/* list */}
                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'} >
                        <TextField disabled
                          value={maxDonorID} id="outlined-basic" label="Donor Code" variant="outlined" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField required id="outlined-basic" label="Donor Name" variant="outlined"
                          value={formData.DONOR_NAME}
                          onChange={e => {
                            setFormData({ ...formData, DONOR_NAME: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="Donor ShortName" variant="outlined"
                          value={formData.DONOR_SHORTNAME}
                          onChange={e => {
                            setFormData({ ...formData, DONOR_SHORTNAME: e.target.value })
                          }} />
                      </Grid>


                    </Grid>

                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'}>
                        <TextField id="outlined-basic" label="Grant Name" variant="outlined"
                          value={formData.GRANT_NAME}
                          onChange={e => {
                            setFormData({ ...formData, GRANT_NAME: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="START_DATE" variant="outlined" type="date" InputLabelProps={{ shrink: true }} style={{ width: '90%' }}
                          value={formData.START_DATE}
                          onChange={e => {
                            setFormData({ ...formData, START_DATE: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField id="outlined-basic" label="END_DATE" variant="outlined" type="date" InputLabelProps={{ shrink: true }} style={{ width: '90%' }}
                          value={formData.END_DATE}
                          onChange={e => {
                            setFormData({ ...formData, END_DATE: e.target.value })
                          }} />
                      </Grid>
                    </Grid>

                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                  <Button onClick={save} variant="contained" color="success">Save</Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          </Stack>
        </Grid>

        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
          <Stack direction="row" spacing={2} justifyContent={'center'} >
            <TableContainer style={{ width: '93%', maxHeight: '59.5vh', }} >
              <Table
                stickyHeader
                aria-label="spanning table"
                size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Donor Code</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Donor Name</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Donor ShortName</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Grant Name</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>START_DATE</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>END_DATE</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody >

                  {donorData.length ?
                    donorData.map((row) => (
                      <TableRow>
                        <TableCell align="center" >{row.DONOR_ID}</TableCell>
                        <TableCell align="center" >{row.DONOR_NAME}</TableCell>
                        <TableCell align="center" >{row.DONOR_SHORTNAME}</TableCell>
                        <TableCell align="center" >{row.GRANT_NAME}</TableCell>
                        <TableCell align="center" >{row.START_DATE ? moment(row.START_DATE).format("DD-MM-YYYY") : '-'}</TableCell>
                        <TableCell align="center" >{row.END_DATE ? moment(row.END_DATE).format("DD-MM-YYYY") : '-'}</TableCell>
                        <TableCell align="center">
                          <IconButton >
                            <EditIcon style={{ color: "#41273b" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    : null}

                </TableBody>



              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>
      }



    </>
  )
}

export default EditDonor