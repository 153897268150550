import axios from 'axios';
import {api} from '../utils/helper';

//****************** Insert user ******************//
export const insertUser = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertuser`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertUser controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertUser controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }


//****************** Update user ******************//
export const updateUser = async (a) => {
    try{
        const resrep = await axios.put(`${api}/updateuser`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('updateUser controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('updateUser controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }
