import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add div dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

import moment from "moment";

///////API///////
import { adminBackgroundProjData, adminBackgroundDonorData } from '../../../modals/adminbackground'
import { insertProj } from '../../../modals/adminprojinfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

const options = [
  { value: 'on', label: 'one' },
  { value: 'tw', label: 'two' },
  { value: 'thr', label: 'three' },
  { value: 'onon', label: 'oneone' },
]
function EditProject() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [projShow, setProjShow] = useState(false)

  const [maxProjID, setMaxProjID] = useState()
  const [projData, setProjData] = useState([])
  const [donorData, setDonorData] = useState([])
  const [selectedDonor, setSelectedDonor] = useState('')

  const [formData, setFormData] = useState(
    {
      PROJECT_ID: '',
      PROJECT_NAME: '',
      DONOR_ID: '',
      PROJECT_STARTDATE: '',
      PROJECT_ENDDATE: '',
      PROJECT_DESCRIPTION: '',
      PROJECT_STATUS: '',
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    fetchDonorProjData()

  }, [])

  const fetchDonorProjData = async () => {
    setLoading(true)
    setFormData([])
    setSelectedDonor('')
    let donorIDData = await adminBackgroundDonorData()
    let projIDData = await adminBackgroundProjData()
    if (donorIDData && projIDData) {
      let dData = donorIDData.data.data.adminBackgroundDonorData
      let pData = projIDData.data.data.adminBackgroundProjData
      let idList = pData.map((id) => id.PROJECT_ID.replace("P-", ""))
      let maxID = Math.max(...idList)
      setProjData(pData)
      setDonorData(dData)
      setMaxProjID('P-' + (maxID + 1))
    }
    setLoading(false)
  }

  const donorHandle = async (e) => {
    let donor = e.target.value
    setSelectedDonor(donor)
    console.log('donor =>', donor)
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const showHandle = (home, ctry) => {
    setHomeShow(home)
    setProjShow(ctry)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminProj', 'false')
  }

  const save = async () => {
    let valid = !selectedDonor ? 'Please Select Donor!' :
      !formData.PROJECT_NAME ? 'Please Fill Project Name!' : 'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        PROJECT_ID: maxProjID,
        PROJECT_NAME: formData.PROJECT_NAME,
        DONOR_ID: selectedDonor,
        PROJECT_STARTDATE: formData.PROJECT_STARTDATE,
        PROJECT_ENDDATE: formData.PROJECT_ENDDATE,
        PROJECT_DESCRIPTION: formData.PROJECT_DESCRIPTION,
        PROJECT_STATUS: formData.PROJECT_STATUS,
      }
      const res = await insertProj(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new project");
        fetchDonorProjData()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (
    <>
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminProj') == 'true') && <Grid container >
        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
          <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                Go Back
              </Button>
              <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                Add New Project
              </Button>

              {/* dialog */}
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
              /*  onClose={handleClose} */
              >
                <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New Project</DialogTitle>
                <DialogContent>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      m: 'auto',
                      width: 'fit-content',
                    }}>


                    {/* list */}
                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'} >
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "90%" }}>
                            <InputLabel id="demo-simple-select-filled-label">
                              Donor id
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              sx={{ width: "100%" }}
                              value={selectedDonor}
                              onChange={(e) => {
                                donorHandle(e);
                              }}
                              MenuProps={{
                                style: {
                                  maxHeight: 400,
                                  maxWidth:300
                                },
                              }}
                            >
                              {donorData.length &&
                                donorData.map((option) => (
                                  <MenuItem value={option?.DONOR_ID}>
                                    {option?.DONOR_NAME}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} >
                        <TextField disabled
                          id="outlined-basic" label="Project Code" variant="outlined"
                          value={maxProjID}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField required id="outlined-basic" label="Project Name" variant="outlined"
                          value={formData.PROJECT_NAME}
                          onChange={e => {
                            setFormData({ ...formData, PROJECT_NAME: e.target.value })
                          }} />
                      </Grid>
                    </Grid>

                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'} >
                        <TextField type="date" InputLabelProps={{ shrink: true }} style={{ width: '90%' }} id="outlined-basic" label="START_DATE" variant="outlined"
                          value={formData.PROJECT_STARTDATE}
                          onChange={e => {
                            setFormData({ ...formData, PROJECT_STARTDATE: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} >
                        <TextField type="date" InputLabelProps={{ shrink: true }} style={{ width: '90%' }} id="outlined-basic" label="END_DATE" variant="outlined"
                          value={formData.PROJECT_ENDDATE}
                          onChange={e => {
                            setFormData({ ...formData, PROJECT_ENDDATE: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} >
                        <TextField id="outlined-basic" label="Description" variant="outlined"
                          value={formData.PROJECT_DESCRIPTION}
                          onChange={e => {
                            setFormData({ ...formData, PROJECT_DESCRIPTION: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "90%" }}
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              Status
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={formData.PROJECT_STATUS}
                              onChange={e => {
                                setFormData({ ...formData, PROJECT_STATUS: e.target.value })
                              }}
                            >
                              <MenuItem value={1}> Active </MenuItem>
                              <MenuItem value={0}> Not Active </MenuItem>
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>

                    </Grid>

                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                  <Button onClick={save} variant="contained" color="success">Save</Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          </Stack>
        </Grid>

        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
          <Stack direction="row" spacing={2} justifyContent={'center'} >
            <TableContainer style={{ width: '93%', maxHeight: '59.5vh', }} >
              <Table
                stickyHeader
                aria-label="spanning table"
                size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Project Code</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Project Name</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Donor Code/Name</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>START_DATE</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>END_DATE</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Description</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Status</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>

                  </TableRow>
                </TableHead>

                <TableBody >

                  {projData.length ?
                    projData.map((row) => (
                      <TableRow>
                        <TableCell align="center" >{row.PROJECT_ID}</TableCell>
                        <TableCell align="center" >{row.PROJECT_NAME}</TableCell>
                        <TableCell align="center" >{row.DONOR_ID}</TableCell>
                        <TableCell align="center" >{row.PROJECT_STARTDATE ? moment(row.PROJECT_STARTDATE).format("DD-MM-YYYY") : '-'}</TableCell>
                        <TableCell align="center" >{row.PROJECT_ENDDATE ? moment(row.PROJECT_ENDDATE).format("DD-MM-YYYY") : '-'}</TableCell>
                        <TableCell align="center" >{row.PROJECT_DESCRIPTION ? row.PROJECT_DESCRIPTION : '-'}</TableCell>
                        <TableCell align="center" >{row.PROJECT_STATUS === 1 ? 'Active' : row.PROJECT_STATUS === 0 ? 'Not Active' : '-'}</TableCell>
                        <TableCell align="center">
                          <IconButton >
                            <EditIcon style={{ color: "#41273b" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    : null}
                </TableBody>

              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>}




    </>
  )
}

export default EditProject