import axios from 'axios';
import {api} from '../utils/helper';

//****************** Insert div ******************//
export const insertDiv = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertdiv`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertDiv controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertDiv controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }


//****************** Update div ******************//
export const updateDiv = async (a) => {
    try{
        const resrep = await axios.put(`${api}/updatediv`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('updateDiv controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('updateDiv controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }

//****************** Delete div ******************//
export const deleteDiv = async (a) => {
    try{
        const resrep = await axios.put(`${api}/deletediv`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('deleteDiv controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('deleteDiv controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }