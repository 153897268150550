import axios from 'axios';
import {api} from '../utils/helper';

//****************** Insert village ******************//
export const insertVillage = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertvillage`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertVillage controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertVillage controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }


//****************** Update village ******************//
export const updateVillage = async (a) => {
    try{
        const resrep = await axios.put(`${api}/updatevillage`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('updateVillage controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('updateVillage controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }