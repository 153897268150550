import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add div dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

///////API///////
import { adminBackgroundDivData, adminBackgroundTspData } from '../../../modals/adminbackground'
import { insertTsp } from '../../../modals/admintspinfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

function EditTownship() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [tspShow, setTspShow] = useState(false)

  const [maxTspID, setMaxTspID] = useState('')
  const [tspData, setTspData] = useState([])
  const [divData, setDivData] = useState([])
  const [selectedDiv, setSelectedDiv] = useState('')

  const [formData, setFormData] = useState(
    {
      TSP_ID: '',
      TSP_NAME: '',
      TSP_SHORTNAME: '',
      DIV_ID: '',
      TSP_LAT: '',
      TSP_LONG: ''
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    fetchDivData()

  }, [])

  const fetchDivData = async () => {
    setLoading(true)
    setFormData([])
    setSelectedDiv('')
    setMaxTspID('')
    let divIDData = await adminBackgroundDivData()
    let tspIDData = await adminBackgroundTspData()
    if (divIDData && tspIDData) {
      let divID = divIDData.data.data.adminBackgroundDivData
      let tspID = tspIDData.data.data.adminBackgroundTspData
      setTspData(tspID)
      setDivData(divID)
    }
    setLoading(false)
  }

  const divisionHandle = async (e) => {
    let div = e.target.value
    setSelectedDiv(div)
    let divFilterData = divData.filter((data) => { return data.DIV_ID === div })
    let divString = divFilterData[0].DIV_SHORTNAME + '-TSP-'
    let tspFilterData = tspData.filter((data) => { return data.DIV_ID === div })
    let idList = tspFilterData.map((id) => id.TSP_ID.replace(divString, ""))
    let maxID = Math.max(...idList)
    let finalMaxID = maxID + 1
    let maxIDString = ''
    for (let i = 0; i < 4; i++) {
      if (finalMaxID < 10) { maxIDString = '00' + finalMaxID }
      else if (finalMaxID < 99) { maxIDString = '0' + finalMaxID }
      else maxIDString = (finalMaxID) + ''
      setMaxTspID(divString + maxIDString)
      console.log('Max ID => ', divString + maxIDString)
    }
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const showHandle = (home, tsp) => {
    setHomeShow(home)
    setTspShow(tsp)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminTsp', 'false')
  }

  const save = async () => {
    let valid = !selectedDiv ? 'Please Select Division!' :
    !formData.TSP_NAME ? 'Please Fill Township Name!' : 'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        TSP_ID: maxTspID,
        TSP_NAME: formData.TSP_NAME,
        TSP_SHORTNAME: formData.TSP_SHORTNAME,
        DIV_ID: selectedDiv,
        TSP_LAT: formData.TSP_LAT,
        TSP_LONG: formData.TSP_LONG
      }
      const res = await insertTsp(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new township");
        fetchDivData()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (
    <>
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminTsp') == 'true') &&
        <Grid container >
          <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
            <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
              <ThemeProvider theme={theme}>
                <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                  Go Back
                </Button>
                <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                  Add New Township
                </Button>

                {/* dialog */}
                <Dialog
                  fullWidth={fullWidth}
                  maxWidth={maxWidth}
                  open={open}
                /* onClose={handleClose} */
                >
                  <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New Township</DialogTitle>
                  <DialogContent>
                    <Box
                      noValidate
                      component="form"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                      }}>

                      {/* list */}
                      <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        {/* DIV select */}
                        <Grid item xs={12} sm={6} md={6} margin={'10px'}>
                          <ThemeProvider theme={theme}>
                            <FormControl
                              variant="filled"
                              sx={{ width: "83%" }}
                            >
                              <InputLabel id="demo-simple-select-filled-label">
                                DIV id
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={selectedDiv}
                                onChange={(e) => {
                                  divisionHandle(e);
                                }}
                                MenuProps={{
                                  style: {
                                    maxHeight: 400,
                                  },
                                }}>
                                {divData.length &&
                                  divData.map((option) => (
                                    <MenuItem value={option?.DIV_ID}>
                                      {option?.DIV_NAME}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </ThemeProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} margin={'10px'}>
                          <TextField disabled
                            value={maxTspID} id="outlined-basic" label="TSP_ID" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} margin={'10px'}>
                          <TextField required id="outlined-basic" label="TSP_NAME" variant="outlined"
                            value={formData.TSP_NAME}
                            onChange={e => {
                              setFormData({ ...formData, TSP_NAME: e.target.value })
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} margin={'10px'}>
                          <TextField id="outlined-basic" label="TSP_SHORTNAME" variant="outlined"
                            value={formData.TSP_SHORTNAME}
                            onChange={e => {
                              setFormData({ ...formData, TSP_SHORTNAME: e.target.value })
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} margin={'10px'} >
                          <TextField id="outlined-basic" label="TSP_LAT" variant="outlined" type='number'
                            InputProps={{
                              inputProps: { min: 0 }
                            }}
                            value={formData.TSP_LAT}
                            onChange={e => {
                              setFormData({ ...formData, TSP_LAT: e.target.value })
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} margin={'10px'}>
                          <TextField id="outlined-basic" label="TSP_LONG" variant="outlined" type='number'
                            InputProps={{
                              inputProps: { min: 0 }
                            }}
                            value={formData.TSP_LONG}
                            onChange={e => {
                              setFormData({ ...formData, TSP_LONG: e.target.value })
                            }} />
                        </Grid>
                      </Grid>

                      {/* <Grid container 
direction="row"
justifyContent="center"
alignItems="center">
<Grid item xs={12} sm={6} md={6} margin={'10px'} marginTop={'30px'} >
<TextField disabled
      defaultValue="DIV-02" id="outlined-basic" label="TSP_ID" variant="outlined" />
</Grid>
<Grid item xs={12} sm={6} md={6} margin={'10px'}>
<TextField id="outlined-basic" label="TSP_NAME" variant="outlined" />
</Grid>
<Grid item xs={12} sm={6} md={6} margin={'10px'}>
<TextField id="outlined-basic" label="DIV_ID" variant="outlined" />
</Grid>
<Grid item xs={12} sm={6} md={6} margin={'10px'} >
<TextField id="outlined-basic" label="TSP_LAT" variant="outlined" type='number' 
InputProps={{
    inputProps: { min: 0 }
  }} />
</Grid>
<Grid item xs={12} sm={6} md={6} margin={'10px'}>
<TextField id="outlined-basic" label="TSP_LONG" variant="outlined" type='number' 
InputProps={{
    inputProps: { min: 0 }
  }}/>
</Grid>
</Grid> */}

                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                    <Button onClick={save} variant="contained" color="success">Save</Button>
                  </DialogActions>
                </Dialog>
              </ThemeProvider>
            </Stack>
          </Grid>

          <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
            <Stack direction="row" spacing={2} justifyContent={'center'} >
              <TableContainer style={{ width: '93%', maxHeight: '59.5vh', }} >
                <Table
                  stickyHeader
                  aria-label="spanning table"
                  size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>TSP_ID</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>TSP_NAME</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>DIV_ID</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>TSP_LAT</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>TSP_LONG</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody >

                    {tspData.length ?
                      tspData.map((row) => (
                        <TableRow>
                          <TableCell align="center" >{row.TSP_ID}</TableCell>
                          <TableCell align="center" >{row.TSP_NAME}</TableCell>
                          <TableCell align="center" >{row.DIV_ID}</TableCell>
                          <TableCell align="center" >{row.TSP_LAT}</TableCell>
                          <TableCell align="center" >{row.TSP_LONG}</TableCell>
                          <TableCell align="center">
                            <IconButton >
                              <EditIcon style={{ color: "#41273b" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                      : null}

                  </TableBody>



                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>}




    </>
  )
}

export default EditTownship