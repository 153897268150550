import {
    Button,
    createTheme,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    ThemeProvider,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import CustomUnicefTextField from "../../components/controls/CustomUnicefTextField";
import CustomSnackBar from "../../components/controls/CustomSnackBar";
import Modals from "../../components/modal";
import XLSX from "xlsx";
import moment from "moment";

/////////////////API////////////////////////
import * as clinic from "../../modals/clinicbyorgproj";
import * as lg from "../../modals/login";
import * as orgQuery from "../../modals/getallorgclinic";
import {getOrgByProj} from "../../modals/getorgbyproj"
import { exportAllTable } from "../../modals/exportalltable";
import * as exportProject from "../../modals/exportbyprojectdashboard";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            flexGrow: 1,
        },
    },
    "& .MuiFilledInput-root": {
        backgroundColor: "#fcf0f2",
    },
    fontSize: {
        "& span:last-child": {
            fontSize: 13,
        },
    },
    cardStyle: {
        marginTop: theme.spacing(0.9),
        marginBottom: theme.spacing(1),
    },
    cardStyleTwo: {
        width: "100%",
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(0.9),
        borderRadius: "3px",
    },
    formControl: {
        margin: theme.spacing(1),
    },

    table: {
        width: "100%",
        background: "#fcf0f2",
        height: "10px",
    },
    cellOne: {
        borderBottom: "none",
        color: "#808080",
        fontSize: "9pt",
    },
    cellTwo: {
        borderBottom: "none",
        color: "#53344d",
        fontSize: "12pt",
        fontWeight: "bold",
    },
    selected: {
        backgroundColor: "#DED4DA !important",
        color: "#482642",
    },
}));

const radioTheme = createTheme({
    palette: {
        primary: {
            main: "#482642",
        },
        secondary: {
            main: "#ffff",
        },
    },
});

export default function ExportByProvidedDateDashboard() {
    const classes = useStyles();

    const [type, setType] = useState('Org');
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [orgData, setOrgData] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [select, setSelect] = useState([]);
    const [error, setError] = useState("");
    const [openSnack, setOpenSnack] = useState(false);

    const typeHandle = async (event) => {
        setType(event.target.value);
        setSelect([]);
    };

    const selectHandle = (event) => {
        setSelect(event.target.value);
        console.log("Seelct event => ", select);
    };

    const setSnackBarOpen = () => {
        setOpenSnack(true);
    };

    const setSnackBarClose = () => {
        setOpenSnack(false);
    };

    const exportBtn = async () => {
        const parameter = {
            orgID: sessionStorage.getItem("org"),
            projID: sessionStorage.getItem("project"),
            sDate: startDate,
            eDate: endDate,
        };

        const validate = () => {
            let valid = !startDate
                ? "Please Choose Start Date"
                : !endDate
                    ? "Please Choose End Date"
                    : type === "Org" && !select.length
                        ? "Please choose at least one organization"
                        : type === "Project" && !select.length
                            ? "Please choose at least one project"
                            : type === "Township" && !select.length
                                ? "Please choose at least one township"
                                : type === "Clinic" && !select.length
                                    ? "Please choose at least one clinic"
                                    : "valid";
            return valid;
        };

        const valid = validate();

        if (valid === "valid") {
            setLoading(true);

            const wb = await XLSX.utils.book_new();
            let allTableRes = [];
            let proj;
            let orgSelect;
            if (type === 'Project') {
                const orgListData = await getOrgByProj({ parameter, select, type });
                if(orgListData.data.data.getOrgByProj)
                {
                    const orgList = orgListData.data.data.getOrgByProj
                    //console.log('Org by Proj=> ', (orgList).map((data)=>data.ORG_ID))
                    //setSelect([])
                    //setSelect((orgList).map((data)=>data.ORG_ID))
                    //console.log('Org by Proj=> ', select)
                    orgSelect = (orgList).map((data)=>data.ORG_ID)
                    proj = select
                    //console.log('Org by Proj=> ', orgSelect)
                    //console.log('Proj=> ', proj)
                    allTableRes = await exportProject.exportByProjectDashboard({ parameter, orgSelect, select, proj, type });
                }
            }
            if (type === 'Org')
            {
                orgSelect = select
                //console.log('org id ', select)
                allTableRes = await exportProject.exportByProjectDashboard({ parameter, orgSelect, select, proj, type });
            }
            else {
                allTableRes = await exportProject.exportByProjectDashboard({ parameter, orgSelect, select, proj, type });
            }


             if (allTableRes) {

                var reg = XLSX.utils.json_to_sheet((allTableRes.data.data.getRegTable).reduce((acc, curr) => acc.concat(curr), []));
                var oReg = XLSX.utils.json_to_sheet((allTableRes.data.data.getORegTable).reduce((acc, curr) => acc.concat(curr), []));
                var anc = XLSX.utils.json_to_sheet((allTableRes.data.data.getANCTable).reduce((acc, curr) => acc.concat(curr), []));
                var delivery = XLSX.utils.json_to_sheet((allTableRes.data.data.getDeliTable).reduce((acc, curr) => acc.concat(curr), []));
                var pnc = XLSX.utils.json_to_sheet((allTableRes.data.data.getPNCTable).reduce((acc, curr) => acc.concat(curr), []));
                var fp = XLSX.utils.json_to_sheet((allTableRes.data.data.getFPTable).reduce((acc, curr) => acc.concat(curr), []));
                var rh = XLSX.utils.json_to_sheet((allTableRes.data.data.getRHTable).reduce((acc, curr) => acc.concat(curr), []));
                var gm = XLSX.utils.json_to_sheet((allTableRes.data.data.getGMTable).reduce((acc, curr) => acc.concat(curr), []));
                var opdMed = XLSX.utils.json_to_sheet((allTableRes.data.data.getOPDMedTable).reduce((acc, curr) => acc.concat(curr), []));
                var opdSur = XLSX.utils.json_to_sheet((allTableRes.data.data.getOPDSurTable).reduce((acc, curr) => acc.concat(curr), []));
                var ipd = XLSX.utils.json_to_sheet((allTableRes.data.data.getIPDTable).reduce((acc, curr) => acc.concat(curr), []));
                var lab = XLSX.utils.json_to_sheet((allTableRes.data.data.getLabTable).reduce((acc, curr) => acc.concat(curr), []));
                var imam = XLSX.utils.json_to_sheet((allTableRes.data.data.getIMAMTable).reduce((acc, curr) => acc.concat(curr), []));
                var imamsfp = XLSX.utils.json_to_sheet((allTableRes.data.data.getIMAMSFPTable).reduce((acc, curr) => acc.concat(curr), []));
                var hts = XLSX.utils.json_to_sheet((allTableRes.data.data.getHTSTable).reduce((acc, curr) => acc.concat(curr), []));

                XLSX.utils.book_append_sheet(wb, reg, "RegByService");
                XLSX.utils.book_append_sheet(wb, oReg, "OnlyRegister");
                XLSX.utils.book_append_sheet(wb, anc, "ANC");
                XLSX.utils.book_append_sheet(wb, delivery, "Delivery");
                XLSX.utils.book_append_sheet(wb, pnc, "PNC");
                XLSX.utils.book_append_sheet(wb, fp, "FP");
                XLSX.utils.book_append_sheet(wb, rh, "RH");
                XLSX.utils.book_append_sheet(wb, gm, "GM");
                XLSX.utils.book_append_sheet(wb, opdMed, "OPD-Medical");
                XLSX.utils.book_append_sheet(wb, opdSur, "OPD-Surgery");
                XLSX.utils.book_append_sheet(wb, ipd, "IPD");
                XLSX.utils.book_append_sheet(wb, imam, "IMAM-OTP");
                XLSX.utils.book_append_sheet(wb, imamsfp, "IMAM-SFP");
                XLSX.utils.book_append_sheet(wb, hts, "HTS");
                XLSX.utils.book_append_sheet(wb, lab, "Lab");
            } else {
                XLSX.utils.book_append_sheet(wb, [], "Empty");
            }
            if(type === "Org")
                {
                  XLSX.writeFile(
                    wb,
                    "InfoMxExportDatasetByOrg_" +
                    `${moment(new Date()).format("DD-MM-YYYY")}` +
                    ".xlsx"
                  );
                }
                else if(type === "Project")
                {
                  XLSX.writeFile(
                    wb,
                    "InfoMxExportDatasetByProj_" +
                    `${moment(new Date()).format("DD-MM-YYYY")}` +
                    ".xlsx"
                  );
                }
                else if(type === "Township")
                {
                  XLSX.writeFile(
                    wb,
                    "InfoMxExportDatasetByTsp_" +
                    `${moment(new Date()).format("DD-MM-YYYY")}` +
                    ".xlsx"
                  );
                }
                else if(type === "Clinic")
                {
                  XLSX.writeFile(
                    wb,
                    "InfoMxExportDatasetByCln_" +
                    `${moment(new Date()).format("DD-MM-YYYY")}` +
                    ".xlsx"
                  );
                }
                else 
                {
                    XLSX.writeFile(
                        wb,
                        "InfoMxExportDataset_" +
                        `${moment(new Date()).format("DD-MM-YYYY")}` +
                        ".xlsx"
                    ); 
                }

            

            setLoading(false);
        } else {
            setError(valid);
            setOpenSnack(true);
        }
    };

    const clear = () => {
        setSelect([]);
        setStartDate("");
        setEndDate("");
        setType('Org');
    };

    useEffect(async () => {
        setLoading(true);

        let donorAndTsp = await lg.login();
        let pData = await donorAndTsp.data.data.getAllProjectInLogIn;
        let org = await orgQuery.getAllOrgAndClinic();
        let orgDt = await org.data.data.getAllOrg;

        if (pData && orgDt) {
            setProjectData(pData);
            setOrgData(orgDt);
        }
        setLoading(false);
    }, []);

    return (
        <div style={{ width: "100%", height: "75.5vH", background: "#ffffff" }}>
            <Modals open={loading} />
            {openSnack && (
                <CustomSnackBar
                    open={setSnackBarOpen}
                    close={setSnackBarClose}
                    alertMsg={error}
                    type="warning"
                />
            )}
            <ThemeProvider theme={radioTheme}>
                <Typography
                    variant="h5"
                    align="center"
                    style={{
                        color: "#53344d",
                        background: "#ffffff",
                        fontWeight: "bold",
                        padding: "1%",
                    }}
                >
                    Export Dataset{" "}
                </Typography>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ margin: "2%", marginBlockStart: "5%" }}
                    >
                        <CustomUnicefTextField
                            id="filled-basic"
                            type="date"
                            style={{ width: "90%" }}
                            label={
                                <Grid row container>
                                    <Typography color="#482642">Start Date </Typography>
                                    <Typography variant="subtitle2" style={{ color: "#d91d4c" }}>
                                        *
                                    </Typography>
                                </Grid>
                            }
                            variantText="filled"
                            InputLabelProps={{
                                style: { color: "#482642" },
                                shrink: true,
                            }}
                            onChange={(e) => setStartDate(e.target.value)}
                            value={startDate}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ margin: "2%", marginBlockStart: "5%" }}
                    >
                        <CustomUnicefTextField
                            id="filled-basic"
                            type="date"
                            style={{ width: "90%" }}
                            label={
                                <Grid row container>
                                    <Typography color="#482642">End Date </Typography>
                                    <Typography variant="subtitle2" style={{ color: "#d91d4c" }}>
                                        *
                                    </Typography>
                                </Grid>
                            }
                            variantText="filled"
                            InputLabelProps={{
                                style: { color: "#482642" },
                                shrink: true,
                            }}
                            onChange={(e) => setEndDate(e.target.value)}
                            value={endDate}
                        />
                    </Grid>
                </Grid>
                <ThemeProvider theme={radioTheme}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={6} md={3} style={{ margin: "2%" }}>
                            <FormControl variant="filled" style={{ width: "90%" }}>
                                <InputLabel id="demo-simple-select-filled-label">
                                    {
                                        <Grid row container>
                                            <Typography color="#482642">Choose Type</Typography>
                                            <Typography
                                                variant="subtitle2"
                                                style={{ color: "#d91d4c" }}
                                            >
                                                *
                                            </Typography>
                                        </Grid>
                                    }
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    style={{ width: "100%" }}
                                    value={type}
                                    onChange={typeHandle}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        style: {
                                            maxHeight: 300,
                                        },
                                        getContentAnchorEl: null,
                                    }}

                                >
                                    <MenuItem value={"Org"}>By Organization</MenuItem>
                                    <MenuItem value={"Project"}>By Project</MenuItem>
                                    
                                </Select>
                            </FormControl>
                        </Grid>
                        {type === "Org" && (
                            <Grid item xs={12} sm={6} md={3} style={{ margin: "2%" }}>
                                <FormControl variant="filled" style={{ width: "90%" }}>
                                    <InputLabel id="demo-simple-select-filled-label">
                                        {
                                            <Grid row container>
                                                <Typography color="#482642">Choose {type} </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    style={{ color: "#d91d4c" }}
                                                >
                                                    *
                                                </Typography>
                                            </Grid>
                                        }
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        style={{ width: "100%" }}
                                        multiple
                                        renderValue={(selected) =>
                                            orgData
                                                .filter((org) => selected.includes(org.ORG_ID))
                                                .map((record) => record.ORG_SHORTNAME)
                                                .join(", ")
                                        }
                                        value={select}
                                        onChange={selectHandle}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            style: {
                                                maxHeight: 400,
                                                maxWidth: 100
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {orgData.length &&
                                            type === "Org" &&
                                            orgData.map((option) => (
                                                <MenuItem
                                                    value={option.ORG_ID}
                                                    key={option.ORG_ID}
                                                    classes={{ selected: classes.selected }}
                                                >
                                                    {option.ORG_SHORTNAME}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {type === "Project" && (
                            <Grid item xs={12} sm={6} md={3} style={{ margin: "2%" }}>
                                <FormControl variant="filled" style={{ width: "90%" }}>
                                    <InputLabel id="demo-simple-select-filled-label">
                                        {
                                            <Grid row container>
                                                <Typography color="#482642">Choose {type} </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    style={{ color: "#d91d4c" }}
                                                >
                                                    *
                                                </Typography>
                                            </Grid>
                                        }
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        style={{ width: "100%" }}
                                        multiple
                                        renderValue={(selected) =>
                                            projectData
                                                .filter((proj) => selected.includes(proj.PROJECT_ID))
                                                .map((record) => record.PROJECT_NAME)
                                                .join(", ")
                                        }
                                        value={select}
                                        onChange={selectHandle}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            style: {
                                                maxHeight: 400,
                                                maxWidth: 100
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {projectData.length &&
                                            type === "Project" &&
                                            projectData.map((option) => (
                                                <MenuItem
                                                    value={option.PROJECT_ID}
                                                    key={option.PROJECT_ID}
                                                    classes={{ selected: classes.selected }}
                                                >
                                                    {option.PROJECT_NAME}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                    </Grid>
                </ThemeProvider>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6} md={3} style={{ margin: "2%" }}>
                        <FormControl variant="filled" style={{ width: "90%" }}>
                            <InputLabel id="demo-simple-select-filled-label">
                                {
                                    <Grid row container>
                                        <Typography color="#482642">Export By</Typography>
                                        <Typography
                                            variant="subtitle2"
                                            style={{ color: "#d91d4c" }}
                                        >
                                            *
                                        </Typography>
                                    </Grid>
                                }
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                style={{ width: "100%" }}
                                value={1}
                                disabled
                                /* value={type}
                                onChange={typeHandle} */
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    style: {
                                        maxHeight: 300,
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value={1}>Selected Variable</MenuItem>
                                {/* <MenuItem value={2}>Raw Data</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >

                    <Grid item xs={1} sm={1} md={1} >
                        <Button
                            variant="contained"
                            style={{ background: "#482642", color: "#fff", width: '90%' }}
                            onClick={exportBtn}
                        >
                            Export
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} >
                        <Button
                            variant="contained"
                            style={{ background: "#482642", color: "#fff", width: '90%' }}
                            onClick={clear}
                        >
                            Clear
                        </Button>

                    </Grid>
                </Grid>
            </ThemeProvider>
        </div>
    );
}
