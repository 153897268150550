import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add country dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

///////API///////
import { adminBackgroundCtryData } from '../../../modals/adminbackground'
import { insertCtry } from '../../../modals/adminctryinfo';

/* color */
const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

function EditCountry() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [ctryShow, setCtryShow] = useState(false)

  const [maxCtryID, setMaxCtryID] = useState()
  const [ctryData, setCtryData] = useState([])

  const [formData, setFormData] = useState(
    {
      CTRY_ID: '',
      CTRY_NAME: '',
      CTRY_REMARK: ''
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    maxCtryIDCal()

  }, [])

  const maxCtryIDCal = async () => {
    setLoading(true)
    setFormData([])
    let ctryIDData = await adminBackgroundCtryData()
    if (ctryIDData) {
      let ctryID = ctryIDData.data.data.adminBackgroundCtryData
      let idList = ctryID.map((id) => id.CTRY_ID.replace("CTY_", ""))
      let maxID = Math.max(...idList)
      let finalMaxID = maxID + 1
      let maxIDString = ''
      for (let i = 0; i < 4; i++) {
        if (finalMaxID < 10) { maxIDString = '000' + finalMaxID }
        else if (finalMaxID < 100) { maxIDString = '00' + finalMaxID }
        else if (finalMaxID < 999) { maxIDString = '0' + finalMaxID }
        else maxIDString = (finalMaxID) + ''
        setMaxCtryID('CTY_' + maxIDString)
        console.log('Max ID => ', maxIDString)
      }
      setCtryData(ctryID)
    }
    setLoading(false)
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const showHandle = (home, ctry) => {
    setHomeShow(home)
    setCtryShow(ctry)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminCtry', 'false')
  }

  const save = async () => {
    let valid = !formData.CTRY_NAME ? 'Please Fill Country Name!' : 'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        CTRY_ID: maxCtryID,
        CTRY_NAME: formData.CTRY_NAME,
        CTRY_REMARK: formData.CTRY_REMARK
      }
      const res = await insertCtry(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new country");
        maxCtryIDCal()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (
    <>
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminCtry') == 'true') &&
        <Grid container >
          <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
            <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
              <ThemeProvider theme={theme}>
                <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                  Go Back
                </Button>
                <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                  Add New Country
                </Button>

                {/* dialog */}
                <Dialog
                  fullWidth={fullWidth}
                  maxWidth={maxWidth}
                  open={open}
                /* onClose={handleClose} */
                >
                  <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New Country</DialogTitle>
                  <DialogContent>
                    <Box
                      noValidate
                      component="form"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                      }}>

                      {/* list */}
                      <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item xs={12} sm={6} md={3} margin={'10px'} marginTop={'30px'} >
                          <TextField disabled
                            defaultValue={maxCtryID} id="outlined-basic" label="Country Code" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} margin={'10px'}>
                          <TextField required id="outlined-basic" label="Country Name" variant="outlined"
                            onChange={e => {
                              setFormData({ ...formData, CTRY_NAME: e.target.value })
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} margin={'10px'}>
                          <TextField id="outlined-basic" label="Remark" variant="outlined"
                            onChange={e => {
                              setFormData({ ...formData, CTRY_REMARK: e.target.value })
                            }} />
                        </Grid>
                      </Grid>

                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                    <Button onClick={save} variant="contained" color="success">Save</Button>
                  </DialogActions>
                </Dialog>
              </ThemeProvider>
            </Stack>
          </Grid>

          <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
            <Stack direction="row" spacing={2} justifyContent={'center'} >
              <TableContainer style={{ width: '93%' }} >
                <Table
                  aria-label="spanning table"
                  size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Country Code</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Country Name</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Remark</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                      <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {ctryData.length ?
                      ctryData.map((row) => (
                        <TableRow>
                          <TableCell align="center" >{row.CTRY_ID}</TableCell>
                          <TableCell align="center" >{row.CTRY_NAME}</TableCell>
                          <TableCell align="center" >{row.CTRY_REMARK}</TableCell>
                          <TableCell align="center">
                            <IconButton >
                              <EditIcon style={{ color: "#41273b" }} />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton >
                              <DeleteIcon style={{ color: "#d91d4c" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid >}

    </>
  )
}

export default EditCountry