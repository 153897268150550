import axios from 'axios';
import {api} from '../utils/helper';

//****************** Insert ctry ******************//
export const insertCtry = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertctry`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertCtry controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertCtry controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }


//****************** Update ctry ******************//
export const updateCtry = async (a) => {
    try{
        const resrep = await axios.put(`${api}/updatectry`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('updateCtry controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('updateCtry controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }

//****************** Delete ctry ******************//
export const deleteCtry = async (a) => {
    try{
        const resrep = await axios.put(`${api}/deletectry`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('deleteCtry controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('deleteCtry controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }