import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
/* table */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/* Add div dialog */
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdminPage from '../AdminPage';

import CustomizedSnackbars from '../../../components/controls/CustomSnackBar';
import Modals from "../../../components/modal";

///////API///////
import { adminBackgroundOrgData, adminBackgroundUserData } from '../../../modals/adminbackground'
import { insertUser } from '../../../modals/adminuserinfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41273b',

    },
    secondary: {
      main: '#d91e4d',
    },
  },
});

const usertype = [
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Entry' },
  { value: 3, label: 'Read Only' },
  { value: 4, label: 'Super' },
]

const userorg = [
  { value: 'on', label: 'org 1' },
  { value: 'tw', label: 'org 2' },
  { value: 'thr', label: 'org 3' },
  { value: 'onon', label: 'org 4' },
]

const usersalt = [
  { value: 'infomx', label: 'infomx' },
  { value: 'vol', label: 'vol' },
  { value: 'hris', label: 'hris' },
  { value: 'evtreg', label: 'evtreg' },
  { value: 'learn', label: 'learn' },
  { value: 'emr', label: 'emr' },
]

function UsrEdit() {
  /* dialog */
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [homeShow, setHomeShow] = useState(false)
  const [userShow, setUserShow] = useState(false)

  const [maxUserID, setMaxUserID] = useState(0)
  const [orgData, setOrgData] = useState([])
  const [userData, setUserData] = useState([])

  const [formData, setFormData] = useState(
    {
      USER_ID: '',
      USER_FULL_NAME: '',
      USER_ORG: '',
      USER_NAME: '',
      USER_TYPE: '',
      USER_PASSWORD: '',
      CREATED_BY: '',
      CREATED_DATE: '',
      USER_STATUS: '',
      USER_SALT: '',
      USER_EXPIRED: '',
      USER_CLN: '',
    }
  )

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  useEffect(async () => {

    maxUserIDCal()

  }, [])

  const maxUserIDCal = async () => {
    setLoading(true)
    setFormData([])
    let orgIDData = await adminBackgroundOrgData()
    let userIDData = await adminBackgroundUserData()
    if (orgIDData && userIDData) {
      let orgID = orgIDData.data.data.adminBackgroundOrgData
      let userID = userIDData.data.data.adminBackgroundUserData
      let idList = userID.map((id) => id.USER_ID)
      let maxID = Math.max(...idList)
      console.log('Max ID => ', maxID + 1)
      setOrgData(orgID)
      setUserData(userID)
      setMaxUserID(maxID + 1)
    }
    setLoading(false)
  }

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }

  const showHandle = (home, ctry) => {
    setHomeShow(home)
    setUserShow(ctry)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GoHome = async () => {
    showHandle(true, false)
    //console.log('Go to Home Page')
    sessionStorage.setItem('adminHome', 'true')
    sessionStorage.setItem('adminUser', 'false')
  }

  const save = async () => {
    let valid = !formData.USER_NAME ? 'Please Fill User Name!' :
      !formData.USER_FULL_NAME ? 'Please Fill User Full Name!' :
        !formData.USER_PASSWORD ? 'Please Fill User Password!' :
          !formData.USER_TYPE ? 'Please Choose User Type!' :
            !formData.USER_ORG ? 'Please Choose Organization!' :
              !formData.CREATED_DATE ? 'Please Fill Created Date!' :
                !formData.USER_EXPIRED ? 'Please Fill Expired Date!' :
                  !formData.CREATED_BY ? 'Please Fill Creater!' :
                    !formData.USER_SALT ? 'Please Choose User Salt!' :
                      'valid'
    if (valid == 'valid') {

      //console.log('Form Data ', formData)
      let a = {
        USER_ID: maxUserID,
        USER_FULL_NAME: formData.USER_FULL_NAME,
        USER_ORG: formData.USER_ORG,
        USER_NAME: formData.USER_NAME,
        USER_TYPE: formData.USER_TYPE,
        USER_PASSWORD: formData.USER_PASSWORD,
        CREATED_BY: formData.CREATED_BY,
        CREATED_DATE: formData.CREATED_DATE,
        USER_STATUS: formData.USER_STATUS,
        USER_SALT: formData.USER_SALT,
        USER_EXPIRED: formData.USER_EXPIRED,
        USER_CLN: formData.USER_CLN,
      }
      const res = await insertUser(a)
      if (res?.status === 200) {
        setSuccess("Successfully inserted new user");
        maxUserIDCal()
        setSuccessSnack(true);
        setTimeout(function () {
          handleClose()
        }, 1500);
      }

    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  return (
    <>
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      {(sessionStorage.getItem('adminHome') == 'true') && <AdminPage />}
      {(sessionStorage.getItem('adminUser') == 'true') && <Grid container >
        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px', marginRight: '3%' }}>
          <Stack direction="row" spacing={2} justifyContent={'flex-end'} style={{ margin: '10px' }}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" endIcon={<HomeIcon />} onClick={GoHome} color="primary" >
                Go Back
              </Button>
              <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen} color='secondary'>
                Add New User
              </Button>

              {/* dialog */}
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
              /*  onClose={handleClose} */
              >
                <DialogTitle align='center' style={{ backgroundColor: '#41273b', color: '#f8dadd' }}>Add New User</DialogTitle>
                <DialogContent>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      m: 'auto',
                      width: 'fit-content',
                    }}>
                    {/* 	*/}

                    {/* list */}
                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'} >
                        <TextField
                          id="outlined-basic" label="USER_NAME" variant="outlined"
                          required
                          value={formData.USER_NAME}
                          onChange={e => {
                            setFormData({ ...formData, USER_NAME: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField
                          id="outlined-basic" label="USER_FULL_NAME" variant="outlined"
                          required
                          value={formData.USER_FULL_NAME}
                          onChange={e => {
                            setFormData({ ...formData, USER_FULL_NAME: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField required id="outlined-password-input" label="USER_PASSWORD" variant="outlined"
                          value={formData.USER_PASSWORD}
                          onChange={e => {
                            setFormData({ ...formData, USER_PASSWORD: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} >
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "89%" }}
                            required
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              User Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={formData.USER_TYPE}
                              onChange={(e) => {
                                setFormData({ ...formData, USER_TYPE: e.target.value })
                              }}
                            >
                              {usertype.length &&
                                usertype.map((option) => (
                                  <MenuItem value={option?.value}>
                                    {option?.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} >
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "89%" }}
                            required
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              Organization
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={formData.USER_ORG}
                              onChange={(e) => {
                                setFormData({ ...formData, USER_ORG: e.target.value })
                              }}
                              MenuProps={{
                                style: {
                                  maxHeight: 400,
                                  maxWidth: 300
                                },
                              }}>
                              {orgData.length &&
                                orgData.map((option) => (
                                  <MenuItem value={option?.ORG_ID}>
                                    {option?.ORG_SHORTNAME}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <TextField
                          id="outlined-basic" label="USER_CLN" variant="outlined"
                          value={formData.USER_CLN}
                          onChange={e => {
                            setFormData({ ...formData, USER_CLN: e.target.value })
                          }} />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} marginTop={'30px'}>
                        <TextField id="outlined-basic" label="CREATED_DATE" variant="outlined" type='date'
                          InputLabelProps={{ shrink: true }}
                          required
                          style={{ width: '90%' }}
                          value={formData.CREATED_DATE}
                          onChange={e => {
                            setFormData({ ...formData, CREATED_DATE: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} >
                        <TextField id="outlined-basic" label="EXPIRED_DATE" variant="outlined" type='date'
                          InputLabelProps={{ shrink: true }}
                          required
                          style={{ width: '90%' }}
                          value={formData.USER_EXPIRED}
                          onChange={e => {
                            setFormData({ ...formData, USER_EXPIRED: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'} >
                        <TextField id="outlined-basic" label="Creater" variant="outlined"
                          value={formData.CREATED_BY}
                          required
                          onChange={e => {
                            setFormData({ ...formData, CREATED_BY: e.target.value })
                          }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "89%" }}

                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              USER_STATUS
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={formData.USER_STATUS}
                              onChange={(e) => {
                                setFormData({ ...formData, USER_STATUS: e.target.value })
                              }}>
                              <MenuItem value={1}>
                                1
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} margin={'10px'}>
                        <ThemeProvider theme={theme}>
                          <FormControl
                            variant="filled"
                            sx={{ width: "89%" }}
                            required
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              User_salt
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={formData.USER_SALT}
                              onChange={(e) => {
                                setFormData({ ...formData, USER_SALT: e.target.value })
                              }}
                              MenuProps={{
                                style: {
                                  maxHeight: 400,
                                  maxWidth: 300
                                },
                              }}>
                              {usersalt.length &&
                                usersalt.map((option) => (
                                  <MenuItem value={option?.value}>
                                    {option?.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Grid>
                    </Grid>

                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                  <Button onClick={save} variant="contained" color="success">Save</Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          </Stack>
        </Grid>

        <Grid item xs={12} ms={12} md={12} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} >
          <Stack direction="row" spacing={2} justifyContent={'center'} >
            <TableContainer style={{ width: '93%', maxHeight: '59.5vh', }} >
              <Table
                stickyHeader
                aria-label="spanning table"
                size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>USER_FULL_NAME</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>USER_ORG</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>USER_NAME</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>USER_TYPE</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>USER_PASSWORD</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>USER_SALT</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>USER_CLN</TableCell>
                    <TableCell align="center" style={{ color: '#f8dadd', background: '#41273b', fontWeight: 'bold' }}>Edit</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody >

                  {userData.length ?
                    userData.map((row) => (
                      <TableRow>
                        <TableCell align="center" >{row.USER_FULL_NAME}</TableCell>
                        <TableCell align="center" >{row.USER_ORG}</TableCell>
                        <TableCell align="center" >{row.USER_NAME}</TableCell>
                        <TableCell align="center" >{row.USER_TYPE}</TableCell>
                        <TableCell align="center" >{row.USER_PASSWORD}</TableCell>
                        <TableCell align="center" >{row.USER_SALT}</TableCell>
                        <TableCell align="center" >{row.USER_CLN}</TableCell>
                        <TableCell align="center">
                          <IconButton >
                            <EditIcon style={{ color: "#41273b" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    : null}

                </TableBody>



              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>}
    </>
  )
}

export default UsrEdit