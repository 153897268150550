import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { Card } from "@mui/material";
import { Button, Typography } from "@material-ui/core";
import Modals from "../../components/modal";
import _ from 'lodash';

/////////Form////////////
import OTPEditForm from "../IMAM_OTP/OTPEditForm"

////////Controls////////
import OTPPatientSearchBar from "../../components/controls/OTPPatientSearchBar";

////////API////////
import * as edit from '../../modals/editImamShow'

const EditOTPForm = props => {
    //FPPatientData
  
  const [searchData, setSearchData] = useState([])
  const [tableData, setTableData] = useState([])
    
    ///////Background Data///////////
    const [loading, setLoading] = useState(false);
    const [editPage, setEditPage] = useState(false)

    /* useEffect(() => {
        const fn = async () => {
          setLoading(true)
          const searchData = await edit.editImamShow()
          if(searchData)
          {
            setSearchData(searchData.data.data.editImamShow)
            console.log("IMAM getPatientForSearch => ", searchData.data.data.editImamShow)
          }
          setLoading(false)
        }
    
        fn();
    
      }, [])  */

      useEffect(() => {
        const fn = async () => {

            const edit = sessionStorage.getItem('editOTPPatient')
            setEditPage(edit)
            console.log("editOTPPatient from OTP edit form", edit)
        }
        fn();
    }, [sessionStorage.getItem('editOTPPatient')])


    return(
        <div style={{ background: '#fcf0f2' }}>
      <Modals open={loading} />
      <Card style={{ background: '#fcf0f2' }}>
        {(editPage === "false") ?
          <><Typography variant="h5" align="center" style={{ color: '#53344d', background: '#fcf0f2', fontWeight: 'bold', padding: '1%' }}>
            Actue Malnutrition Register Information</Typography>
            <OTPPatientSearchBar placeholder="Enter Name or ID..."  searchData={searchData} /* tableData={RHPatientData} *//></>

          : <OTPEditForm editPage={editPage}></OTPEditForm>}


      </Card >

    </div >
    )
}

export default EditOTPForm;