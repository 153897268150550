import axios from 'axios';
import {api} from '../utils/helper';

//****************** Insert clinic ******************//
export const insertClinic = async (a) => {
    try{
        const resrep = await axios.post(`${api}/insertclinic`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('insertClinic controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('insertClinic controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }


//****************** Update clinic ******************//
export const updateClinic = async (a) => {
    try{
        const resrep = await axios.put(`${api}/updateclinic`,a, {
            headers: {
              authorization: sessionStorage.getItem('token'),
            },
          },);
          console.log('updateClinic controller res: ', resrep);
          return resrep;
    }catch (error) {
        console.log('updateClinic controller error: ', error?.response?.data);
        alert(error?.response?.data?.message);
    }
  
  }