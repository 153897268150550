import React, { useState, useEffect } from 'react'
import ctry from '../../images/ctry.png'
import clinic from '../../images/clinic.png'
import div from '../../images/div.png'
import org from '../../images/org.png'
import project from '../../images/project.png'
import tsp from '../../images/tsp.png'
import usr from '../../images/usr.png'
import village from '../../images/village.png'
import dono from '../../images/dono.png'
import Card from '@mui/material/Card';
import dist from '../../images/dist.png'
import EditCountryTest from './country/EditCountry'
import EditDivision from './division/EditDivision';
import EditClinic from './clinic/EditClinic';
import EditOrganization from './organization/EditOrganization';
import EditProject from './project/EditProject';
import AddProject from './project/AddProject';
import EditDonor from './donor/EditDonor';
import AddDonor from './donor/AddDonor';
import EditTownship from './township/EditTownship';
import EditVillage from './village/EditVillage';
import UsrEdit from './user/UsrEdit';
import EditDist from './district/EditDist';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useHistory, useLocation } from 'react-router-dom';
import EditCountry from './country/EditCountry'

function AdminHome() {


  const [homeShow, setHomeShow] = useState(false)
  const [ctryShow, setCtryShow] = useState(false)
  const [divShow, setDivShow] = useState(false)
  const [distShow, setDistShow] = useState(false)
  const [tspShow, setTspShow] = useState(false)
  const [vilShow, setVilShow] = useState(false)
  const [orgShow, setOrgShow] = useState(false)
  const [projShow, setProjShow] = useState(false)
  const [clnShow, setClnShow] = useState(false)
  const [userShow, setUserShow] = useState(false)
  const [donorShow, setDonorShow] = useState(false)

  const showHandle = (home, ctry, div, dist, tsp, vil, org, proj, cln, user, donor) => {
    setHomeShow(home)
    setCtryShow(ctry)
    setDivShow(div)
    setDistShow(dist)
    setTspShow(tsp)
    setVilShow(vil)
    setOrgShow(org)
    setProjShow(proj)
    setClnShow(cln)
    setUserShow(user)
    setDonorShow(donor)
  }

  const goctry = async () => {
    showHandle(false, true, false, false, false, false, false, false, false, false, false)
    sessionStorage.setItem('adminHome','false')
    sessionStorage.setItem('adminCtry','true')
  }
  const godiv = async () => {
    showHandle(false, false, true, false, false, false, false, false, false, false, false)
    sessionStorage.setItem('adminHome', 'false')
    sessionStorage.setItem('adminDiv','true')
  }
  const gocli = async () => {
    showHandle(false, false, false, false, false, false, false, false, true, false, false)
    sessionStorage.setItem('adminHome', 'false')
    sessionStorage.setItem('adminCln','true')
  }
  const goorg = async () => {
    showHandle(false, false, false, false, false, false, true, false, false, false, false)
    sessionStorage.setItem('adminHome', 'false')
    sessionStorage.setItem('adminOrg','true')
  }
  const goproj = async () => {
    showHandle(false, false, false, false, false, false, false, true, false, false, false)
    sessionStorage.setItem('adminHome', 'false')
    sessionStorage.setItem('adminProj','true')
  }
  const godon = async () => {
    showHandle(false, false, false, false, false, false, false, false, false, false, true)
    sessionStorage.setItem('adminHome','false')
    sessionStorage.setItem('adminDonor','true')
  }
  const gotsp = async () => {
    showHandle(false, false, false, false, true, false, false, false, false, false, false)
    sessionStorage.setItem('adminHome','false')
    sessionStorage.setItem('adminTsp','true')
  }
  const govlg = async () => {
    showHandle(false, false, false, false, false, true, false, false, false, false, false)
    sessionStorage.setItem('adminHome','false')
    sessionStorage.setItem('adminVil','true')
  }
  const gousr = async () => {
    showHandle(false, false, false, false, false, false, false, false, false, true, false)
    sessionStorage.setItem('adminHome','false')
    sessionStorage.setItem('adminUser','true')
  }
  const godist = async () => {
    showHandle(false, false, false, true, false, false, false, false, false, false, false)
    sessionStorage.setItem('adminHome','false')
    sessionStorage.setItem('adminDist','true')
  }
  return (
    <>
      {(sessionStorage.getItem('adminCtry')=='true') && <EditCountry />}
      {(sessionStorage.getItem('adminDiv')=='true') && <EditDivision />}
      {(sessionStorage.getItem('adminDist')=='true') && <EditDist />}
      {(sessionStorage.getItem('adminTsp')=='true') && <EditTownship />}
      {(sessionStorage.getItem('adminVil')=='true') && <EditVillage />}
      {(sessionStorage.getItem('adminOrg')=='true') && <EditOrganization />}
      {(sessionStorage.getItem('adminProj')=='true') && <EditProject />}
      {(sessionStorage.getItem('adminCln')=='true') && <EditClinic />}
      {(sessionStorage.getItem('adminUser')=='true') && <UsrEdit />}
      {(sessionStorage.getItem('adminDonor')=='true') && <EditDonor />}
      {(sessionStorage.getItem('adminHome')=='true') &&
        <Grid container alignItems='center' alignContent='center' textAlign='center' marginBottom='20px' >

          {/*country */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={goctry} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={ctry}
                  alt="green iguana"
                />
              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>Country</Button>

            </Card>
          </Grid>


          {/*   division */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={godiv} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={div}
                  alt="green iguana"
                />

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>Division</Button>
            </Card>
          </Grid>

          {/*   distract */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={godist} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={dist}
                  alt="green iguana"
                />
                {/* <CardContent style={{backgroundColor:'#D91E4D',width:'100%',margin:'auto'}}>
       <Typography variant="h6"  align='center' style={{width:'100%',color:'#ffffff'}}>Country</Typography>
      </CardContent> */}

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>District</Button>

            </Card>
          </Grid>

          {/*   township */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={gotsp} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={tsp}
                  alt="green iguana"
                />
                {/* <CardContent style={{backgroundColor:'#D91E4D',width:'100%',margin:'auto'}}>
       <Typography variant="h6"  align='center' style={{width:'100%',color:'#ffffff'}}>Country</Typography>
      </CardContent> */}

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>Township</Button>

            </Card>
          </Grid>


          {/*   village */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={govlg} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={village}
                  alt="green iguana"
                />
                {/* <CardContent style={{backgroundColor:'#D91E4D',width:'100%',margin:'auto'}}>
       <Typography variant="h6"  align='center' style={{width:'100%',color:'#ffffff'}}>Country</Typography>
      </CardContent> */}

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>Village</Button>

            </Card>
          </Grid>


          {/*   organization */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={goorg} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={org}
                  alt="green iguana"
                />
                {/* <CardContent style={{backgroundColor:'#D91E4D',width:'100%',margin:'auto'}}>
       <Typography variant="h6"  align='center' style={{width:'100%',color:'#ffffff'}}>Country</Typography>
      </CardContent> */}

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>Organization</Button>

            </Card>
          </Grid>


          {/*   project */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={goproj} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={project}
                  alt="green iguana"
                />
                {/* <CardContent style={{backgroundColor:'#D91E4D',width:'100%',margin:'auto'}}>
       <Typography variant="h6"  align='center' style={{width:'100%',color:'#ffffff'}}>Country</Typography>
      </CardContent> */}

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>Project</Button>

            </Card>
          </Grid>

          {/*   clinic */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={gocli} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={clinic}
                  alt="green iguana"
                />
                {/* <CardContent style={{backgroundColor:'#D91E4D',width:'100%',margin:'auto'}}>
       <Typography variant="h6"  align='center' style={{width:'100%',color:'#ffffff'}}>Country</Typography>
      </CardContent> */}

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>Clinic</Button>

            </Card>
          </Grid>


          {/*   user */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={gousr}>
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={usr}
                  alt="green iguana"
                />
                {/* <CardContent style={{backgroundColor:'#D91E4D',width:'100%',margin:'auto'}}>
       <Typography variant="h6"  align='center' style={{width:'100%',color:'#ffffff'}}>Country</Typography>
      </CardContent> */}

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>User</Button>

            </Card>
          </Grid>

          {/* donors */}
          <Grid item xs={6} ms={3} md={3} textAlign='center' style={{ textAlign: 'center', marginTop: '20px' }} onClick={godon} >
            <Card elevation={3} style={{ width: '75%', alignSelf: 'center', margin: 'auto', borderRadius: 0 }}>
              <CardActionArea style={{ direction: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%', }}>
                <CardMedia
                  sx={{ objectFit: "contain" }}
                  height="150"
                  component="img"
                  image={dono}
                  alt="green iguana"
                />
                {/* <CardContent style={{backgroundColor:'#D91E4D',width:'100%',margin:'auto'}}>
       <Typography variant="h6"  align='center' style={{width:'100%',color:'#ffffff'}}>Country</Typography>
      </CardContent> */}

              </CardActionArea>
              <Button style={{ backgroundColor: '#D91E4D', color: 'white', width: '100%', borderRadius: 0 }}>Donor</Button>

            </Card>
          </Grid>



        </Grid>
      }



    </>



  )
}

export default AdminHome