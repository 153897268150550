import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment, { fn } from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomTextField from "../../components/controls/CustomTextFieldFilled";
import CustomUnicefTextField from '../../components/controls/CustomUnicefTextField'
import { Card } from "@mui/material";
import { Button, Chip, OutlinedInput, Snackbar, SnackbarContent, Switch, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, List, ListItem } from "@material-ui/core";
import TableHead from '@material-ui/core/TableHead';
import _ from 'lodash';

//////////////API/////////////////
import { getEWARDivision, getEWARTsp, getEWARClinic, getEWARVillage, getEWARDisease } from "../../modals/ewardata";
import { insertEWAR } from "../../modals/ewarinfo";

import CustomSnackBar from "../../components/controls/CustomSnackBar";

const under = ' <5 '
const above = ' >=5 '

const useStyles = makeStyles((theme) => ({
    container: {
        width: '98%',
        borderRadius: '5px',
        position: 'revert',

        marginLeft: '1%',
        marginRight: '1%',
        marginTop: '10px',
    },

    table: {
        width: '100%',
        background: '#fcf0f2',
        height: '10px',


    },
    cellOne: {
        borderBottom: 'none',
        color: '#808080',
        fontSize: '9pt',
    },
    cellTwo: {
        borderBottom: 'none',
        color: '#53344d',
        fontSize: '12pt',
        fontWeight: 'bold',
    },
    tableRightBorder: {
        borderWidth: 0,
        borderRightWidth: 2,
        borderTopWidth: 2,
        borderColor: 'lightgrey',
        borderStyle: 'solid',
    },
    tableLastRightBorder: {
        borderWidth: 0,
        borderRightWidth: 2,
        borderTopWidth: 2,
        borderBottomWidth: 2,
        borderColor: 'lightgrey',
        borderStyle: 'solid',
    },
    tableTopBorder: {
        borderWidth: 0,
        borderTopWidth: 2,
        borderColor: 'lightgrey',
        borderStyle: 'solid',
    },
}));

const radioTheme = createTheme({
    palette: {
        primary: {
            main: "#482642"
        },
        secondary: {
            main: "#ffff"
        }
    }
});

export default function EWARForm() {
    const classes = useStyles();

    const history = useHistory();
    const [division, setDivision] = useState([])
    const [selectedDiv, setSelectedDiv] = useState('999')
    const [tsp, setTsp] = useState([])
    const [selectedTsp, setSelectedTsp] = useState('999')
    const [clinic, setClinic] = useState([])
    const [selectedClinic, setSelectedClinic] = useState('999')
    const [remark, setRemark] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [village, setVillage] = useState([])
    const [selectedVillage, setSelectedVillage] = useState('999')

    const [disease, setDisease] = useState([])
    const [form, setForm] = useState([])

    const [change, setChange] = useState(false)

    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [successSnack, setSuccessSnack] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)

    useEffect(async () => {
        let div = await getEWARDivision();
        let diseaseData = await getEWARDisease();
        if (div && diseaseData) {
            console.log('disease : ', diseaseData.data.data.getEWARDisease)
            setDivision(div.data.data.getEWARDivision)
            setDisease(diseaseData.data.data.getEWARDisease)
            let dis = diseaseData.data.data.getEWARDisease
            let formArr = []
            for (let i = 0; i < dis.length; i++) {
                formArr.push({
                    DIS_CODE: dis[i].DIS_CODE,
                    EWR_PROVIDEDDATE: '',
                    EWR_PROVIDERPOSITION: '',
                    EWR_PROVIDERNAME: '',
                    DIV_ID: '',
                    TSP_ID: '',
                    CLN_ID: '',
                    VIL_ID: '',
                    EWR_Year: '',
                    EWR_Month: '',
                    EWR_CMUD5: 0,
                    EWR_CMUP5: 0,
                    EWR_CFUD5: 0,
                    EWR_CFUP5: 0,
                    EWR_DMUD5: 0,
                    EWR_DMUP5: 0,
                    EWR_DFUD5: 0,
                    EWR_DFUP5: 0,
                    EWR_CTOTAL: 0,
                    EWR_DTOTAL: 0,
                    PROJ_ID: sessionStorage.getItem('project'),
                    EWR_REMARK: '',
                    ORG_ID: sessionStorage.getItem('org'),
                    USR_LOGIN: sessionStorage.getItem('userName'),
                    EWR_INSERT: '',
                    EWR_UPDATE: '',
                    EWR_STATUS: '1',
                    EWR_SYNC: '0',
                })
            }
            setForm(formArr)
            console.log('Form Array : ', formArr)

        }
    }, [])

    const setSnackBarOpen = () => {
        setOpenSnack(true)
    }

    const setSnackBarClose = () => {
        setOpenSnack(false)
    }

    const setSuccessSnackBarOpen = () => {
        setSuccessSnack(true)
    }

    const setSuccessSnackBarClose = () => {
        setSuccessSnack(false)
    }

    const divHandleChange = async (event) => {
        setSelectedDiv(event.target.value)
        const a = { projID: sessionStorage.getItem('project'), orgID: sessionStorage.getItem('org'), divID: event.target.value }
        let tspData = await getEWARTsp(a)
        if (tspData) {
            console.log('Tsp : ', tspData.data.data.getEWARTsp)
            setTsp(tspData.data.data.getEWARTsp)
        }
    }

    const tspHandleChange = async (event) => {
        setSelectedTsp(event.target.value)
        const a = { projID: sessionStorage.getItem('project'), orgID: sessionStorage.getItem('org'), tspID: event.target.value }
        let clnData = await getEWARClinic(a)
        if (clnData) {
            console.log('Clinic : ', clnData.data.data.getEWARClinic)
            setClinic(clnData.data.data.getEWARClinic)
        }
    }

    const clinicHandleChange = async (event) => {
        setSelectedClinic(event.target.value)
        /*  const a = { projID: sessionStorage.getItem('project'), orgID: sessionStorage.getItem('org'), clnID: event.target.value }
         let villageData = await getEWARVillage(a)
         if (villageData) {
             console.log('Village : ', villageData.data.data.getEWARVillage)
             setVillage(villageData.data.data.getEWARVillage)
         } */
    }

    const villageHandleChange = async (event) => {
        setSelectedVillage(event.target.value)
    }

    const CMUD5Handle = async (e, idx) => {
        setChange(!change)
        let clone = [...form];
        let obj = clone[idx];
        obj.EWR_CMUD5 = parseInt(e.target.value);
        clone[idx] = obj;
        setForm([...clone])
        //console.log('Form ===. ', form)
    }
    const CMUP5Handle = async (e, idx) => {
        setChange(!change)
        let clone = [...form];
        let obj = clone[idx];
        obj.EWR_CMUP5 = parseInt(e.target.value);
        clone[idx] = obj;
        setForm([...clone])
        //console.log('Form ===. ', form)
    }
    const CFUD5Handle = async (e, idx) => {
        setChange(!change)
        let clone = [...form];
        let obj = clone[idx];
        obj.EWR_CFUD5 = parseInt(e.target.value);
        clone[idx] = obj;
        setForm([...clone])
        //console.log('Form ===. ', form)
    }

    const CFUP5Handle = async (e, idx) => {
        setChange(!change)
        let clone = [...form];
        let obj = clone[idx];
        obj.EWR_CFUP5 = parseInt(e.target.value);
        clone[idx] = obj;
        setForm([...clone])
        //console.log('Form ===. ', form)
    }

    const DMUD5Handle = async (e, idx) => {
        setChange(!change)
        let clone = [...form];
        let obj = clone[idx];
        obj.EWR_DMUD5 = parseInt(e.target.value);
        clone[idx] = obj;
        setForm([...clone])
        //console.log('Form ===. ', form)
    }

    const DMUP5Handle = async (e, idx) => {
        setChange(!change)
        let clone = [...form];
        let obj = clone[idx];
        obj.EWR_DMUP5 = parseInt(e.target.value);
        clone[idx] = obj;
        setForm([...clone])
        //console.log('Form ===. ', form)
    }

    const DFUD5Handle = async (e, idx) => {
        setChange(!change)
        let clone = [...form];
        let obj = clone[idx];
        obj.EWR_DFUD5 = parseInt(e.target.value);
        clone[idx] = obj;
        setForm([...clone])
        //console.log('Form ===. ', form)
    }

    const DFUP5Handle = async (e, idx) => {
        setChange(!change)
        let clone = [...form];
        let obj = clone[idx];
        obj.EWR_DFUP5 = parseInt(e.target.value);
        clone[idx] = obj;
        setForm([...clone])
        //console.log('Form ===. ', form)
    }

    const clear = () => {
        setYear('999')
        setMonth('999')
        setSelectedDiv('999')
        setSelectedTsp('999')
        setSelectedClinic('999')
        setRemark('')
        for (let i = 0; i < form.length; i++) {
            form[i].EWR_CTOTAL = 0
            form[i].EWR_DTOTAL = 0
            form[i].EWR_CMUD5 = 0
            form[i].EWR_CMUP5 = 0
            form[i].EWR_CFUD5 = 0
            form[i].EWR_CFUP5 = 0
            form[i].EWR_DMUD5 = 0
            form[i].EWR_DMUP5 = 0
            form[i].EWR_DFUD5 = 0
            form[i].EWR_DFUP5 = 0
        }
    }

    const cancle = () => {
        history.push('entryhomepage')
        sessionStorage.setItem('homeSave', 'done')
    }

    const save = async () => {

        const valid = (!month || month === 999) ? 'Please choose Report Month' :
            (!year || year === 999) ? 'Please choose Report Year' :
                (!selectedDiv || selectedDiv === '999') ? 'Please choose Region' :
                    (!selectedTsp || selectedTsp === '999') ? 'Please choose Township' :
                        (!selectedClinic || selectedClinic === '999') ? 'Please choose Clinic' :
                            'valid'
        if (valid === 'valid') {
            for (let i = 0; i < form.length; i++) {
                form[i].EWR_INSERT = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
                form[i].EWR_UPDATE = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
                form[i].DIV_ID = selectedDiv
                form[i].TSP_ID = selectedTsp
                form[i].CLN_ID = selectedClinic
                form[i].EWR_Year = year
                form[i].EWR_Month = month
                form[i].EWR_CTOTAL = form[i].EWR_CMUD5 + form[i].EWR_CMUP5 + form[i].EWR_CFUD5 + form[i].EWR_CFUP5
                form[i].EWR_DTOTAL = form[i].EWR_DMUD5 + form[i].EWR_DMUP5 + form[i].EWR_DFUD5 + form[i].EWR_DFUP5
                form[i].EWR_REMARK = remark
                let formData = form[i]
                const res = await insertEWAR({ formData })
                if (res?.status === 200) {
                    console.log('Success!')

                }
            }
            setSuccess('Successfully insert EWAR Data')
            setSuccessSnackBarOpen()
            clear()
            //console.log('Form ====> ', form)
        }
        else {
            setError(valid)
            setSnackBarOpen()
        }

    }

    return (
        <>
            {openSnack && <CustomSnackBar open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
            {successSnack && <CustomSnackBar open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
            <Typography variant="h5" align="center" style={{ color: '#53344d', fontWeight: 'bold', padding: '1%' }}>
                EWARS</Typography>
            <Grid container row justify="center">
                {/* <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        id="filled-basic"
                        label="Provider Name"
                        variantText="filled"
                        style={{ width: '90%' }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Provider Position</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        variantText="filled"
                        style={{ width: '90%' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option value={999}>-</option>
                        <option value={1}>EmOCW</option>
                        <option value={2}>MCHW</option>
                        <option value={3}>Medic</option>
                        <option value={4}>CHW</option>
                        <option value={5}>AMW</option>
                        <option value={6}>Nurse</option>
                        <option value={7}>Doctor</option>
                        <option value={8}>TTBA/TBA</option>
                        <option value={9}>Other</option>
                        <option value={13}>Midwife</option>
                        {(sessionStorage.getItem('org') === 'CPI-05' || sessionStorage.getItem('org') === 'CPI-06' || sessionStorage.getItem('org') === 'CPI-07') ?
                            <option value={16} >PA(Physician Assistant)</option> : null}
                        {(sessionStorage.getItem('project') === 'P-008' ||
                            sessionStorage.getItem('org') === 'CPI-17' || sessionStorage.getItem('org') === 'CPI-18' ||
                            sessionStorage.getItem('org') === 'CPI-19' || sessionStorage.getItem('org') === 'CPI-63' || sessionStorage.getItem('org') === 'CPI-86') ?
                            <option value={16} >VHW</option> : null}
                    </CustomUnicefTextField>
                </Grid> */}
                <Grid item xs={12} sm={12} md={1} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Year</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        value={year}
                        onChange={(e) => { setYear(e.target.value) }}
                        variantText="filled"
                        style={{ width: '90%' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                            shrink: true
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option value={999}>-</option>
                        <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
                        <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                        <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                        <option value={new Date().getFullYear() + 2}>{new Date().getFullYear() + 2}</option>
                    </CustomUnicefTextField>
                </Grid>
                <Grid item xs={12} sm={12} md={1} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Month</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        value={month}
                        onChange={(e) => { setMonth(e.target.value) }}
                        variantText="filled"
                        style={{ width: '90%' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                            shrink: true
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option value={999}>-</option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                    </CustomUnicefTextField>
                </Grid>

                <Grid item xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Region</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        value={selectedDiv}
                        onChange={divHandleChange}
                        variantText="filled"
                        style={{ width: '90%' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                            shrink: true
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option classes={{ selected: classes.selected }} value={'999'}> -</option>
                        {division.length &&
                            division.map((option) => (
                                <option key={option.DIV_ID} value={option.DIV_ID}>
                                    {option.DIV_NAME}
                                </option>
                            ))}
                    </CustomUnicefTextField>

                </Grid>
                <Grid item xs={12} sm={12} md={2} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Township</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        value={selectedTsp}
                        onChange={tspHandleChange}
                        variantText="filled"
                        style={{ width: '90%' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                            shrink: true
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option classes={{ selected: classes.selected }} value={'999'}> -</option>
                        {tsp.length &&
                            tsp.map((option) => (
                                <option key={option.TSP_ID} value={option.TSP_ID}>
                                    {option.TSP_NAME}
                                </option>
                            ))}
                    </CustomUnicefTextField>

                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Clinic</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        value={selectedClinic}
                        onChange={clinicHandleChange}
                        variantText="filled"
                        style={{ width: '90%' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                            shrink: true
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option classes={{ selected: classes.selected }} value={'999'}> -</option>
                        {clinic.length &&
                            clinic.map((option) => (
                                <option key={option.CLN_ID} value={option.CLN_ID}>
                                    {option.CLN_NAME}
                                </option>
                            ))}
                    </CustomUnicefTextField>

                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        id="filled-basic"
                        label="Remark"
                        variantText="filled"
                        style={{ width: '90%' }}
                        onChange={e => { setRemark(e.target.value) }}
                        value={remark}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center' }}>
                    <CustomUnicefTextField
                        label={<Grid row container><Typography color="#482642">Village</Typography>
                            <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                        select
                        value={selectedVillage}
                        onChange={villageHandleChange}
                        variantText="filled"
                        style={{ width: '90%', marginTop: '20px' }}
                        InputLabelProps={{
                            style: { color: '#482642' },
                            shrink: true
                        }}
                        SelectProps={{
                            native: true
                        }}>
                        <option classes={{ selected: classes.selected }} value={'999'}> -</option>
                        {village.length &&
                            village.map((option) => (
                                <option key={option.VILLAGE_CODE} value={option.VILLAGE_CODE}>
                                    {option.VILLAGENAME}
                                </option>
                            ))}
                    </CustomUnicefTextField>

                </Grid> */}
            </Grid>
            <TableContainer
                className={classes.container}>
                <Table
                    aria-label="spanning table"
                    size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} rowSpan={3} className={classes.tableRightBorder}>No.</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold', width: '23%' }} rowSpan={3} className={classes.tableRightBorder}>Disease</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold', width: '8%' }} rowSpan={3} className={classes.tableRightBorder}>Disease Code</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} colSpan={4} className={classes.tableRightBorder}>Cases</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} colSpan={4} className={classes.tableRightBorder}>Death</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold', width: '4%'}} rowSpan={3} className={classes.tableRightBorder}>Total Cases</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold', width: '4%'}} rowSpan={3} className={classes.tableTopBorder}>Total Death</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} colSpan={2} className={classes.tableRightBorder}>Male</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} colSpan={2} className={classes.tableRightBorder}>Female</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} colSpan={2} className={classes.tableRightBorder}>Male</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} colSpan={2} className={classes.tableRightBorder}>Female</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold'}} className={classes.tableRightBorder}>{under}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} className={classes.tableRightBorder}>{above}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold'}} className={classes.tableRightBorder}>{under}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} className={classes.tableRightBorder}>{above}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold'}} className={classes.tableRightBorder}>{under}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} className={classes.tableRightBorder}>{above}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold'}} className={classes.tableRightBorder}>{under}</TableCell>
                            <TableCell align="center" style={{ color: '#53344d', background: '#e3dbdd', fontWeight: 'bold' }} className={classes.tableRightBorder}>{above}</TableCell>
                        </TableRow>

                    </TableHead>
                    {disease.length && form.length &&
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >1.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[0].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[0].DIS_CODELBL}</TableCell>
                                <TableCell align="center" className={classes.tableRightBorder}>
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 0) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 0) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 0) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 0) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 0) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 0) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 0) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 0) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_CMUD5 + form[0].EWR_CMUP5 + form[0].EWR_CFUD5 + form[0].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[0].EWR_DMUD5 + form[0].EWR_DMUP5 + form[0].EWR_DFUD5 + form[0].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >2.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[1].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[1].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 1) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 1) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 1) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 1) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 1) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 1) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 1) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 1) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_CMUD5 + form[1].EWR_CMUP5 + form[1].EWR_CFUD5 + form[1].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[1].EWR_DMUD5 + form[1].EWR_DMUP5 + form[1].EWR_DFUD5 + form[1].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >3.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[2].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[2].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 2) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 2) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 2) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 2) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 2) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 2) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 2) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 2) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_CMUD5 + form[2].EWR_CMUP5 + form[2].EWR_CFUD5 + form[2].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[2].EWR_DMUD5 + form[2].EWR_DMUP5 + form[2].EWR_DFUD5 + form[2].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >4.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[3].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[3].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 3) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 3) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 3) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 3) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 3) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 3) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 3) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 3) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_CMUD5 + form[3].EWR_CMUP5 + form[3].EWR_CFUD5 + form[3].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[3].EWR_DMUD5 + form[3].EWR_DMUP5 + form[3].EWR_DFUD5 + form[3].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >5.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[4].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[4].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 4) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 4) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 4) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 4) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 4) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 4) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 4) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 4) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_CMUD5 + form[4].EWR_CMUP5 + form[4].EWR_CFUD5 + form[4].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[4].EWR_DMUD5 + form[4].EWR_DMUP5 + form[4].EWR_DFUD5 + form[4].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >6.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[5].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[5].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 5) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 5) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 5) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 5) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 5) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 5) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 5) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 5) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_CMUD5 + form[5].EWR_CMUP5 + form[5].EWR_CFUD5 + form[5].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[5].EWR_DMUD5 + form[5].EWR_DMUP5 + form[5].EWR_DFUD5 + form[5].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >7.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[6].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[6].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 6) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 6) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 6) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 6) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 6) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 6) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 6) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 6) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_CMUD5 + form[6].EWR_CMUP5 + form[6].EWR_CFUD5 + form[6].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[6].EWR_DMUD5 + form[6].EWR_DMUP5 + form[6].EWR_DFUD5 + form[6].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >8.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[7].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[7].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 7) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 7) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 7) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 7) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 7) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 7) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 7) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 7) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_CMUD5 + form[7].EWR_CMUP5 + form[7].EWR_CFUD5 + form[7].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[7].EWR_DMUD5 + form[7].EWR_DMUP5 + form[7].EWR_DFUD5 + form[7].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >9.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[8].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[8].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 8) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 8) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 8) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 8) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 8) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 8) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 8) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 8) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_CMUD5 + form[8].EWR_CMUP5 + form[8].EWR_CFUD5 + form[8].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[8].EWR_DMUD5 + form[8].EWR_DMUP5 + form[8].EWR_DFUD5 + form[8].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >10.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[9].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[9].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 9) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 9) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 9) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 9) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 9) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 9) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 9) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 9) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_CMUD5 + form[9].EWR_CMUP5 + form[9].EWR_CFUD5 + form[9].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[9].EWR_DMUD5 + form[9].EWR_DMUP5 + form[9].EWR_DFUD5 + form[9].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >11.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[10].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[10].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 10) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 10) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 10) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 10) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 10) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 10) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 10) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 10) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_CMUD5 + form[10].EWR_CMUP5 + form[10].EWR_CFUD5 + form[10].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[10].EWR_DMUD5 + form[10].EWR_DMUP5 + form[10].EWR_DFUD5 + form[10].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >12.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[11].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[11].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[11].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 11) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" style={{ background: 'lightgrey' }}>
                                    {/* <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%',background:'lightgrey' }}
                                        disabled
                                        value={form[11].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 11) }} /> */} </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[11].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 11) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" style={{ background: 'lightgrey' }}>
                                    {/* <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        disabled
                                        value={form[11].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 11) }} /> */}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[11].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 11) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" style={{ background: 'lightgrey' }}>
                                    {/* <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        disabled
                                        value={form[11].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 11) }} /> */}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[11].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 11) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" style={{ background: 'lightgrey' }} >
                                    {/* <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        disabled
                                        value={form[11].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 11) }} /> */} </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[11].EWR_CMUD5 + form[11].EWR_CMUP5 + form[11].EWR_CFUD5 + form[11].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[11].EWR_DMUD5 + form[11].EWR_DMUP5 + form[11].EWR_DFUD5 + form[11].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >13.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[12].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[12].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 12) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 12) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 12) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 12) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 12) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 12) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 12) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 12) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_CMUD5 + form[12].EWR_CMUP5 + form[12].EWR_CFUD5 + form[12].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[12].EWR_DMUD5 + form[12].EWR_DMUP5 + form[12].EWR_DFUD5 + form[12].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >14.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[13].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[13].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 13) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 13) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 13) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 13) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 13) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 13) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 13) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 13) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_CMUD5 + form[13].EWR_CMUP5 + form[13].EWR_CFUD5 + form[13].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[13].EWR_DMUD5 + form[13].EWR_DMUP5 + form[13].EWR_DFUD5 + form[13].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >15.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[14].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[14].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" style={{ background: 'lightgrey' }} >
                                    {/* <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        disabled
                                        value={form[14].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 14) }} /> */} </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" style={{ background: 'lightgrey' }}>
                                    {/* <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        disabled
                                        value={form[14].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 14) }} /> */} </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" style={{ background: 'lightgrey' }}>
                                    {/* <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        disabled
                                        value={form[14].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 14) }} /> */}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" style={{ background: 'lightgrey' }}>
                                    {/* <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        disabled
                                        value={form[14].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 14) }} /> */}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[14].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 14) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[14].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 14) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[14].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 14) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[14].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 14) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[14].EWR_CMUD5 + form[14].EWR_CMUP5 + form[14].EWR_CFUD5 + form[14].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[14].EWR_DMUD5 + form[14].EWR_DMUP5 + form[14].EWR_DFUD5 + form[14].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableRightBorder} align="center"  >16.</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[15].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center"  >{disease[15].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 15) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 15) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 15) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 15) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 15) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 15) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 15) }} /></TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 15) }} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_CMUD5 + form[15].EWR_CMUP5 + form[15].EWR_CFUD5 + form[15].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[15].EWR_DMUD5 + form[15].EWR_DMUP5 + form[15].EWR_DFUD5 + form[15].EWR_DFUP5} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableLastRightBorder} align="center"  >17.</TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center"  >{disease[16].DIS_NAME}</TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center"  >{disease[16].DIS_CODELBL}</TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_CMUD5}
                                        onChange={(e) => { CMUD5Handle(e, 16) }} /> </TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_CMUP5}
                                        onChange={(e) => { CMUP5Handle(e, 16) }} /> </TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_CFUD5}
                                        onChange={(e) => { CFUD5Handle(e, 16) }} /></TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_CFUP5}
                                        onChange={(e) => { CFUP5Handle(e, 16) }} /></TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_DMUD5}
                                        onChange={(e) => { DMUD5Handle(e, 16) }} /></TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_DMUP5}
                                        onChange={(e) => { DMUP5Handle(e, 16) }} /></TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_DFUD5}
                                        onChange={(e) => { DFUD5Handle(e, 16) }} /></TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_DFUP5}
                                        onChange={(e) => { DFUP5Handle(e, 16) }} /> </TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_CMUD5 + form[16].EWR_CMUP5 + form[16].EWR_CFUD5 + form[16].EWR_CFUP5} /> </TableCell>
                                <TableCell className={classes.tableLastRightBorder} align="center" >
                                    <CustomUnicefTextField type='number' disabled inputProps={{ min: 0, style: { textAlign: 'center' } }} style={{ width: '100%' }}
                                        value={form[16].EWR_DMUD5 + form[16].EWR_DMUP5 + form[16].EWR_DFUD5 + form[16].EWR_DFUP5} /></TableCell>
                            </TableRow>
                        </TableBody>}

                </Table>
            </TableContainer>
            <Grid container justifyContent="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Grid item xs={3} sm={2} md={1}>
                    <Button variant="contained" style={{ color: '#ffffff', background: '#482642', width: '100%' }} onClick={save}>Save</Button>
                </Grid>
                <Grid item xs={3} sm={2} md={1} style={{marginLeft:'2%'}}>
                    <Button variant="contained" style={{ background: 'lightgray', color: '#482642', width: '100%' }} onClick={cancle}>Cancel</Button>
                </Grid>
            </Grid>
        </>
    )
}
