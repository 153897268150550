import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Card } from "@mui/material";
import { Button, Checkbox, Input, ListItemText, OutlinedInput, Snackbar, SnackbarContent, Switch, TextField, Typography } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import CustomTextField from "../../components/controls/CustomTextFieldFilled";
import CustomUnicefTextField from '../../components/controls/CustomUnicefTextField'
import CustomSnackBar from "../../components/controls/CustomSnackBar";

import _ from 'lodash';

//////////////API/////////////////
import { insertDELI, updateDELI } from "../../modals/deliinfo";
import { insertLab } from "../../modals/labinfo";
import { getMaxID } from "../../modals/maxid";
import * as serviceLab from '../../modals/service_labdatabyid'
import * as serviceData from '../../modals/rhservicedatabyid'
import * as labData from '../../modals/rhlabdatabyid'
import * as clinic from "../../modals/clinicbyorgproj"
import * as village from "../../modals/villagebyorgproj"
import { villageTest } from "../../modals/villagetest";

import CustomRHTable from '../../components/controls/CustomRHTable';

import Modals from "../../components/modal";
import { color } from "highcharts";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            flexGrow: 1
        }
    },
    "& .MuiFilledInput-root": {
        backgroundColor: "#fcf0f2"
    },
    fontSize: {
        "& span:last-child": {
            fontSize: 13
        }
    },
    cardStyle: {

        marginTop: theme.spacing(0.9),
        marginBottom: theme.spacing(1),


    },
    cardStyleTwo: {
        width: "100%",
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(0.9),
        borderRadius: "3px"
    },
    formControl: {
        margin: theme.spacing(1),
    },
    secondFormControl: {
        margin: theme.spacing(1),
        width: '40%'
    }
    ,

    thirdFormControl: {
        margin: theme.spacing(1),
        width: '100%'
    },

    table: {
        width: '100%',
        background: '#fcf0f2',
        height: '10px',


    },
    cellOne: {
        borderBottom: 'none',
        color: '#808080',
        fontSize: '9pt',

    },
    cellTwo: {
        borderBottom: 'none',
        color: '#53344d',
        fontSize: '12pt',
        fontWeight: 'bold',


    },
    selected: {
        backgroundColor: "#DED4DA !important",
        color: '#482642'
    }
}));

const radioTheme = createTheme({
    palette: {
        primary: {
            main: "#482642"
        },
        secondary: {
            main: "#482642"
        }
    }
});

export default function DeliveryServiceEditForm(props) {

    const classes = useStyles();

    const history = useHistory();

    ///////Background Data///////////
    const [loading, setLoading] = useState(false);
    const [patientData, setPatientData] = useState([])
    const [serviceTypeData, setServiceTypeData] = useState('')
    const [patientOC, setPatientOC] = useState([])
    const [clinicData, setClinicData] = useState([])
    const [villageData, setVillageData] = useState([])
    let villageName = ''
    const [selectedVillage, setSelectedVillage] = useState("")
    let clinicName = ''
    const [selectedClinic, setSelectedClinic] = useState("")

    const [deliForm, setdeliForm] = useState({
        DELIREGID: '',
        DELIPROVIDEDDATE: '',
        DELIDONOR: '',
        DELIORG: '',
        DELIPROJECT: '',
        DELITSP: '',
        DELIVILLAGE: '',
        DELIPROVIDERNAME: '',
        DELIPROVIDERPOSITION: '',
        DELIUSRLOGIN: '',
        DELIMCOMPLICATION: '',
        DELIMPROCEDURE: '',
        DELIMTX: '',
        DELIMREFREASON: '',
        DELIMDEATHREASON: '',
        DELIBREFREASON: '',
        DELIBDEATHREASON: '',
        DELIAGE: '',
        DELITYPE: '',
        DELIPLACE: '',
        DELIDELITYPE: '',
        DELIG: '',
        DELIP: '',
        DELIA: '',
        DELILAB: '',
        DELIMOUTCOME: '',
        DELIMREFTO: '',
        DELIBOUTCOME: '',
        DELIBDELIOUTCOME: '',
        DELIBSEX1: '',
        DELRESTOWEL: '',
        DELIRESMASK: '',
        DELIRESSUCTION: '',
        DELIRESCOMPRESSION: '',
        DELIRESSTIMULATION: '',
        DELIBBF1: '',
        DELIBREFTO: '',
        DELIBWT1: '',
        DELIANSELFREP: '',
        DELIBSEX2: '',
        DELIBWT2: '',
        DELIBBF2: '',
        DELIBBF3: '',
        DELIAGEUNIT: '',
        DELICLNID: '',
        DELIBSEX3: '',
        DELIBWT3: '',
        DELIUPDATE: '',
        DELISTATUS: '',
        DELISYNC: '',
        ID: '',
        DELIREMARK: '',
        DELIMIGRANT: '999',
        DELIIDP: '999',
        DELIDSEE: '999',
        DELIDHEAR: '999',
        DELIDWALK: '999',
        DELIDREMBR: '999',
        DELIDWASH: '999',
        DELIDCOMMU: '999',
        DELIDISABILITY: '999',
    });

    const [deliLabForm, setdeliLabForm] = useState({
        LABREGID: '',
        LABPROVIDEDDATE: '',
        LABPLACE: '',
        LABVILLAGE: '',
        LABRDT: '999',
        LABMICROSCOPIC: '999',
        LABHB: '',
        LABBG: '999',
        LABRH: '999',
        LABUCG: '999',
        LABUSUGAR: '999',
        LABUPROTEIN: '999',
        LABGONO: '999',
        LABTRICHO: '999',
        LABCANDIDA: '999',
        LABRPR: '999',
        LABTPHA: '999',
        LABVDRL: '999',
        LABHIV: '999',
        LABHBV: '999',
        LABHCV: '999',
        LABSSOURCE: '',
        LABOTHER: '',
        LABRBS: '',
        LABORG: '',
        LABUPDATE: '',
        LABSTATUS: '',
        LABSYNC: 0,
        ID: '',
        LABTEST: '',
    });

    ///////Age Calculate/////////////
    const [ageCalculate, setAgeCalculate] = useState('')
    const [realAge, setRealAge] = useState('')
    const [age, setAge] = useState('')
    const [ageUnit, setAgeUnit] = useState('')
    const [ageUnitValue, setAgeUnitValue] = useState('')
    const [providedDate, setProvidedDate] = useState('')

    const calculateAge = async (event) => {

        setProvidedDate(event.target.value)
        console.log(event.target.value)
        console.log(new Date(event.target.value))
        let date = await new Date(new Date(event.target.value) - new Date().getTimezoneOffset() * 60000);
        console.log("provided date => ", date)
        let a = await Number(patientData[0].REGAGE) * Number(patientData[0].REGAGEUNIT);
        let b = await new Date(date);
        let c = await new Date(patientData[0].REGDATE);
        console.log("register date=>", c)
        let g = await b.getTime() - c.getTime();
        let e = await g / (1000 * 3600 * 24);
        //let f = (a + e) / 365;
        let totalAge = await (a + e);
        if (b >= c) {

            if (totalAge >= 365) {
                //year
                let ageCount = await Number(totalAge);
                let h = await Number(ageCount / 365);

                if (h < 10) {
                    setAgeError('Patient must be older than 10 years to get this service')
                    setAgeSnack(true)
                    setAgeValid(false)
                    sessionStorage.setItem('rhage', h.toString().split('.')[0])
                    sessionStorage.setItem('rhageunit', '365')
                    sessionStorage.setItem('rhageunitvalue', 'Year')
                }

                else {
                    setAgeValid(true)
                    await setAge(h.toString().split('.')[0])
                    await setAgeUnit('365')
                    await setAgeUnitValue('Year')

                    //setRHForm({ ...RHForm, RHPROVIDEDDATE: event.target.value })
                    setdeliForm({ ...deliForm, DELIPROVIDEDDATE: moment(event.target.value).format('YYYY-MM-DD'), DELIAGE: h.toString().split('.')[0], DELIAGEUNIT: '365' })
                    setdeliLabForm({ ...deliLabForm, LABPROVIDEDDATE: moment(event.target.value).format('YYYY-MM-DD'), })
                    sessionStorage.setItem('rhage', h.toString().split('.')[0])
                    sessionStorage.setItem('rhageunit', '365')
                    sessionStorage.setItem('rhageunitvalue', 'Year')
                }


            }
            else if (totalAge >= 30 && totalAge < 365) {
                setAgeValid(true)
                //month
                let ageCount = await Number(totalAge);
                let h = await Number(ageCount / 30);

                await setAge(h.toString().split('.')[0])
                await setAgeUnit('30')
                await setAgeUnitValue('Month')

                setdeliForm({ ...deliForm, DELIPROVIDEDDATE: moment(event.target.value).format('YYYY-MM-DD'), DELIAGE: h.toString().split('.')[0], DELIAGEUNIT: '30' })
                setdeliLabForm({ ...deliLabForm, LABPROVIDEDDATE: moment(event.target.value).format('YYYY-MM-DD'), })
                sessionStorage.setItem('rhage', h.toString().split('.')[0])
                sessionStorage.setItem('rhageunit', '30')
                sessionStorage.setItem('rhageunitvalue', 'Month')
            }
            else {
                //day
                setAgeValid(true)
                await setAge(totalAge)
                await setAgeUnit('1')
                await setAgeUnitValue('Day')

                setdeliForm({ ...deliForm, DELIPROVIDEDDATE: moment(event.target.value).format('YYYY-MM-DD'), DELIAGE: totalAge, DELIAGEUNIT: '1' })
                setdeliLabForm({ ...deliLabForm, LABPROVIDEDDATE: moment(event.target.value).format('YYYY-MM-DD'), })
                sessionStorage.setItem('rhage', totalAge)
                sessionStorage.setItem('rhageunit', '1')
                sessionStorage.setItem('rhageunitvalue', 'Day')

            }
        }
        else if (b < c) {

            setAgeError('Provided Date is cannot be Less than Registration Date!')
            setAgeSnack(true)
            setAgeValid(false)
        }

        else {
            setAgeValid(true)
        }

        console.log("Total Age => ", a + e)
        //console.log("After Calculation => ",f)


    }

    ///////////Radio Handle/////////////

    function deliB1SexHandleChange(event) {
        if (event.target.value === deliForm.DELIBSEX1) {
            setdeliForm({ ...deliForm, DELIBSEX1: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIBSEX1: event.target.value })
        }
    }

    function deliB2SexHandleChange(event) {
        if (event.target.value === deliForm.DELIBSEX2) {
            setdeliForm({ ...deliForm, DELIBSEX2: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIBSEX2: event.target.value })
        }
    }

    function deliB3SexHandleChange(event) {
        if (event.target.value === deliForm.DELIBSEX3) {
            setdeliForm({ ...deliForm, DELIBSEX3: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIBSEX3: event.target.value })
        }
    }

    function deliBBF1HandleChange(event) {
        if (event.target.value === deliForm.DELIBBF1) {
            setdeliForm({ ...deliForm, DELIBBF1: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIBBF1: event.target.value })
        }
    }

    function deliBBF2HandleChange(event) {
        if (event.target.value === deliForm.DELIBBF2) {
            setdeliForm({ ...deliForm, DELIBBF2: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIBBF2: event.target.value })
        }
    }

    function deliBBF3HandleChange(event) {
        if (event.target.value === deliForm.DELIBBF3) {
            setdeliForm({ ...deliForm, DELIBBF3: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIBBF3: event.target.value })
        }
    }

    ///////LabTest///////////
    const [labTest, setLabTest] = useState(false)
    const labTestHandle = (event) => {
        setLabTest(event.target.checked);
        setdeliForm({ ...deliForm, DELILAB: event.target.checked === true ? 1 : 0 })
        setdeliLabForm({ ...deliLabForm, LABTEST: event.target.checked === true ? 1 : 0 })
    };

    const [baby2, setBaby2] = useState(false)
    const baby2Handle = (event) => {
        setBaby2(event.target.checked);
    };

    const [baby3, setBaby3] = useState(false)
    const baby3Handle = (event) => {
        setBaby3(event.target.checked);
    };

    ///////Investigation///////////
    const [RDT, setRDT] = useState('999');
    const RDTHandle = (event) => {
        setRDT(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABRDT: event.target.value })
    };
    const [microscopic, setMicroscopic] = useState('999');
    const microscopicHandle = (event) => {
        setMicroscopic(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABMICROSCOPIC: event.target.value })
    };
    const [blood, setBlood] = useState('999')
    const bloodHandle = (event) => {
        setBlood(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABBG: event.target.value })
    };
    const [RH, setRH] = useState('999')
    const RHHandle = (event) => {
        setRH(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABRH: event.target.value })
    };
    const [urineProtein, setUrineProtein] = useState('999');
    const urintProteinHandle = (event) => {
        setUrineProtein(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABUPROTEIN: event.target.value })
    };
    const [UCG, setUCG] = useState('999')
    const UCGHandle = (event) => {
        setUCG(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABUCG: event.target.value })
    };

    const [urine, setUrine] = useState('999')
    const urineHandle = (event) => {
        setUrine(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABUSUGAR: event.target.value })
    };

    const [gonorrhoea, setGonorrhoea] = useState('999')
    const gonorrhoeaHandle = (event) => {
        setGonorrhoea(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABGONO: event.target.value })
    };
    const [trichomonus, setTrichomonus] = useState('999')
    const trichomonusHandle = (event) => {
        setTrichomonus(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABTRICHO: event.target.value })
    };
    const [candida, setCandida] = useState('999')
    const candidaHandle = (event) => {
        setCandida(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABCANDIDA: event.target.value })
    };
    const [RPR, setRPR] = useState('999')
    const RPRHandle = (event) => {
        setRPR(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABRPR: event.target.value })
    };
    const [TPHA, setTPHA] = useState('999')
    const TPHAHandle = (event) => {
        setTPHA(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABTPHA: event.target.value })
    };
    const [VDRL, setVDRL] = useState('999')
    const VDRLHandle = (event) => {
        setVDRL(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABVDRL: event.target.value })
    };
    const [HIV, setHIV] = useState('999')
    const HIVHandle = (event) => {
        setHIV(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABHIV: event.target.value })
    };
    const [HBV, setHBV] = useState('999')
    const HBVHandle = (event) => {
        setHBV(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABHBV: event.target.value })
    };
    const [HepC, setHepC] = useState('999')
    const HepCHandle = (event) => {
        setHepC(event.target.value);
        setdeliLabForm({ ...deliLabForm, LABHCV: event.target.value })
    };

    //////////////Baby outcome and procedure/////////////////
    const [resuscitation, setResuscitation] = useState([])

    const resuscitationHandle = (event) => {
        setResuscitation(event.target.value);
        console.log(resuscitation)
    };

    const [proPlace, setProPlace] = useState('')
    const proPlaceHandle = (event) => {
        setProPlace(event.target.value);
        setdeliForm({ ...deliForm, DELIPLACE: event.target.value })
        setdeliLabForm({ ...deliLabForm, LABPLACE: event.target.value })
    };
    const [deliDeliType, setDeliDeliType] = useState('999')
    const deliDeliTypeHandle = (event) => {
        setDeliDeliType(event.target.value);
        setdeliForm({ ...deliForm, DELIDELITYPE: event.target.value })
    };
    const [deliBDeliOutcome, setDeliBDeliOutcome] = useState('999')
    const deliBDeliOutcomeHandle = (event) => {
        setDeliBDeliOutcome(event.target.value);
        setdeliForm({ ...deliForm, DELIBDELIOUTCOME: event.target.value })
    };
    const [babyOutcome, setBabyOutcome] = useState('999')
    const babyOutcomeHandle = (event) => {
        setBabyOutcome(event.target.value);
        setdeliForm({ ...deliForm, DELIBOUTCOME: event.target.value })
    };
    const [deliBRefto, setDeliBRefto] = useState('999')
    const deliBReftoHandle = (event) => {
        setDeliBRefto(event.target.value);
        setdeliForm({ ...deliForm, DELIBREFTO: event.target.value })
    };

    /////Maternal Outcome//////////
    const [proPosition, setProPosition] = useState('')
    const proPositionHandle = (event) => {
        setProPosition(event.target.value);
        setdeliForm({ ...deliForm, DELIPROVIDERPOSITION: event.target.value })
    };

    const [patientMOutcome, setPatientMOutcome] = useState('999')
    const patientMOutcomeHandle = (event) => {
        setPatientMOutcome(event.target.value);
        setdeliForm({ ...deliForm, DELIMOUTCOME: event.target.value })
    };

    const [referMPlace, setReferMPlace] = useState('999')
    const referMPlaceHandle = (event) => {
        setReferMPlace(event.target.value);
        setdeliForm({ ...deliForm, DELIMREFTO: event.target.value })
    };

    /////////////////////For Shan IPs Only//////////////////

    const [seeDis, setSeeDis] = useState('999')
    const [hearDis, setHearDis] = useState('999')
    const [walkDis, setWalkDis] = useState('999')
    const [remDis, setRemDis] = useState('999')
    const [washDis, setWashDis] = useState('999')
    const [comDis, setComDis] = useState('999')
    const seeDisHandle = (event) => {
        setSeeDis(event.target.value);
        setdeliForm({ ...deliForm, DELIDSEE: event.target.value })
    };
    const hearDisHandle = (event) => {
        setHearDis(event.target.value);
        setdeliForm({ ...deliForm, DELIDHEAR: event.target.value })
    };
    const walkDisHandle = (event) => {
        setWalkDis(event.target.value);
        setdeliForm({ ...deliForm, DELIDWALK: event.target.value })
    };
    const remDisHandle = (event) => {
        setRemDis(event.target.value);
        setdeliForm({ ...deliForm, DELIDREMBR: event.target.value })
    };
    const washDisHandle = (event) => {
        setWashDis(event.target.value);
        setdeliForm({ ...deliForm, DELIDWASH: event.target.value })
    };
    const comDisHandle = (event) => {
        setComDis(event.target.value);
        setdeliForm({ ...deliForm, DELIDCOMMU: event.target.value })
    };

    function migrantHandleChange(event) {
        if (event.target.value === deliForm.DELIMIGRANT) {
            setdeliForm({ ...deliForm, DELIMIGRANT: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIMIGRANT: event.target.value })
        }
    }

    function IDPHandleChange(event) {
        if (event.target.value === deliForm.DELIIDP) {
            setdeliForm({ ...deliForm, DELIIDP: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIIDP: event.target.value })
        }
    }

    function disablilityHandleChange(event) {
        if (event.target.value === deliForm.DELIDISABILITY) {
            setdeliForm({ ...deliForm, DELIDISABILITY: 999 })
        } else {
            setdeliForm({ ...deliForm, DELIDISABILITY: event.target.value })
        }
    }


    ///////////Handle Change///////////
    const [tspCode, setTspCode] = useState('')
    const [clnCode, setClnCode] = useState('')
    const [villageCode, setVillageCode] = useState('')
    const [chosenVillageData, setChosenVillageData] = useState([])
    const [villageList, setVillageList] = useState([])

    const deliVillageHandleChange = async (event, obj) => {
        let tsp = _.find(villageData, ['VILLAGE_CODE', obj?.VILLAGE_CODE]);
        setTspCode(tsp.TSP_CODE)
        setVillageCode(obj?.VILLAGE_CODE)
        const vData = await village.getVillageByOrgProj()
        if (vData.data.data.getVillageByOrgProj.length) {
            villageName = _.find(vData.data.data.getVillageByOrgProj, ['VILLAGE_CODE', obj?.VILLAGE_CODE]).VILLAGE_NAME
            setSelectedVillage(villageName)
        }
        setdeliLabForm({ ...deliLabForm, LABVILLAGE: obj?.VILLAGE_CODE })
        if ((sessionStorage.getItem('project') === 'P-990' || sessionStorage.getItem('project') === 'P-989') && (sessionStorage.getItem('org') === 'CPI-05' || sessionStorage.getItem('org') === 'CPI-16')) {
            setdeliForm({ ...deliForm, DELIVILLAGE: obj?.VILLAGE_CODE })
        }
        else {
            setdeliForm({ ...deliForm, DELIVILLAGE: obj?.VILLAGE_CODE, DELITSP: tsp.TSP_CODE })
        }

        console.log("Selected Village => ", obj?.VILLAGE_CODE)
    };
    const deliClinicHandleChange = async (event, obj) => {
        setClnCode(obj?.CLN_CODE)
        setdeliForm({ ...deliForm, DELICLNID: obj?.CLN_CODE })
        let cData = await clinic.getClinicByOrgProj()
        if (cData.data.data.getClinicByOrgProj.length) {
            clinicName = _.find(cData.data.data.getClinicByOrgProj, ['CLN_CODE', obj?.CLN_CODE]).CLN_NAME
            setSelectedClinic(clinicName)
        }
        if (sessionStorage.getItem('org') === 'CPI-13' || sessionStorage.getItem('org') === 'CPI-15' || sessionStorage.getItem('org') === 'CPI-01' || sessionStorage.getItem('org') === 'CPI-11' || sessionStorage.getItem('org') === 'CPI-14' || sessionStorage.getItem('org') === 'CPI-21' || sessionStorage.getItem('org') === 'CPI-17' || sessionStorage.getItem('org') === 'CPI-19' || sessionStorage.getItem('org') === 'CPI-63' || sessionStorage.getItem('org') === 'CPI-86' || sessionStorage.getItem('org') === 'CPI-87' || sessionStorage.getItem('org') === 'CPI-88') {
            setChosenVillageData(_.filter(villageData, ['CLN_CODE', obj?.CLN_CODE]))
        }
        console.log("Selected Clinic => ", obj?.CLN_CODE)
    };

    const [error, setError] = useState("")
    const [ageError, setAgeError] = useState("")
    const [success, setSuccess] = useState("")
    const [ageValid, setAgeValid] = useState(false)
    const [successSnack, setSuccessSnack] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [ageSnack, setAgeSnack] = useState(false)

    const setSnackBarOpen = () => {
        setOpenSnack(true)
    }

    const setSnackBarClose = () => {
        setOpenSnack(false)
    }

    const setSuccessSnackBarOpen = () => {
        setSuccessSnack(true)
    }

    const setSuccessSnackBarClose = () => {
        setSuccessSnack(false)
    }

    const setAgeSnackBarOpen = () => {
        setAgeSnack(true)
    }

    const setAgeSnackBarClose = () => {
        setAgeSnack(false)
    }

    ///////////////Update Cancle btn/////////////
    const update = async () => {
        let valid = ''
        if (sessionStorage.getItem('org') === 'CPI-05' || sessionStorage.getItem('org') === 'CPI-06' || sessionStorage.getItem('org') === 'CPI-07') {
            valid = !providedDate ? "Please Choose Provided Date" :
                !deliForm.DELICLNID ? "Please Choose Clinic" :
                    !deliForm.DELIVILLAGE ? "Please Choose Village" :
                        (!proPosition || proPosition === 999) ? "Please Choose Provider Position" :
                            (!proPlace || proPlace === 999) ? "Please Choose Provided Place" :
                                deliForm.DELIG === null ? "Please enter Gravida" :
                                    deliForm.DELIP === null ? "Please enter Parity" :
                                        deliForm.DELIA === null ? "Please enter Abortion" :
                                            (!patientMOutcome || patientMOutcome === 999 || patientMOutcome === '999') ? "Please choose Maternal Outcome" :
                                                !deliForm.DELIBSEX1 ? "Please choose Baby Sex-1" :
                                                    !deliForm.DELIBWT1 ? "Please choose Birth Weight-1" :
                                                        'valid';
        }
        else {
            valid = !providedDate ? "Please Choose Provided Date" :
                !deliForm.DELICLNID ? "Please Choose Clinic" :
                    !deliForm.DELIVILLAGE ? "Please Choose Village" :
                        (!proPosition || proPosition === 999) ? "Please Choose Provider Position" :
                            (!proPlace || proPlace === 999) ? "Please Choose Provided Place" :
                                deliForm.DELIG === null ? "Please enter Gravida" :
                                    'valid';
        }


        if (valid === 'valid') {

            var rArr = []
            var rArr1 = ['Drying with towel', resuscitation.includes('Drying with towel') ? 1 : 999]
            var rArr2 = ['Suction', resuscitation.includes('Suction') ? 1 : 999]
            var rArr3 = ['Stimulation', resuscitation.includes('Stimulation') ? 1 : 999]
            var rArr4 = ['Bag and mask', resuscitation.includes('Bag and mask') ? 1 : 999]
            var rArr5 = ['Chest compression', resuscitation.includes('Chest compression') ? 1 : 999]
            rArr.push(rArr1)
            rArr.push(rArr2)
            rArr.push(rArr3)
            rArr.push(rArr4)
            rArr.push(rArr5)
            deliForm.DELRESTOWEL = rArr[0][1]
            deliForm.DELIRESMASK = rArr[1][1]
            deliForm.DELIRESSUCTION = rArr[2][1]
            deliForm.DELIRESCOMPRESSION = rArr[3][1]
            deliForm.DELIRESSTIMULATION = rArr[4][1]

            var selfRep = deliForm.DELIANSELFREP === null ? 999 : deliForm.DELIANSELFREP
            deliForm.DELIANSELFREP = selfRep
            var parity = deliForm.DELIP === null ? 999 : deliForm.DELIP
            deliForm.DELIP = parity
            var abortion = deliForm.DELIA === null ? 999 : deliForm.DELIA
            deliForm.DELIA = abortion
            var deliDeliType = deliForm.DELIDELITYPE === null ? 999 : deliForm.DELIDELITYPE
            deliForm.DELIDELITYPE = deliDeliType
            var deliBDeliOutcome = deliForm.DELIBDELIOUTCOME === null ? 999 : deliForm.DELIBDELIOUTCOME
            deliForm.DELIBDELIOUTCOME = deliBDeliOutcome
            var deliBOutcome = deliForm.DELIBOUTCOME === null ? 999 : deliForm.DELIBOUTCOME
            deliForm.DELIBOUTCOME = deliBOutcome
            var deliBRefto = deliForm.DELIBREFTO === null ? 999 : deliForm.DELIBREFTO
            deliForm.DELIBREFTO = deliBRefto
            var bb1wt = (deliForm.DELIBWT1 !== null || deliForm.DELIBWT1) ? deliForm.DELIBWT1 : (deliForm.DELIBWT1 && (deliForm.DELIBWT1).length > 5) ? (deliForm.DELIBWT1).slice(0, 5) : 999.9
            deliForm.DELIBWT1 = bb1wt
            var bb2wt = (deliForm.DELIBWT2 === null || deliForm.DELIBWT2 === 999.9) ? 999.9 : (deliForm.DELIBWT2).length > 5 ? (deliForm.DELIBWT2).slice(0, 5) : deliForm.DELIBWT2
            deliForm.DELIBWT2 = bb2wt
            var bb3wt = (deliForm.DELIBWT3 === null || deliForm.DELIBWT3 === 999.9) ? 999.9 : (deliForm.DELIBWT3).length > 5 ? (deliForm.DELIBWT3).slice(0, 5) : deliForm.DELIBWT3
            deliForm.DELIBWT3 = bb3wt
            var bb1s = deliForm.DELIBSEX1 === '1' ? 1 : deliForm.DELIBSEX1 === '2' ? 2 : 999
            deliForm.DELIBSEX1 = bb1s
            var bb2s = deliForm.DELIBSEX2 === '1' ? 1 : deliForm.DELIBSEX2 === '2' ? 2 : 999
            deliForm.DELIBSEX2 = bb2s
            var bb3s = deliForm.DELIBSEX3 === '1' ? 1 : deliForm.DELIBSEX3 === '2' ? 2 : 999
            deliForm.DELIBSEX3 = bb3s
            var bf1 = deliForm.DELIBBF1 === '1' ? 1 : deliForm.DELIBBF1 === '2' ? 2 : 999
            deliForm.DELIBBF1 = bf1
            var bf2 = deliForm.DELIBBF2 === '1' ? 1 : deliForm.DELIBBF2 === '2' ? 2 : 999
            deliForm.DELIBBF2 = bf2
            var bf3 = deliForm.DELIBBF3 === '1' ? 1 : deliForm.DELIBBF3 === '2' ? 2 : 999
            deliForm.DELIBBF3 = bf3
            var deliMOutcome = deliForm.DELIMOUTCOME === null ? 999 : deliForm.DELIMOUTCOME
            deliForm.DELIMOUTCOME = deliMOutcome
            var deliMRefto = deliForm.DELIMREFTO === null ? 999 : deliForm.DELIMREFTO
            deliForm.DELIMREFTO = deliMRefto
            deliForm.DELIUPDATE = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')

            var migrant = (deliForm.DELIMIGRANT === null || deliForm.DELIMIGRANT === '') ? 999 : deliForm.DELIMIGRANT
            deliForm.DELIMIGRANT = migrant
            var idp = (deliForm.DELIIDP === null || deliForm.DELIIDP === '') ? 999 : deliForm.DELIIDP
            deliForm.DELIIDP = idp
            var dis = deliForm.DELIDISABILITY === null ? 999 : deliForm.DELIDISABILITY
            deliForm.DELIDISABILITY = dis

            var labHB = deliLabForm.LABHB === null ? 999 : deliLabForm.LABHB
            deliLabForm.LABHB = labHB
            var labRBS = deliLabForm.LABRBS === null ? 999 : deliLabForm.LABRBS
            deliLabForm.LABRBS = labRBS

            var lab = labTest === false ? 0 : 1;
            deliForm.DELILAB = lab
            deliLabForm.LABTEST = lab

            //deliLabForm.LABINSERT = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            deliLabForm.LABUPDATE = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')


            const res = await updateDELI({ deliForm, deliLabForm });
            if (res?.status === 200) {
                sessionStorage.setItem('homeSave', 'done')
                setSuccess("Successfully updated a patient's Delivery Service")
                setSuccessSnack(true)
                history.push({
                    pathname: "entryhomepage",
                    openDeliUpdateSnackbar: true
                });
            }
            console.log('deliFORM=>', deliForm)
        }
        else {
            setError(valid)
            setOpenSnack(true)
        }
    }
    const cancle = () => {
        history.push('entryhomepage')
        sessionStorage.setItem('homeSave', 'done')
    }

    //////Patient Data///////////

    useEffect(async () => {

        setAgeValid(true)

        if (sessionStorage.getItem('editDeliPatient') === 'true') {

            setAgeValid(true)
            setLoading(true)

            let service = await serviceData.getServiceData()
            let lab = await labData.getLabData()
            let cData = await clinic.getClinicByOrgProj()
            let vData = await village.getVillageByOrgProj()
            let pData = await serviceLab.getPatient()

            if (cData && vData) {
                setClinicData(cData.data.data.getClinicByOrgProj)
                setVillageData(vData.data.data.getVillageByOrgProj)
            }

            if (pData) {
                setPatientData(pData.data.data.getPatient)
            }

            if (service && lab && cData && vData) {
                let serviceData = service.data.data.getServiceData;

                let villageArr = []
                villageArr = vData.data.data.getVillageByOrgProj
                if (_.find(vData.data.data.getVillageByOrgProj, ['VILLAGE_CODE', serviceData[0].DELIVILLAGE])) {
                    villageName = _.find(vData.data.data.getVillageByOrgProj, ['VILLAGE_CODE', serviceData[0].DELIVILLAGE]).VILLAGE_NAME
                    if (sessionStorage.getItem('org') === 'CPI-13' || sessionStorage.getItem('org') === 'CPI-15' || sessionStorage.getItem('org') === 'CPI-01' || sessionStorage.getItem('org') === 'CPI-11' || sessionStorage.getItem('org') === 'CPI-14' || sessionStorage.getItem('org') === 'CPI-21' || sessionStorage.getItem('org') === 'CPI-17' || sessionStorage.getItem('org') === 'CPI-19' || sessionStorage.getItem('org') === 'CPI-63' || sessionStorage.getItem('org') === 'CPI-86' || sessionStorage.getItem('org') === 'CPI-87' || sessionStorage.getItem('org') === 'CPI-88') { setChosenVillageData(_.filter(vData.data.data.getVillageByOrgProj, ['CLN_CODE', serviceData[0].DELICLNID])) }
                    else { setChosenVillageData(vData.data.data.getVillageByOrgProj) }
                    setVillageData(vData.data.data.getVillageByOrgProj)
                    setSelectedVillage(villageName)
                    console.log('village from default : ', villageName)
                }
                else {
                    let villTest = await villageTest({ village: serviceData[0].DELIVILLAGE })
                    if (villTest) {
                        villageArr.push({
                            VILLAGE_CODE: villTest.data.data.villageTest[0].VILLAGE_CODE,
                            VILLAGE_NAME: villTest.data.data.villageTest[0].VILLAGE_NAME,
                            CLN_NAME: '',
                            PROJ_CODE: '',
                            ORG_CODE: '',
                            CLN_CODE: '',
                            TSP_CODE: '',
                            PROJECT_NAME: '',
                            DIV_NAME: '',
                            DIV_ID: '',
                            TSP_NAME: ''
                        }
                        )
                    }
                    setSelectedVillage(villTest.data.data.villageTest[0].VILLAGE_NAME)
                    if (sessionStorage.getItem('org') === 'CPI-13' || sessionStorage.getItem('org') === 'CPI-15' || sessionStorage.getItem('org') === 'CPI-01' || sessionStorage.getItem('org') === 'CPI-11' || sessionStorage.getItem('org') === 'CPI-14' || sessionStorage.getItem('org') === 'CPI-21' || sessionStorage.getItem('org') === 'CPI-17' || sessionStorage.getItem('org') === 'CPI-19' || sessionStorage.getItem('org') === 'CPI-63' || sessionStorage.getItem('org') === 'CPI-86' || sessionStorage.getItem('org') === 'CPI-87' || sessionStorage.getItem('org') === 'CPI-88') { setChosenVillageData(_.filter(villageArr, ['CLN_CODE', serviceData[0].DELICLNID])) }
                    else { setChosenVillageData(villageArr) }
                    setVillageData(villageArr)
                }

                clinicName = _.find(cData.data.data.getClinicByOrgProj, ['CLN_CODE', serviceData[0].DELICLNID]).CLN_NAME
                setSelectedClinic(clinicName)
                console.log('clinic from default : ', clinicName)
                deliForm.DELIREGID = serviceData[0].DELIREGID
                //deliForm.DELIPROVIDEDDATE = serviceData[0].DELIPROVIDEDDATE.format("YYYY-MM-DD")
                deliForm.DELIPROVIDEDDATE = moment(serviceData[0].DELIPROVIDEDDATE).format("YYYY-MM-DD")
                setProvidedDate(moment(serviceData[0].DELIPROVIDEDDATE).format("YYYY-MM-DD"))
                deliForm.DELIDONOR = serviceData[0].DELIDONOR
                deliForm.DELIORG = serviceData[0].DELIORG
                deliForm.DELIPROJECT = serviceData[0].DELIPROJECT
                deliForm.DELITSP = serviceData[0].DELITSP
                setTspCode(serviceData[0].DELITSP)
                deliForm.DELIVILLAGE = serviceData[0].DELIVILLAGE
                setVillageCode(serviceData[0].DELIVILLAGE)
                deliForm.DELIPROVIDERNAME = serviceData[0].DELIPROVIDERNAME
                deliForm.DELIPROVIDERPOSITION = serviceData[0].DELIPROVIDERPOSITION
                setProPosition(serviceData[0].DELIPROVIDERPOSITION)
                deliForm.DELIUSRLOGIN = serviceData[0].DELIUSRLOGIN
                deliForm.DELIMCOMPLICATION = serviceData[0].DELIMCOMPLICATION
                deliForm.DELIMPROCEDURE = serviceData[0].DELIMPROCEDURE
                deliForm.DELIMTX = serviceData[0].DELIMTX
                deliForm.DELIMREFREASON = serviceData[0].DELIMREFREASON
                deliForm.DELIMDEATHREASON = serviceData[0].DELIMDEATHREASON
                deliForm.DELIBREFREASON = serviceData[0].DELIBREFREASON
                deliForm.DELIBDEATHREASON = serviceData[0].DELIBDEATHREASON
                deliForm.DELIAGE = serviceData[0].DELIAGE
                deliForm.DELITYPE = serviceData[0].DELITYPE
                deliForm.DELIPLACE = serviceData[0].DELIPLACE
                setProPlace(serviceData[0].DELIPLACE);
                deliForm.DELIDELITYPE = serviceData[0].DELIDELITYPE
                setDeliDeliType(serviceData[0].DELIDELITYPE)
                deliForm.DELIG = serviceData[0].DELIG
                deliForm.DELIP = serviceData[0].DELIP === 999 ? '' : serviceData[0].DELIP
                deliForm.DELIA = serviceData[0].DELIA === 999 ? '' : serviceData[0].DELIA
                deliForm.DELILAB = serviceData[0].DELILAB
                deliForm.DELIMOUTCOME = serviceData[0].DELIMOUTCOME === 999 ? '' : serviceData[0].DELIMOUTCOME
                setPatientMOutcome(serviceData[0].DELIMOUTCOME === 999 ? '' : serviceData[0].DELIMOUTCOME)
                deliForm.DELIMREFTO = serviceData[0].DELIMREFTO === 999 ? '' : serviceData[0].DELIMREFTO
                setReferMPlace(serviceData[0].DELIMREFTO === 999 ? '' : serviceData[0].DELIMREFTO)
                deliForm.DELIBOUTCOME = serviceData[0].DELIBOUTCOME === 999 ? '' : serviceData[0].DELIBOUTCOME
                setBabyOutcome(serviceData[0].DELIBOUTCOME === 999 ? '' : serviceData[0].DELIBOUTCOME)
                deliForm.DELIBDELIOUTCOME = serviceData[0].DELIBDELIOUTCOME === 999 ? '' : serviceData[0].DELIBDELIOUTCOME
                setDeliBDeliOutcome(serviceData[0].DELIBDELIOUTCOME === 999 ? '' : serviceData[0].DELIBDELIOUTCOME)
                deliForm.DELIBSEX1 = serviceData[0].DELIBSEX1 === 999 ? '' : serviceData[0].DELIBSEX1 === 1 ? '1' : '2'
                deliForm.DELRESTOWEL = serviceData[0].DELRESTOWEL === 999 ? '' : serviceData[0].DELRESTOWEL
                deliForm.DELIRESMASK = serviceData[0].DELIRESMASK === 999 ? '' : serviceData[0].DELIRESMASK
                deliForm.DELIRESSUCTION = serviceData[0].DELIRESSUCTION === 999 ? '' : serviceData[0].DELIRESSUCTION
                deliForm.DELIRESCOMPRESSION = serviceData[0].DELIRESCOMPRESSION === 999 ? '' : serviceData[0].DELIRESCOMPRESSION
                deliForm.DELIRESSTIMULATION = serviceData[0].DELIRESSTIMULATION === 999 ? '' : serviceData[0].DELIRESSTIMULATION
                var rArr = []
                var rArr1 = serviceData[0].DELRESTOWEL === 1 ? rArr.push('Drying with towel') : null
                var rArr2 = serviceData[0].DELIRESSUCTION === 1 ? rArr.push('Suction') : null
                var rArr3 = serviceData[0].DELIRESSTIMULATION === 1 ? rArr.push('Stimulation') : null
                var rArr4 = serviceData[0].DELIRESMASK === 1 ? rArr.push('Bag and mask') : null
                var rArr5 = serviceData[0].DELIRESCOMPRESSION === 1 ? rArr.push('Chest compression') : null
                setResuscitation(rArr)
                deliForm.DELIBBF1 = serviceData[0].DELIBBF1 === 999 ? '' : serviceData[0].DELIBBF1 === 1 ? '1' : '2'
                deliForm.DELIBREFTO = serviceData[0].DELIBREFTO === 999 ? '' : serviceData[0].DELIBREFTO
                setDeliBRefto(serviceData[0].DELIBREFTO === 999 ? '' : serviceData[0].DELIBREFTO)
                deliForm.DELIBWT1 = serviceData[0].DELIBWT1 === 999.9 ? '' : (serviceData[0].DELIBWT1 + '').length > 5 ? (serviceData[0].DELIBWT1 + '').slice(0, 5) : serviceData[0].DELIBWT1
                deliForm.DELIANSELFREP = serviceData[0].DELIANSELFREP === 999 ? '' : serviceData[0].DELIANSELFREP
                deliForm.DELIBSEX2 = serviceData[0].DELIBSEX2 === 999 ? '' : serviceData[0].DELIBSEX2 === 1 ? '1' : '2'
                deliForm.DELIBWT2 = serviceData[0].DELIBWT2 === 999.9 ? null : (serviceData[0].DELIBWT2 + '').length > 5 ? (serviceData[0].DELIBWT2 + '').slice(0, 5) : serviceData[0].DELIBWT2
                deliForm.DELIBBF2 = serviceData[0].DELIBBF2 === 999 ? '' : serviceData[0].DELIBBF2 === 1 ? '1' : '2'
                deliForm.DELIBBF3 = serviceData[0].DELIBBF3 === 999 ? '' : serviceData[0].DELIBBF3 === 1 ? '1' : '2'
                deliForm.DELIAGEUNIT = serviceData[0].DELIAGEUNIT
                deliForm.DELICLNID = serviceData[0].DELICLNID
                setClnCode(serviceData[0].DELICLNID)
                deliForm.DELIBSEX3 = serviceData[0].DELIBSEX3 === 999 ? '' : serviceData[0].DELIBSEX3 === 1 ? '1' : '2'
                deliForm.DELIBWT3 = serviceData[0].DELIBWT3 === 999.9 ? null : (serviceData[0].DELIBWT3 + '').length > 5 ? (serviceData[0].DELIBWT3 + '').slice(0, 5) : serviceData[0].DELIBWT3
                deliForm.DELIUPDATE = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
                deliForm.DELISTATUS = '2'
                deliForm.DELISYNC = '0'
                deliForm.ID = serviceData[0].ID
                deliForm.DELIREMARK = serviceData[0].DELIREMARK
                deliForm.DELIMIGRANT = serviceData[0].DELIMIGRANT === 1 ? '1' : serviceData[0].DELIMIGRANT === 2 ? '2' : '999'
                deliForm.DELIIDP = serviceData[0].DELIIDP === 1 ? '1' : serviceData[0].DELIIDP === 2 ? '2' : '999'
                deliForm.DELIDISABILITY = serviceData[0].DELIDISABILITY === 1 ? '1' : serviceData[0].DELIDISABILITY === 2 ? '2' : '999'
                deliForm.DELIDSEE = serviceData[0].DELIDSEE + ''
                deliForm.DELIDHEAR = serviceData[0].DELIDHEAR + ''
                deliForm.DELIDWALK = serviceData[0].DELIDWALK + ''
                deliForm.DELIDREMBR = serviceData[0].DELIDREMBR + ''
                deliForm.DELIDWASH = serviceData[0].DELIDWASH + ''
                deliForm.DELIDCOMMU = serviceData[0].DELIDCOMMU + ''
                setSeeDis(serviceData[0].DELIDSEE + '')
                setHearDis(serviceData[0].DELIDHEAR + '')
                setWalkDis(serviceData[0].DELIDWALK + '')
                setRemDis(serviceData[0].DELIDREMBR + '')
                setWashDis(serviceData[0].DELIDWASH + '')
                setComDis(serviceData[0].DELIDCOMMU + '')

                setBaby2(((serviceData[0].DELIBSEX2 !== 999 || serviceData[0].DELIBBF2 !== 999) && serviceData[0].DELIBWT2 !== 999.9))
                setBaby3(((serviceData[0].DELIBSEX3 !== 999 || serviceData[0].DELIBBF3 !== 999) && serviceData[0].DELIBWT3 !== 999.9))

                let labData = lab.data.data.getLabData;
                let labRegID = labData[0].LABREGID
                let labProvidedDate = labData[0].LABPROVIDEDDATE
                let labPlace = labData[0].LABPLACE
                let labVillage = labData[0].LABVILLAGE
                setVillageCode(labVillage)
                let labRDT = labData[0].LABRDT === 999 ? '999' : labData[0].LABRDT
                setRDT(labRDT)
                let labMicroscopic = labData[0].LABMICROSCOPIC === 999 ? '999' : labData[0].LABMICROSCOPIC
                setMicroscopic(labMicroscopic);
                let labHB = labData[0].LABHB === 999 ? '' : labData[0].LABHB
                let labBG = labData[0].LABBG === 999 ? '999' : labData[0].LABBG
                setBlood(labBG);
                let labRH = labData[0].LABRH === 999 ? '999' : labData[0].LABRH
                setRH(labRH);
                let labUrineProtein = labData[0].LABUPROTEIN === 999 ? '999' : labData[0].LABUPROTEIN
                setUrineProtein(labUrineProtein)
                let labUCG = labData[0].LABUCG === 999 ? '999' : labData[0].LABUCG
                setUCG(labUCG);
                let labUSugar = labData[0].LABUSUGAR === 999 ? '999' : labData[0].LABUSUGAR
                setUrine(labUSugar);
                let labGono = labData[0].LABGONO === 999 ? '999' : labData[0].LABGONO
                setGonorrhoea(labGono);
                let labTricho = labData[0].LABTRICHO === 999 ? '999' : labData[0].LABTRICHO
                setTrichomonus(labTricho);
                let labCandida = labData[0].LABCANDIDA === 999 ? '999' : labData[0].LABCANDIDA
                setCandida(labCandida);
                let labRPR = labData[0].LABRPR === 999 ? '999' : labData[0].LABRPR
                setRPR(labRPR);
                let labTPHA = labData[0].LABTPHA === 999 ? '999' : labData[0].LABTPHA
                setTPHA(labTPHA);
                let labVDRL = labData[0].LABVDRL === 999 ? '999' : labData[0].LABVDRL
                setVDRL(labVDRL);
                let labHIV = labData[0].LABHIV === 999 ? '999' : labData[0].LABHIV
                setHIV(labHIV);
                let labHBV = labData[0].LABHBV === 999 ? '999' : labData[0].LABHBV
                setHBV(labHBV);
                let labHCV = labData[0].LABHCV === 999 ? '999' : labData[0].LABHCV
                setHepC(labHCV);
                let labSsource = labData[0].LABSSOURCE
                let labOther = labData[0].LABOTHER
                let labRBS = labData[0].LABRBS === 999 ? '' : labData[0].LABRBS
                let labOrg = labData[0].LABORG
                let labUpdate = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
                let labStatus = 2
                let labSync = labData[0].LABSYNC
                let labID = labData[0].ID
                let labTest = labData[0].LABTEST
                let labOpen = labData[0].LABTEST === 1 ? setLabTest(true) : setLabTest(false)


                setdeliLabForm({
                    ...deliLabForm,
                    LABREGID: labRegID,
                    LABPROVIDEDDATE: labProvidedDate,
                    LABPLACE: labPlace,
                    LABVILLAGE: labVillage,
                    LABRDT: labRDT,
                    LABMICROSCOPIC: labMicroscopic,
                    LABHB: labHB,
                    LABBG: labBG,
                    LABRH: labRH,
                    LABUCG: labUCG,
                    LABUSUGAR: labUSugar,
                    LABUPROTEIN: labUrineProtein,
                    LABGONO: labGono,
                    LABTRICHO: labTricho,
                    LABCANDIDA: labCandida,
                    LABRPR: labRPR,
                    LABTPHA: labTPHA,
                    LABVDRL: labVDRL,
                    LABHIV: labHIV,
                    LABHBV: labHBV,
                    LABHCV: labHCV,
                    LABSSOURCE: labSsource,
                    LABOTHER: labOther,
                    LABRBS: labRBS,
                    LABORG: labOrg,
                    LABUPDATE: labUpdate,
                    LABSTATUS: labStatus,
                    LABSYNC: labSync,
                    ID: labID,
                    LABTEST: labTest,
                });
            }
            setLoading(false)

        }

    }, [])


    return (
        <>
            <Modals open={loading} />
            <Typography variant="h5" align="center" style={{ color: '#53344d', background: '#fcf0f2', fontWeight: 'bold', padding: '1%' }}>
                Delivery Service</Typography>
            {/* <CustomRHTable patient={props.patient} serviceType={props.serviceType} /> */}

            <div style={{ background: '#fcf0f2', paddingTop: '2%' }}>
                <div className={classes.root} style={{ paddingLeft: "2%", paddingRight: "3%", paddingBottom: "2%" }}>
                    <Grid container spacing={4} justify="center">
                        <Grid item xs={12} sm={3} md={3}>
                            <CustomTextField
                                id="filled-basic"
                                disabled
                                label={<Grid row container><Typography color="#482642">Patient ID </Typography>
                                    <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                                variantText="filled"
                                InputLabelProps={{
                                    style: { color: '#482642' },
                                    shrink: true
                                }}
                                value={patientData.length ? patientData[0].REGID : ''} />

                        </Grid>
                        {/* (sessionStorage.getItem('project') !== 'P-990' && sessionStorage.getItem('project') !== 'P-989') */}

                        <Grid item xs={12} sm={3} md={3}>
                            <Autocomplete
                                value={{ CLN_NAME: selectedClinic, CLN_CODE: deliForm.DELICLNID }}
                                isOptionEqualToValue={(option, value) => option.CLN_CODE === value.CLN_CODE}
                                onChange={(e, obj) => deliClinicHandleChange(e, obj)}
                                id="controllable-states-demo"
                                InputLabelProps={{
                                    style: { color: '#482642' }
                                }}
                                options={clinicData}
                                clearOnBlur={false}
                                disablePortal
                                getOptionLabel={(option) => option.CLN_NAME}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.CLN_NAME}
                                    </React.Fragment>
                                )}
                                autoHighlight
                                renderInput={(params) => <CustomTextField
                                    style={{ width: '95%' }}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "disabled" // disable autocomplete and autofill
                                    }}
                                    {...params} label={<Grid row container><Typography color="#482642">Choose Clinic </Typography>
                                        <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>
                                    } variantText="filled" />} />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Autocomplete
                                value={{ VILLAGE_NAME: selectedVillage, VILLAGE_CODE: deliForm.DELIVILLAGE }}
                                isOptionEqualToValue={(option, value) => option.VILLAGE_CODE === value.VILLAGE_CODE}
                                onChange={(e, obj) => deliVillageHandleChange(e, obj)}
                                id="controllable-states-demo"
                                InputLabelProps={{
                                    style: { color: '#482642' }
                                }}
                                options={chosenVillageData}
                                clearOnBlur={false}
                                disablePortal
                                getOptionLabel={(option) => option.VILLAGE_NAME}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.VILLAGE_NAME} ({option.CLN_NAME},{option.PROJECT_NAME})
                                    </React.Fragment>
                                )}
                                autoHighlight
                                renderInput={(params) => <CustomTextField
                                    style={{ width: '95%' }}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "disabled" // disable autocomplete and autofill
                                    }}
                                    {...params} label={<Grid row container><Typography color="#482642">Village </Typography>
                                        <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>
                                    } variantText="filled" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <CustomTextField
                                type="number"
                                variantText="filled"
                                inputProps={{ step: "1", min: 0 }}
                                InputLabelProps={{
                                    style: { color: '#482642' },
                                    shrink: true
                                }}
                                style={{ width: '95%' }}
                                label={<Grid row container><Typography color="#482642">AN Visit(self-reported) </Typography>
                                </Grid>}
                                onChange={e => { setdeliForm({ ...deliForm, DELIANSELFREP: parseInt(e.target.value) }) }}
                                value={deliForm.DELIANSELFREP} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ThemeProvider theme={radioTheme}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '100%',
                                        borderRadius: '10px'
                                    }}
                                    className={classes.cardStyle}>
                                    {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px', fontWeight: 'bold' }}><Typography>History </Typography>
                                    </Grid>}
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                        <CustomTextField
                                            type="number"
                                            variantText="filled"
                                            inputProps={{ step: "1", min: 0, max: 99, maxLength: 2 }}
                                            InputLabelProps={{
                                                style: { color: '#482642' },
                                                shrink: true
                                            }}
                                            label={<Grid row container><Typography color="#482642">Gravida </Typography>
                                                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                                            style={{ marginTop: '10px' }}
                                            onChange={e => { (e.target.value.length > 2) ? setdeliForm({ ...deliForm, DELIG: (e.target.value).slice(0, 2) }) : setdeliForm({ ...deliForm, DELIG: e.target.value }) }}
                                            value={deliForm.DELIG} />
                                        <CustomTextField
                                            type="number"
                                            variantText="filled"
                                            inputProps={{ step: "1", min: 0, max: 99, maxLength: 2 }}
                                            InputLabelProps={{
                                                style: { color: '#482642' },
                                                shrink: true
                                            }}
                                            label={<Grid row container><Typography color="#482642">Parity </Typography>
                                                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                                            style={{ marginTop: '10px' }}
                                            onChange={e => { (e.target.value.length > 2) ? setdeliForm({ ...deliForm, DELIP: (e.target.value).slice(0, 2) }) : setdeliForm({ ...deliForm, DELIP: e.target.value }) }}
                                            value={deliForm.DELIP} />
                                        <CustomTextField
                                            type="number"
                                            variantText="filled"
                                            inputProps={{ step: "1", min: 0, max: 99, maxLength: 2 }}
                                            InputLabelProps={{
                                                style: { color: '#482642' },
                                                shrink: true
                                            }}
                                            label={<Grid row container><Typography color="#482642">Abortion </Typography>
                                                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                                            style={{ marginTop: '10px' }}
                                            onChange={e => { (e.target.value.length > 2) ? setdeliForm({ ...deliForm, DELIA: (e.target.value).slice(0, 2) }) : setdeliForm({ ...deliForm, DELIA: e.target.value }) }}
                                            value={deliForm.DELIA} />

                                    </div>


                                </Card>
                            </ThemeProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ThemeProvider theme={radioTheme}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '100%',
                                        borderRadius: '10px'
                                    }}
                                    className={classes.cardStyle}>
                                    {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px', fontWeight: 'bold' }}><Typography>Delivery Record </Typography>
                                    </Grid>}
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                        <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                            <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Provided Place </Typography>
                                                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                style={{ width: '100%' }}
                                                value={proPlace}
                                                onChange={proPlaceHandle}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                    getContentAnchorEl: null
                                                }}>
                                                <MenuItem value={999}>-</MenuItem>
                                                <MenuItem value={1}>Clinic</MenuItem>
                                                <MenuItem value={2}>Outreach</MenuItem>
                                                <MenuItem value={3}>Volunteer</MenuItem>
                                                <MenuItem value={4}>Home</MenuItem>
                                                <MenuItem value={6}>Secondary Level</MenuItem>
                                                <MenuItem value={5}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <CustomTextField
                                            id="filled-basic"
                                            type="date"
                                            label={<Grid row container><Typography color="#482642">Provided Date </Typography>
                                                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
                                            variantText="filled"
                                            InputLabelProps={{
                                                style: { color: '#482642' },
                                                shrink: true
                                            }}
                                            style={{ marginTop: '9px' }}
                                            onChange={calculateAge}
                                            value={providedDate} />
                                        {/* For Nae Thit Project, BTDuser */}
                                        {(sessionStorage.getItem('project') === 'P-007' && (sessionStorage.getItem('org') === 'CPI-01' || sessionStorage.getItem('org') === 'CPI-11'
                                            || sessionStorage.getItem('org') === 'CPI-14')) ? null :
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Type of Delivery </Typography>
                                                </Grid>}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    style={{ width: '100%' }}
                                                    value={deliDeliType}
                                                    onChange={deliDeliTypeHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>NSVD</MenuItem>
                                                    <MenuItem value={2}>Breech</MenuItem>
                                                    <MenuItem value={3}>Vacuum</MenuItem>
                                                    <MenuItem value={4}>LSCS</MenuItem>
                                                    <MenuItem value={5}>Forcep</MenuItem>
                                                </Select>
                                            </FormControl>}

                                        <FormControlLabel
                                            style={{ marginTop: '10px' }}
                                            control={
                                                <Switch
                                                    checked={labTest}
                                                    onChange={labTestHandle}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            InputLabelProps={{
                                                style: { color: '#482642' },
                                            }}
                                            label="LabTest"
                                            labelPlacement="top"
                                        />
                                    </div>
                                </Card>
                            </ThemeProvider>
                        </Grid>
                        {labTest &&
                            <ThemeProvider theme={radioTheme}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '100%',
                                        borderRadius: '10px',
                                        marginTop: '20px'
                                    }}
                                    className={classes.cardStyle}>
                                    {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px' }}><Typography>Investigation </Typography>
                                    </Grid>}
                                    <Grid container spacing={1} style={{ marginBottom: '10px' }}>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">RDT</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={RDT}
                                                    onChange={RDTHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>PF</MenuItem>
                                                    <MenuItem value={2}>PV</MenuItem>
                                                    <MenuItem value={3}>Mixed</MenuItem>
                                                    <MenuItem value={4}>Negative</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">Microscopic</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={microscopic}
                                                    onChange={microscopicHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Not Seen</MenuItem>
                                                    <MenuItem value={2}>Pf</MenuItem>
                                                    <MenuItem value={3}>Pf+</MenuItem>
                                                    <MenuItem value={4}>Pf++</MenuItem>
                                                    <MenuItem value={5}>Pf+++</MenuItem>
                                                    <MenuItem value={6}>Pv</MenuItem>
                                                    <MenuItem value={7}>Pv+</MenuItem>
                                                    <MenuItem value={8}>Pv++</MenuItem>
                                                    <MenuItem value={9}>Pv+++</MenuItem>
                                                    <MenuItem value={10}>Mixed</MenuItem>
                                                    <MenuItem value={11}>Po</MenuItem>
                                                    <MenuItem value={12}>Po+</MenuItem>
                                                    <MenuItem value={13}>Po++</MenuItem>
                                                    <MenuItem value={14}>Po+++</MenuItem>
                                                    <MenuItem value={15}>Pm</MenuItem>
                                                    <MenuItem value={16}>Pm+</MenuItem>
                                                    <MenuItem value={17}>Pm++</MenuItem>
                                                    <MenuItem value={18}>Pm+++</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <CustomUnicefTextField
                                                type="number"
                                                variantText="filled"
                                                inputProps={{ step: "1", min: 0 }}
                                                InputLabelProps={{
                                                    style: { color: '#482642' },
                                                    shrink: true
                                                }}
                                                label={<Grid row container><Typography color="#482642">HB(%) </Typography>
                                                </Grid>}
                                                style={{ marginTop: '11px', width: '95%' }}
                                                onChange={e => { setdeliLabForm({ ...deliLabForm, LABHB: e.target.value }) }}
                                                value={deliLabForm.LABHB} />
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">BloodGroup</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={blood}
                                                    onChange={bloodHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>A</MenuItem>
                                                    <MenuItem value={2}>B</MenuItem>
                                                    <MenuItem value={3}>O</MenuItem>
                                                    <MenuItem value={4}>AB</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">RH</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={RH}
                                                    onChange={RHHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Rh+</MenuItem>
                                                    <MenuItem value={2}>Rh-</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">UrineProtein</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={urineProtein}
                                                    onChange={urintProteinHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>None</MenuItem>
                                                    <MenuItem value={2}>Trace</MenuItem>
                                                    <MenuItem value={3}>+</MenuItem>
                                                    <MenuItem value={4}>++</MenuItem>
                                                    <MenuItem value={5}>+++</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">UCG/HCG</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={UCG}
                                                    onChange={UCGHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Positive</MenuItem>
                                                    <MenuItem value={2}>Negative</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">Urine Sugar</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={urine}
                                                    onChange={urineHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>None</MenuItem>
                                                    <MenuItem value={2}>Trace</MenuItem>
                                                    <MenuItem value={3}>+</MenuItem>
                                                    <MenuItem value={4}>++</MenuItem>
                                                    <MenuItem value={5}>+++</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">Gonorrhoea</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={gonorrhoea}
                                                    onChange={gonorrhoeaHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Seen</MenuItem>
                                                    <MenuItem value={2}>Not Seen</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">Trichomonus</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={trichomonus}
                                                    onChange={trichomonusHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Seen</MenuItem>
                                                    <MenuItem value={2}>Not Seen</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">Candida</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={candida}
                                                    onChange={candidaHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Seen</MenuItem>
                                                    <MenuItem value={2}>Not Seen</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">RPR</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={RPR}
                                                    onChange={RPRHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Reactive</MenuItem>
                                                    <MenuItem value={2}>Non Reactive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">TPHA</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={TPHA}
                                                    onChange={TPHAHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Reactive</MenuItem>
                                                    <MenuItem value={2}>Non Reactive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">VDRL</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={VDRL}
                                                    onChange={VDRLHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Reactive</MenuItem>
                                                    <MenuItem value={2}>Non Reactive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">HIV</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={HIV}
                                                    onChange={HIVHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Reactive</MenuItem>
                                                    <MenuItem value={2}>Non Reactive</MenuItem>
                                                    <MenuItem value={3}>Invalid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">HBV</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={HBV}
                                                    onChange={HBVHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Reactive</MenuItem>
                                                    <MenuItem value={2}>Non Reactive</MenuItem>
                                                    <MenuItem value={3}>Invalid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">Hep-C</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    value={HepC}
                                                    onChange={HepCHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>Reactive</MenuItem>
                                                    <MenuItem value={2}>Non Reactive</MenuItem>
                                                    <MenuItem value={3}>Invalid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <CustomUnicefTextField
                                                id="filled-basic"
                                                label="Remark"
                                                variantText="filled"
                                                style={{ marginTop: '11px', width: '95%' }}
                                                onChange={e => { setdeliLabForm({ ...deliLabForm, LABOTHER: e.target.value }) }}
                                                value={deliLabForm.LABOTHER}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={2}>
                                            <CustomUnicefTextField
                                                type="number"
                                                variantText="filled"
                                                inputProps={{ min: 0 }}
                                                InputLabelProps={{
                                                    style: { color: '#482642' },
                                                    shrink: true
                                                }}
                                                label={<Grid row container><Typography color="#482642">RBS </Typography>
                                                </Grid>}
                                                style={{ width: '95%', marginTop: '11px' }}
                                                onChange={e => { setdeliLabForm({ ...deliLabForm, LABRBS: e.target.value }) }}
                                                value={deliLabForm.LABRBS} />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </ThemeProvider>}
                        <Grid item xs={12} sm={12} md={12}>
                            <ThemeProvider theme={radioTheme}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '100%',
                                        borderRadius: '10px'
                                    }}
                                    className={classes.cardStyle}>
                                    {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px', fontWeight: 'bold' }}><Typography>Baby Outcome and Procedure </Typography>
                                    </Grid>}
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                        <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                            <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Type of Baby Outcome </Typography>
                                            </Grid>}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                style={{ width: '100%' }}
                                                value={deliBDeliOutcome}
                                                onChange={deliBDeliOutcomeHandle}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                    getContentAnchorEl: null
                                                }}>
                                                <MenuItem value={999}>-</MenuItem>
                                                <MenuItem value={1}>Still Birth</MenuItem>
                                                <MenuItem value={2}>Live Birth</MenuItem>
                                                <MenuItem value={3}>Prem</MenuItem>
                                                <MenuItem value={4}>IUGR</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* For Nae Thit Project, BTDuser */}
                                        {(sessionStorage.getItem('project') === 'P-007' && (sessionStorage.getItem('org') === 'CPI-01' || sessionStorage.getItem('org') === 'CPI-11'
                                            || sessionStorage.getItem('org') === 'CPI-14')) ? null :
                                            <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">Resuscitation</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"

                                                    multiple
                                                    value={resuscitation}
                                                    onChange={resuscitationHandle}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>

                                                    <MenuItem classes={{ selected: classes.selected }} value={'Drying with towel'}>Drying with towel</MenuItem>
                                                    <MenuItem classes={{ selected: classes.selected }} value={'Suction'}>Suction</MenuItem>
                                                    <MenuItem classes={{ selected: classes.selected }} value={'Stimulation'}>Stimulation</MenuItem>
                                                    <MenuItem classes={{ selected: classes.selected }} value={'Bag and mask'}>Bag and mask </MenuItem>
                                                    <MenuItem classes={{ selected: classes.selected }} value={'Chest compression'}>Chest compression</MenuItem>
                                                </Select>
                                            </FormControl>}

                                        <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                            <InputLabel id="demo-simple-select-filled-label">Baby Outcome</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                style={{ width: '95%' }}
                                                value={babyOutcome}
                                                onChange={babyOutcomeHandle}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                    getContentAnchorEl: null
                                                }}>
                                                <MenuItem value={999}>-</MenuItem>
                                                <MenuItem value={1}>OPD</MenuItem>
                                                <MenuItem value={2}>IPD</MenuItem>
                                                <MenuItem value={3}>Referral</MenuItem>
                                                <MenuItem value={4}>Death</MenuItem>
                                                <MenuItem value={5}>Discharge</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                        {babyOutcome === 3 && <>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                    <InputLabel id="demo-simple-select-filled-label">Provided ReferPlace</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        style={{ width: '95%' }}
                                                        value={deliBRefto}
                                                        onChange={deliBReftoHandle}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        <MenuItem value={999}>-</MenuItem>
                                                        {(sessionStorage.getItem('org') === 'CPI-05' || sessionStorage.getItem('org') === 'CPI-06' || sessionStorage.getItem('org') === 'CPI-07') ?
                                                            <MenuItem value={7}>KDHW Secondary Care</MenuItem> : null}
                                                        <MenuItem value={1}>Gov Hospital</MenuItem>
                                                        <MenuItem value={2}>MTC</MenuItem>
                                                        <MenuItem value={3}>NGO</MenuItem>
                                                        <MenuItem value={4}>Thai Hospital</MenuItem>
                                                        <MenuItem value={5}>Others</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <CustomTextField
                                                    id="filled-basic"
                                                    label="Reasons of Referral"
                                                    variantText="filled"
                                                    style={{ marginTop: '9px', width: '90%' }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIBREFREASON: e.target.value }) }}
                                                    value={deliForm.DELIBREFREASON}
                                                />
                                            </Grid></>}
                                        {babyOutcome === 4 && <>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <CustomTextField
                                                    id="filled-basic"
                                                    label="Reasons of Death"
                                                    variantText="filled"
                                                    style={{ marginTop: '9px', marginBottom: '10px', width: '90%' }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIBDEATHREASON: e.target.value }) }}
                                                    value={deliForm.DELIBDEATHREASON}
                                                />
                                            </Grid></>}
                                    </div>
                                </Card>
                            </ThemeProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ThemeProvider theme={radioTheme}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '100%',
                                        borderRadius: '10px'
                                    }}
                                    className={classes.cardStyle}>
                                    {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px', fontWeight: 'bold' }}><Typography>Baby-1 Record </Typography>
                                    </Grid>}
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                        <CustomTextField
                                            type="number"
                                            variantText="filled"
                                            inputProps={{ step: "1", min: 0 }}
                                            InputLabelProps={{
                                                style: { color: '#482642', textAlign: 'center' },
                                                shrink: true
                                            }}
                                            label={<Grid row container><Typography color="#482642">Birth Weight-1(kg) </Typography>
                                            </Grid>}
                                            style={{ marginTop: '10px', width: '40%' }}
                                            onChange={e => { (e.target.value.length > 5) ? setdeliForm({ ...deliForm, DELIBWT1: (e.target.value).slice(0, 5) }) : setdeliForm({ ...deliForm, DELIBWT1: e.target.value }) }}
                                            value={deliForm.DELIBWT1} />
                                        <FormControl style={{ width: '100%' }}>
                                            <Card
                                                variant="outlined"
                                                style={{
                                                    marginTop: '10px',
                                                    marginRight: '10px',
                                                    background: "#fcf0f2"
                                                }}
                                            >
                                                {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Baby Sex-1</Typography>
                                                </Grid>}

                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="gender1"
                                                    style={{
                                                        display: "flex",

                                                        flexDirection: 'row',
                                                        justifyContent: "space-around"
                                                    }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIBSEX1: e.target.value }) }}
                                                    value={deliForm.DELIBSEX1}
                                                    row={true}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        labelPlacement="left"
                                                        label="Male"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliB1SexHandleChange} onKeyDown={e => e.key === 'Enter' && deliB1SexHandleChange(e)} />}
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        labelPlacement="left"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliB1SexHandleChange} onKeyDown={e => e.key === 'Enter' && deliB1SexHandleChange(e)} />}
                                                        label="Female"
                                                    />
                                                </RadioGroup>
                                            </Card>
                                        </FormControl>
                                        <FormControl style={{ width: '100%' }}>
                                            <Card
                                                variant="outlined"
                                                style={{
                                                    marginTop: '10px',
                                                    marginRight: '10px',
                                                    background: "#fcf0f2"
                                                }}
                                            >
                                                {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Breast Feeding(in 30min)-1</Typography>
                                                </Grid>}

                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="gender1"
                                                    style={{
                                                        display: "flex",

                                                        flexDirection: 'row',
                                                        justifyContent: "space-around"
                                                    }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIBBF1: e.target.value }) }}
                                                    value={deliForm.DELIBBF1}
                                                    row={true}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        labelPlacement="left"
                                                        label="Yes"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliBBF1HandleChange} onKeyDown={e => e.key === 'Enter' && deliBBF1HandleChange(e)} />}
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        labelPlacement="left"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliBBF1HandleChange} onKeyDown={e => e.key === 'Enter' && deliBBF1HandleChange(e)} />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Card>
                                        </FormControl>
                                        <FormControlLabel
                                            style={{ marginTop: '10px' }}
                                            control={
                                                <Switch
                                                    checked={baby2}
                                                    onChange={baby2Handle}
                                                    name="baby2"
                                                    color="primary"
                                                />
                                            }
                                            InputLabelProps={{
                                                style: { color: '#482642' },
                                            }}
                                            label="Baby-2"
                                            labelPlacement="top"
                                        />
                                    </div>
                                </Card>
                            </ThemeProvider>
                        </Grid>
                        {baby2 && <Grid item xs={12} sm={12} md={12}>
                            <ThemeProvider theme={radioTheme}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '100%',
                                        borderRadius: '10px'
                                    }}
                                    className={classes.cardStyle}>
                                    {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px', fontWeight: 'bold' }}><Typography>Baby-2 Record </Typography>
                                    </Grid>}
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                        <CustomTextField
                                            type="number"
                                            variantText="filled"
                                            inputProps={{ step: "1", min: 0 }}
                                            InputLabelProps={{
                                                style: { color: '#482642', textAlign: 'center' },
                                                shrink: true
                                            }}
                                            label={<Grid row container><Typography color="#482642">Birth Weight-2(kg) </Typography>
                                            </Grid>}
                                            style={{ marginTop: '10px', width: '40%' }}
                                            onChange={e => { (e.target.value.length > 5) ? setdeliForm({ ...deliForm, DELIBWT2: (e.target.value).slice(0, 5) }) : setdeliForm({ ...deliForm, DELIBWT2: e.target.value }) }}
                                            value={deliForm.DELIBWT2} />
                                        <FormControl style={{ width: '100%' }}>
                                            <Card
                                                variant="outlined"
                                                style={{
                                                    marginTop: '10px',
                                                    marginRight: '10px',
                                                    background: "#fcf0f2"
                                                }}
                                            >
                                                {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Baby Sex-2</Typography>
                                                </Grid>}

                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="gender1"
                                                    style={{
                                                        display: "flex",

                                                        flexDirection: 'row',
                                                        justifyContent: "space-around"
                                                    }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIBSEX2: e.target.value }) }}
                                                    value={deliForm.DELIBSEX2}
                                                    row={true}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        labelPlacement="left"
                                                        label="Male"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliB2SexHandleChange} onKeyDown={e => e.key === 'Enter' && deliB2SexHandleChange(e)} />}
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        labelPlacement="left"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliB2SexHandleChange} onKeyDown={e => e.key === 'Enter' && deliB2SexHandleChange(e)} />}
                                                        label="Female"
                                                    />
                                                </RadioGroup>
                                            </Card>
                                        </FormControl>
                                        <FormControl style={{ width: '100%' }}>
                                            <Card
                                                variant="outlined"
                                                style={{
                                                    marginTop: '10px',
                                                    marginRight: '10px',
                                                    background: "#fcf0f2"
                                                }}
                                            >
                                                {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Breast Feeding(in 30min)-2</Typography>
                                                </Grid>}

                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="gender1"
                                                    style={{
                                                        display: "flex",

                                                        flexDirection: 'row',
                                                        justifyContent: "space-around"
                                                    }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIBBF2: e.target.value }) }}
                                                    value={deliForm.DELIBBF2}
                                                    row={true}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        labelPlacement="left"
                                                        label="Yes"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliBBF2HandleChange} onKeyDown={e => e.key === 'Enter' && deliBBF2HandleChange(e)} />}
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        labelPlacement="left"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliBBF2HandleChange} onKeyDown={e => e.key === 'Enter' && deliBBF2HandleChange(e)} />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Card>
                                        </FormControl>
                                        <FormControlLabel
                                            style={{ marginTop: '10px' }}
                                            control={
                                                <Switch
                                                    checked={baby3}
                                                    onChange={baby3Handle}
                                                    name="baby2"
                                                    color="primary"
                                                />
                                            }
                                            InputLabelProps={{
                                                style: { color: '#482642' },
                                            }}
                                            label="Baby-3"
                                            labelPlacement="top"
                                        />
                                    </div>
                                </Card>
                            </ThemeProvider>
                        </Grid>}
                        {baby3 && <Grid item xs={12} sm={12} md={12}>
                            <ThemeProvider theme={radioTheme}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '100%',
                                        borderRadius: '10px'
                                    }}
                                    className={classes.cardStyle}>
                                    {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px', fontWeight: 'bold' }}><Typography>Baby-3 Record </Typography>
                                    </Grid>}
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                        <CustomTextField
                                            type="number"
                                            variantText="filled"
                                            inputProps={{ step: "1", min: 0 }}
                                            InputLabelProps={{
                                                style: { color: '#482642', textAlign: 'center' },
                                                shrink: true
                                            }}
                                            label={<Grid row container><Typography color="#482642">Birth Weight-3(kg) </Typography>
                                            </Grid>}
                                            style={{ marginTop: '10px', width: '40%' }}
                                            onChange={e => { (e.target.value.length > 5) ? setdeliForm({ ...deliForm, DELIBWT3: (e.target.value).slice(0, 5) }) : setdeliForm({ ...deliForm, DELIBWT3: e.target.value }) }}
                                            value={deliForm.DELIBWT3} />
                                        <FormControl style={{ width: '100%' }}>
                                            <Card
                                                variant="outlined"
                                                style={{
                                                    marginTop: '10px',
                                                    marginRight: '10px',
                                                    background: "#fcf0f2"
                                                }}
                                            >
                                                {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Baby Sex-3</Typography>
                                                </Grid>}

                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="gender1"
                                                    style={{
                                                        display: "flex",

                                                        flexDirection: 'row',
                                                        justifyContent: "space-around"
                                                    }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIBSEX3: e.target.value }) }}
                                                    value={deliForm.DELIBSEX3}
                                                    row={true}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        labelPlacement="left"
                                                        label="Male"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliB3SexHandleChange} onKeyDown={e => e.key === 'Enter' && deliB3SexHandleChange(e)} />}
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        labelPlacement="left"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliB3SexHandleChange} onKeyDown={e => e.key === 'Enter' && deliB3SexHandleChange(e)} />}
                                                        label="Female"
                                                    />
                                                </RadioGroup>
                                            </Card>
                                        </FormControl>
                                        <FormControl style={{ width: '100%' }}>
                                            <Card
                                                variant="outlined"
                                                style={{
                                                    marginTop: '10px',
                                                    marginRight: '10px',
                                                    background: "#fcf0f2"
                                                }}
                                            >
                                                {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Breast Feeding(in 30min)-3</Typography>
                                                </Grid>}

                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="gender1"
                                                    style={{
                                                        display: "flex",

                                                        flexDirection: 'row',
                                                        justifyContent: "space-around"
                                                    }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIBBF3: e.target.value }) }}
                                                    value={deliForm.DELIBBF3}
                                                    row={true}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        labelPlacement="left"
                                                        label="Yes"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliBBF3HandleChange} onKeyDown={e => e.key === 'Enter' && deliBBF3HandleChange(e)} />}
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        labelPlacement="left"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={deliBBF3HandleChange} onKeyDown={e => e.key === 'Enter' && deliBBF3HandleChange(e)} />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Card>
                                        </FormControl>
                                    </div>
                                </Card>
                            </ThemeProvider>
                        </Grid>}
                        {/* For Nae Thit Project, BTDuser */}
                        {(sessionStorage.getItem('project') === 'P-007' && (sessionStorage.getItem('org') === 'CPI-01' || sessionStorage.getItem('org') === 'CPI-11'
                            || sessionStorage.getItem('org') === 'CPI-14')) ? null :
                            <Grid item xs={12} sm={12} md={12}>
                                <ThemeProvider theme={radioTheme}>
                                    <Card
                                        variant="outlined"
                                        style={{
                                            background: "#fcf0f2",
                                            width: '100%',
                                            borderRadius: '10px'
                                        }}
                                        className={classes.cardStyle}>
                                        {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px', fontWeight: 'bold' }}><Typography>Maternal Complication-Procedure-Treatment</Typography>
                                        </Grid>}

                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <CustomTextField
                                                    id="filled-basic"
                                                    label="Maternal Complication"
                                                    variantText="filled"
                                                    style={{ marginTop: '9px', width: '90%' }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIMCOMPLICATION: e.target.value }) }}
                                                    value={deliForm.DELIMCOMPLICATION}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <CustomTextField
                                                    id="filled-basic"
                                                    label="Procedure"
                                                    variantText="filled"
                                                    style={{ marginTop: '9px', width: '90%' }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIMPROCEDURE: e.target.value }) }}
                                                    value={deliForm.DELIMPROCEDURE}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <CustomTextField
                                                    id="filled-basic"
                                                    label="Treatment"
                                                    variantText="filled"
                                                    style={{ marginTop: '9px', width: '90%' }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIMTX: e.target.value }) }}
                                                    value={deliForm.DELIMTX}
                                                />
                                            </Grid>
                                        </div>


                                    </Card>
                                </ThemeProvider>
                            </Grid>}

                        <ThemeProvider theme={radioTheme}>
                            <Card
                                variant="outlined"
                                style={{
                                    background: "#fcf0f2",
                                    width: '100%',
                                    borderRadius: '10px',
                                    marginLeft: '10px',
                                    marginRight: '10px'
                                }}
                                className={classes.cardStyle}>
                                {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px' }}><Typography>Maternal Outcome </Typography>
                                </Grid>}
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', }}>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <FormControl variant="filled" className={classes.formControl} style={{ width: '95%', marginLeft: '13px' }}>
                                            <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Maternal Outcome </Typography>
                                                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                style={{ width: '95%' }}
                                                value={patientMOutcome}
                                                onChange={patientMOutcomeHandle}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                    getContentAnchorEl: null
                                                }}>
                                                <MenuItem value={999}>-</MenuItem>
                                                <MenuItem value={1}>OPD</MenuItem>
                                                <MenuItem value={2}>IPD</MenuItem>
                                                <MenuItem value={3}>Referral</MenuItem>
                                                <MenuItem value={4}>Death</MenuItem>
                                                <MenuItem value={5}>Discharge</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {patientMOutcome === 3 && <>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '95%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">Provided ReferPlace</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    style={{ width: '95%' }}
                                                    value={referMPlace}
                                                    onChange={referMPlaceHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    {(sessionStorage.getItem('org') === 'CPI-05' || sessionStorage.getItem('org') === 'CPI-06' || sessionStorage.getItem('org') === 'CPI-07') ?
                                                        <MenuItem value={7}>KDHW Secondary Care</MenuItem> : null}
                                                    <MenuItem value={1}>Gov Hospital</MenuItem>
                                                    <MenuItem value={2}>MTC</MenuItem>
                                                    <MenuItem value={3}>NGO</MenuItem>
                                                    <MenuItem value={4}>Thai Hospital</MenuItem>
                                                    <MenuItem value={5}>Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <CustomTextField
                                                id="filled-basic"
                                                label="Reasons of Referral"
                                                variantText="filled"
                                                style={{ marginTop: '9px', width: '90%' }}
                                                onChange={e => { setdeliForm({ ...deliForm, DELIMREFREASON: e.target.value }) }}
                                                value={deliForm.DELIMREFREASON}
                                            />
                                        </Grid></>}
                                    {patientMOutcome === 4 && <>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <CustomTextField
                                                id="filled-basic"
                                                label="Reasons of Death"
                                                variantText="filled"
                                                style={{ marginTop: '9px', marginBottom: '10px', width: '90%' }}
                                                onChange={e => { setdeliForm({ ...deliForm, DELIMDEATHREASON: e.target.value }) }}
                                                value={deliForm.DELIMDEATHREASON}
                                            />
                                        </Grid></>}
                                </div>



                            </Card>
                        </ThemeProvider>
                        <Grid item xs={12} sm={12} md={12}>
                            <ThemeProvider theme={radioTheme}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '100%',
                                        borderRadius: '10px',

                                    }}
                                    className={classes.cardStyle}>
                                    {<Grid row container style={{ background: '#6c5268', color: 'white', padding: '10px' }}><Typography>Provider Information</Typography>
                                    </Grid>}
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormControl variant="filled" className={classes.formControl} style={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Provider Position </Typography>
                                                    <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    style={{ width: '100%' }}
                                                    value={proPosition}
                                                    onChange={proPositionHandle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                        getContentAnchorEl: null
                                                    }}>
                                                    <MenuItem value={999}>-</MenuItem>
                                                    <MenuItem value={1}>EmOCW</MenuItem>
                                                    <MenuItem value={2}>MCHW</MenuItem>
                                                    <MenuItem value={3}>Medic</MenuItem>
                                                    <MenuItem value={4}>CHW</MenuItem>
                                                    <MenuItem value={5}>AMW</MenuItem>
                                                    <MenuItem value={6}>Nurse</MenuItem>
                                                    <MenuItem value={7}>Doctor</MenuItem>
                                                    <MenuItem value={8}>TTBA/TBA</MenuItem>
                                                    <MenuItem value={9}>Other</MenuItem>
                                                    <MenuItem value={13}>Midwife</MenuItem>
                                                    <MenuItem value={11}>Health Assistant(HA)</MenuItem>
                                                    {(sessionStorage.getItem('org') === 'CPI-05' || sessionStorage.getItem('org') === 'CPI-06' || sessionStorage.getItem('org') === 'CPI-07') ?
                                                        <MenuItem value={18} >PA(Physician Assistant)</MenuItem> : null}
                                                    {(sessionStorage.getItem('project') === 'P-008' ||
                                                        sessionStorage.getItem('org') === 'CPI-17' || sessionStorage.getItem('org') === 'CPI-18' ||
                                                        sessionStorage.getItem('org') === 'CPI-19' || sessionStorage.getItem('org') === 'CPI-63' || sessionStorage.getItem('org') === 'CPI-86') ?
                                                        <MenuItem value={16} >VHW</MenuItem> : null}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <CustomTextField
                                                id="filled-basic"
                                                label="Provider Name"
                                                variantText="filled"
                                                style={{ marginTop: '9px', width: '95%' }}
                                                onChange={e => { setdeliForm({ ...deliForm, DELIPROVIDERNAME: e.target.value }) }}
                                                value={deliForm.DELIPROVIDERNAME}
                                            />
                                        </Grid>



                                    </div>



                                </Card>
                            </ThemeProvider>
                        </Grid>


                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            {/* {(sessionStorage.getItem('project') === 'P-008' &&
            (sessionStorage.getItem('org') === 'CPI-17' || sessionStorage.getItem('org') === 'CPI-18' ||
            sessionStorage.getItem('org') === 'CPI-19' || sessionStorage.getItem('org') === 'CPI-63' || sessionStorage.getItem('org') === 'CPI-86'))} */}
                            {(sessionStorage.getItem('org') === 'CPI-17' || sessionStorage.getItem('org') === 'CPI-18' ||
                                sessionStorage.getItem('org') === 'CPI-86' || sessionStorage.getItem('org') === 'CPI-63' ||
                                sessionStorage.getItem('org') === 'CPI-87' || sessionStorage.getItem('org') === 'CPI-88') ?
                                <>
                                    <Grid item xs={6} sm={4} md={4} >
                                        <ThemeProvider theme={radioTheme}>
                                            <Card
                                                variant="outlined"
                                                style={{
                                                    background: "#fcf0f2",
                                                    width: '95%',

                                                }}
                                                className={classes.cardStyle}>
                                                {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Migrant Worker</Typography>
                                                </Grid>}

                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="gender1"
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent: "space-around"
                                                    }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIMIGRANT: e.target.value }) }}
                                                    value={deliForm.DELIMIGRANT}
                                                    row={true}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        labelPlacement="left"
                                                        label="Yes"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={migrantHandleChange} onKeyDown={e => e.key === 'Enter' && migrantHandleChange(e)} />}
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        labelPlacement="left"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={migrantHandleChange} onKeyDown={e => e.key === 'Enter' && migrantHandleChange(e)} />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Card>
                                        </ThemeProvider>
                                    </Grid>

                                    <Grid item xs={6} sm={4} md={4} >
                                        <ThemeProvider theme={radioTheme}>
                                            <Card
                                                variant="outlined"
                                                style={{
                                                    background: "#fcf0f2",
                                                    width: '95%',
                                                    marginLeft: '12px'
                                                }}
                                                className={classes.cardStyle}>
                                                {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Internally Displaced Person</Typography>
                                                </Grid>}

                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="gender1"
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent: "space-around"
                                                    }}
                                                    onChange={e => { setdeliForm({ ...deliForm, DELIIDP: e.target.value }) }}
                                                    value={deliForm.DELIIDP}
                                                    row={true}
                                                >
                                                    <FormControlLabel
                                                        value="1"
                                                        labelPlacement="left"
                                                        label="Yes"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={IDPHandleChange} onKeyDown={e => e.key === 'Enter' && IDPHandleChange(e)} />}
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        labelPlacement="left"
                                                        style={{ height: "30px" }}
                                                        className={classes.fontSize}
                                                        control={<Radio size="small" color="primary"
                                                            onClick={IDPHandleChange} onKeyDown={e => e.key === 'Enter' && IDPHandleChange(e)} />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Card>
                                        </ThemeProvider>
                                    </Grid>


                                </> : null}

                                {(sessionStorage.getItem('project') === 'P-051') ? 
                                <Grid item xs={6} sm={4} md={4} >
                                            <ThemeProvider theme={radioTheme}>
                                                <Card
                                                    variant="outlined"
                                                    style={{
                                                        background: "#fcf0f2",
                                                        width: '95%',
                                                        marginLeft: '12px'
                                                    }}
                                                    className={classes.cardStyle}>
                                                    {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Internally Displaced Person</Typography>
                                                    </Grid>}

                                                    <RadioGroup
                                                        aria-label="gender"
                                                        name="gender1"
                                                        style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            justifyContent: "space-around"
                                                        }}
                                                        onChange={e => { setdeliForm({ ...deliForm, DELIIDP: e.target.value }) }}
                                                        value={deliForm.DELIIDP}
                                                        row={true}
                                                    >
                                                        <FormControlLabel
                                                            value="1"
                                                            labelPlacement="left"
                                                            label="Yes"
                                                            style={{ height: "30px" }}
                                                            className={classes.fontSize}
                                                            control={<Radio size="small" color="primary"
                                                                onClick={IDPHandleChange} onKeyDown={e => e.key === 'Enter' && IDPHandleChange(e)} />}
                                                        />
                                                        <FormControlLabel
                                                            value="2"
                                                            labelPlacement="left"
                                                            style={{ height: "30px" }}
                                                            className={classes.fontSize}
                                                            control={<Radio size="small" color="primary"
                                                                onClick={IDPHandleChange} onKeyDown={e => e.key === 'Enter' && IDPHandleChange(e)} />}
                                                            label="No"
                                                        />
                                                    </RadioGroup>
                                                </Card>
                                            </ThemeProvider>
                                        </Grid> : null}

                            {/* {(sessionStorage.getItem('org') === 'CPI-86'|| sessionStorage.getItem('org') === 'CPI-17' || 
sessionStorage.getItem('org') === 'CPI-18' || sessionStorage.getItem('org') === 'CPI-19' || 
sessionStorage.getItem('org') === 'CPI-63') && (sessionStorage.getItem('project') !== 'P-008')} 

((sessionStorage.getItem('org') === 'CPI-19') && (sessionStorage.getItem('project') !== 'P-008'))
*/}
                            {((sessionStorage.getItem('org') === 'CPI-05' || sessionStorage.getItem('org') === 'CPI-06' ||
                                sessionStorage.getItem('org') === 'CPI-07' || sessionStorage.getItem('org') === 'CPI-08' ||
                                sessionStorage.getItem('org') === 'CPI-13' || sessionStorage.getItem('org') === 'CPI-15' || sessionStorage.getItem('org') === 'CPI-20')) ?
                                <Grid item xs={6} sm={4} md={4} >
                                    <ThemeProvider theme={radioTheme}>
                                        <Card
                                            variant="outlined"
                                            style={{
                                                background: "#fcf0f2",
                                                width: '95%',
                                                marginLeft: '12px'
                                            }}
                                            className={classes.cardStyle}>
                                            {<Grid row container style={{ marginLeft: "13px", marginTop: "3px" }}><Typography color="#482642">Disablility/Difficulty</Typography>
                                            </Grid>}

                                            <RadioGroup
                                                aria-label="gender"
                                                name="gender1"
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-around"
                                                }}
                                                onChange={e => { setdeliForm({ ...deliForm, DELIDISABILITY: e.target.value }) }}
                                                value={deliForm.DELIDISABILITY}
                                                row={true}
                                            >
                                                <FormControlLabel
                                                    value="1"
                                                    labelPlacement="left"
                                                    label="Yes"
                                                    style={{ height: "30px" }}
                                                    className={classes.fontSize}
                                                    control={<Radio size="small" color="primary"
                                                        onClick={disablilityHandleChange} onKeyDown={e => e.key === 'Enter' && disablilityHandleChange(e)} />}
                                                />
                                                <FormControlLabel
                                                    value="2"
                                                    labelPlacement="left"
                                                    style={{ height: "30px" }}
                                                    className={classes.fontSize}
                                                    control={<Radio size="small" color="primary"
                                                        onClick={disablilityHandleChange} onKeyDown={e => e.key === 'Enter' && disablilityHandleChange(e)} />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                        </Card>
                                    </ThemeProvider>
                                </Grid> : null}
                        </Grid>

                        {/*  {(sessionStorage.getItem('project') === 'P-008' && (sessionStorage.getItem('org') === 'CPI-17' || 
sessionStorage.getItem('org') === 'CPI-63')) && (sessionStorage.getItem('org') !== 'CPI-86')} */}
                        { (sessionStorage.getItem('project') !== 'P-051') && (sessionStorage.getItem('org') === 'CPI-17' || sessionStorage.getItem('org') === 'CPI-18' ||
                            sessionStorage.getItem('org') === 'CPI-86' || sessionStorage.getItem('org') === 'CPI-63' ||
                            sessionStorage.getItem('org') === 'CPI-87' || sessionStorage.getItem('org') === 'CPI-88' ||
                            ((sessionStorage.getItem('org') === 'CPI-05' || sessionStorage.getItem('org') === 'CPI-06' || sessionStorage.getItem('org') === 'CPI-07') && deliForm.DELIDISABILITY === '1') || sessionStorage.getItem('org') === 'CPI-08' ||
                            sessionStorage.getItem('org') === 'CPI-13' || sessionStorage.getItem('org') === 'CPI-15' || sessionStorage.getItem('org') === 'CPI-20') ?
                            <Grid row container spacing={2} alignItems="center" justifyContent="center">
                                <Card
                                    variant="outlined"
                                    style={{
                                        background: "#fcf0f2",
                                        width: '98%',
                                        marginTop: '2%',
                                        padding: '1%'
                                    }}
                                >
                                    {<Grid item alignItems="center" justifyContent="center" style={{ alignSelf: "center", fontWeight: 'lightBold', marginBottom: "1%", textAlign: 'center' }}><Typography color="#482642">Disability Category</Typography></Grid>}
                                    <Grid container row xs={12} sm={12} md={12} alignItems="center" justifyContent="center">
                                        <Grid item xs={12} sm={2} md={2} >
                                            <ThemeProvider theme={radioTheme}>
                                                <FormControl variant="filled" className={classes.formControl} style={{ width: '100%' }}>
                                                    <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Seeing</Typography>
                                                    </Grid>}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        style={{ width: '90%' }}
                                                        value={seeDis}
                                                        onChange={seeDisHandle}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        <MenuItem value={'999'}>-</MenuItem>
                                                        <MenuItem value={'1'}>No difficulty</MenuItem>
                                                        <MenuItem value={'2'}>Some difficulties</MenuItem>
                                                        <MenuItem value={'3'}>A lot of difficulties</MenuItem>
                                                        <MenuItem value={'4'}>Cannot do it at all</MenuItem>
                                                        <MenuItem value={'9'}>NA</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={2} >
                                            <ThemeProvider theme={radioTheme}>
                                                <FormControl variant="filled" className={classes.formControl} style={{ width: '100%' }}>
                                                    <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Hearing</Typography>
                                                    </Grid>}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        style={{ width: '90%' }}
                                                        value={hearDis}
                                                        onChange={hearDisHandle}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        <MenuItem value={'999'}>-</MenuItem>
                                                        <MenuItem value={'1'}>No difficulty</MenuItem>
                                                        <MenuItem value={'2'}>Some difficulties</MenuItem>
                                                        <MenuItem value={'3'}>A lot of difficulties</MenuItem>
                                                        <MenuItem value={'4'}>Cannot do it at all</MenuItem>
                                                        <MenuItem value={'9'}>NA</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={2} >
                                            <ThemeProvider theme={radioTheme}>
                                                <FormControl variant="filled" className={classes.formControl} style={{ width: '100%' }}>
                                                    <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Walking</Typography>
                                                    </Grid>}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        style={{ width: '90%' }}
                                                        value={walkDis}
                                                        onChange={walkDisHandle}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        <MenuItem value={'999'}>-</MenuItem>
                                                        <MenuItem value={'1'}>No difficulty</MenuItem>
                                                        <MenuItem value={'2'}>Some difficulties</MenuItem>
                                                        <MenuItem value={'3'}>A lot of difficulties</MenuItem>
                                                        <MenuItem value={'4'}>Cannot do it at all</MenuItem>
                                                        <MenuItem value={'9'}>NA</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={2} >
                                            <ThemeProvider theme={radioTheme}>
                                                <FormControl variant="filled" className={classes.formControl} style={{ width: '100%' }}>
                                                    <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Remembering</Typography>
                                                    </Grid>}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        style={{ width: '90%' }}
                                                        value={remDis}
                                                        onChange={remDisHandle}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        <MenuItem value={'999'}>-</MenuItem>
                                                        <MenuItem value={'1'}>No difficulty</MenuItem>
                                                        <MenuItem value={'2'}>Some difficulties</MenuItem>
                                                        <MenuItem value={'3'}>A lot of difficulties</MenuItem>
                                                        <MenuItem value={'4'}>Cannot do it at all</MenuItem>
                                                        <MenuItem value={'9'}>NA</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={2}>
                                            <ThemeProvider theme={radioTheme}>
                                                <FormControl variant="filled" className={classes.formControl} style={{ width: '100%' }}>
                                                    <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Washing</Typography>
                                                    </Grid>}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        style={{ width: '90%' }}
                                                        value={washDis}
                                                        onChange={washDisHandle}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        <MenuItem value={'999'}>-</MenuItem>
                                                        <MenuItem value={'1'}>No difficulty</MenuItem>
                                                        <MenuItem value={'2'}>Some difficulties</MenuItem>
                                                        <MenuItem value={'3'}>A lot of difficulties</MenuItem>
                                                        <MenuItem value={'4'}>Cannot do it at all</MenuItem>
                                                        <MenuItem value={'9'}>NA</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={2} >
                                            <ThemeProvider theme={radioTheme}>
                                                <FormControl variant="filled" className={classes.formControl} style={{ width: '100%' }}>
                                                    <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642">Communicating</Typography>
                                                    </Grid>}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        style={{ width: '90%' }}
                                                        value={comDis}
                                                        onChange={comDisHandle}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            style: {
                                                                maxHeight: 300,
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        <MenuItem value={'999'}>-</MenuItem>
                                                        <MenuItem value={'1'}>No difficulty</MenuItem>
                                                        <MenuItem value={'2'}>Some difficulties</MenuItem>
                                                        <MenuItem value={'3'}>A lot of difficulties</MenuItem>
                                                        <MenuItem value={'4'}>Cannot do it at all</MenuItem>
                                                        <MenuItem value={'9'}>NA</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </ThemeProvider>
                                        </Grid>
                                    </Grid>

                                </Card>
                            </Grid> : null}

                        <Grid item xs={12} sm={4} md={4}>
                            <CustomTextField
                                id="filled-basic"
                                label="Remark/Comment"
                                variantText="filled"
                                style={{ marginTop: '30px', width: '95%' }}
                                onChange={e => { setdeliForm({ ...deliForm, DELIREMARK: e.target.value }) }}
                                value={deliForm.DELIREMARK}
                            />
                        </Grid>

                    </Grid>
                </div>
                {ageValid && <>
                    <Grid container spacing={10} alignItems="center" justifyContent="center" style={{ padding: '20px' }} row>
                        <Grid item xs={'auto'} style={{ width: '18%' }}>
                            <Button
                                variant="contained"
                                style={{ background: '#482642', color: '#fff', width: '100%' }}
                                onClick={update}  >Update</Button>
                        </Grid>
                        <Grid item xs={'auto'} style={{ width: '18%' }}>
                            <Button
                                variant="contained"
                                style={{ background: '#482642', color: '#fff', width: '100%' }}
                                onClick={cancle}>Cancel</Button>
                        </Grid>
                    </Grid>
                </>}
                {openSnack && <CustomSnackBar open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
                {ageSnack && <CustomSnackBar open={setAgeSnackBarOpen} close={setAgeSnackBarClose} alertMsg={ageError} type="warning" />}
                {successSnack && <CustomSnackBar open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}


            </div>


        </>);
}
