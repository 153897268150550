import { Button, Card, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography, FormLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import CustomUnicefTable from '../../components/controls/CustomUnicefTable'
import CustomUnicefTextField from '../../components/controls/CustomUnicefTextField'
import CustomizedSnackbars from '../../components/controls/CustomSnackBar';
import Modals from "../../components/modal";
import _ from 'lodash';
import moment from "moment";

/////////////////////API////////////////////
import { getUnicefClinic } from '../../modals/unicefClinic'
import { insertIMAM, updateIMAM } from '../../modals/imaminfo';
import * as serviceData from '../../modals/imamservicedatabyid'

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      flexGrow: 1
    }
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#fcf0f2"
  },
  fontSize: {
    "& span:last-child": {
      fontSize: 13
    }
  },
  cardStyle: {
    marginBottom: theme.spacing(1),
  },
  cardStyleTwo: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.9),
    borderRadius: "3px"
  },
  formControl: {
    margin: theme.spacing(1),
  },

  table: {
    width: '100%',
    background: '#fcf0f2',
    height: '10px',


  },
  cellOne: {
    borderBottom: 'none',
    color: '#808080',
    fontSize: '9pt',

  },
  cellTwo: {
    borderBottom: 'none',
    color: '#53344d',
    fontSize: '12pt',
    fontWeight: 'bold',


  },
  secondFormControl: {
    margin: theme.spacing(1),
    width: '40%',
  },
  selected: {
    backgroundColor: "#DED4DA !important",
    color: '#482642'
  },
}));

const radioTheme = createTheme({
  palette: {
    primary: {
      main: "#482642"
    },
    secondary: {
      main: "#ffff"
    }
  }
});

export default function OTPEditForm() {

  let wfh = "WFH < -3 Z/ MUAC < 115 mm"

  const classes = useStyles();

  const history = useHistory();

  ///////Background Data///////////
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState([])
  const [clinicData, setClinicData] = useState([]);
  const [clinicCode, setClinicCode] = useState('')
  const [divCode, setDivCode] = useState('')
  const [tspCode, setTspCode] = useState('')
  const [divName, setDivName] = useState('')
  const [tspName, setTspName] = useState('')

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [successSnack, setSuccessSnack] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)

  const [formData, setFormData] = useState(
    {
      IMAMOTPDONOR: '',
      IMAMOTPORG: '',
      IMAMOTPPROJECT: '',
      IMAMOTPCLNID: '',
      IMAMOTPDIVID: '',
      IMAMOTPTSPID: '',
      IMAMOTPREGID: '',
      IMAMOTPREGNAME: '',
      IMAMOTPREGADDRESS: '',
      IMAMOTPREGAGE: '',
      IMAMOTPREGAGEUNIT: '30',
      IMAMOTPREGSEX: '',
      IMAMOTPENTYDATE: '',
      IMAMOTPENTYCAT: '',
      IMAMOTPENTYWT: '',
      IMAMOTPENTYHT: '',
      IMAMOTPENTYWFHL: '',
      IMAMOTPENTYMUAC: '',
      IMAMOTPENTYSAM: '',
      IMAMOTPEXTDATE: '',
      IMAMOTPEXTWT: '',
      IMAMOTPEXTHT: '',
      IMAMOTPEXTWFHL: '',
      IMAMOTPEXTMUAC: '',
      IMAMOTPEXTODM: '',
      IMAMOTPLWTDATE: '',
      IMAMOTPLWTCAT: '999',
      IMAMOTPUSRLOGIN: '',
      IMAMOTPUPDATE: '',
      IMAMOTPSTATUS: '',
      IMAMOTPSYNC: '',
      ID: '',
      IMAMOTPEXTMINWT: '',
      IMAMOTPEXTSAM: '',
    }
  )

  ////////////Handle Change//////////////////////////
  function sexHandleChange(event) {
    if (event.target.value === formData.IMAMOTPREGSEX) {
      setFormData({ ...formData, IMAMOTPREGSEX: "" })
    } else {
      setFormData({ ...formData, IMAMOTPREGSEX: event.target.value })
    }
  }


  const clinicHandleChange = (event) => {
    setClinicCode(event.target.value)
    let cData = _.find(clinicData, ['CLN_ID', event.target.value]);
    formData.IMAMOTPCLNID = event.target.value
    formData.IMAMOTPTSPID = cData.TSP_ID
    setTspCode(cData.TSP_ID)
    setTspName(cData.TSP_NAME)
    formData.IMAMOTPDIVID = cData.DIV_ID
    setDivCode(cData.DIV_ID)
    setDivName(cData.DIV_NAME)
    console.log("Selected Clinic => ", event.target.value)
  };

  const setSuccessSnackBarOpen = () => {
    setSuccessSnack(true)
  }

  const setSuccessSnackBarClose = () => {
    setSuccessSnack(false)
  }

  const setSnackBarOpen = () => {
    setOpenSnack(true)
  }

  const setSnackBarClose = () => {
    setOpenSnack(false)
  }



  const save = async () => {

    let valid = !formData.IMAMOTPREGID ? 'အာဟာရ မှတ်ပုံတင် အမှတ် ကိုရေးပေးပါ။' :
      !formData.IMAMOTPREGNAME ? 'အမည် ကိုရေးပေးပါ။' :
        !formData.IMAMOTPREGAGE ? 'အသက် ကိုရေးပေးပါ။' :
          !formData.IMAMOTPENTYDATE ? 'စာရင်းသွင်း ၀င်ခွင့်ပြုချိန် မှတ်တမ်း နေ့စွဲ ကိုရွေးပေးပါ။' :
            !formData.IMAMOTPENTYCAT ? 'စာရင်းသွင်းလူနာအုပ်စု ကိုရွေးပေးပါ။' :
              !formData.IMAMOTPENTYMUAC ? 'စာရင်းသွင်း ၀င်ခွင့်ပြုချိန် မှတ်တမ်း မှ MUAC ကိုဖြည့်ပေးပါ။' :
                !formData.IMAMOTPEXTMUAC ? 'စာရင်းမှ နုတ်ပယ်ထွက်ခွင့်ပြုချိန် မှတ်တမ်း မှ MUAC ကိုဖြည့်ပေးပါ။' : 'valid';

    if (valid === 'valid') {
      formData.IMAMOTPENTYSAM = formData.IMAMOTPENTYMUAC > 124 ? 'Good' : (formData.IMAMOTPENTYMUAC > 114 && formData.IMAMOTPENTYMUAC <= 124) ? 'MAM' : 'SAM'
      formData.IMAMOTPEXTSAM = formData.IMAMOTPEXTMUAC > 124 ? 'Good' : (formData.IMAMOTPEXTMUAC > 114 && formData.IMAMOTPEXTMUAC <= 124) ? 'MAM' : 'SAM'
      formData.IMAMOTPUPDATE = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')

      const rhres = await updateIMAM({ formData });
      if (rhres?.status === 200) {
        sessionStorage.setItem('homeSave', 'done')
        setSuccess("Successfully updated a patient's IMAMOTP Service")
        setSuccessSnack(true)
        setTimeout(function () {
          history.push({
            pathname: "entryhomepage",
          });
        }, 1500);
      }
      console.log("formData => ", formData)
    }
    else {
      setError(valid)
      setOpenSnack(true)
    }
  }

  const cancle = () => {
    history.push('entryhomepage')
    sessionStorage.setItem('homeSave', 'done')
  }

  useEffect(async () => {

    setLoading(true)
    let clinic = await getUnicefClinic()
    let service = await serviceData.getServiceData()

    if (clinic.data.data.getUnicefClinic.length && service.data.data.getServiceData.length) {

      let serviceData = await service.data.data.getServiceData;

      console.log("Unicef Clinic Data ========> ", clinic)
      setClinicData(clinic.data.data.getUnicefClinic)
      setPatientData(service.data.data.getServiceData)

      let cData = _.find(clinic.data.data.getUnicefClinic, ['CLN_ID', serviceData[0].IMAMOTPCLNID]);
      setTspName(cData.TSP_NAME)
      setDivName(cData.DIV_NAME)
      formData.IMAMOTPTSPID = serviceData[0].IMAMOTPTSPID
      setTspCode(serviceData[0].IMAMOTPTSPID)
      formData.IMAMOTPDIVID = serviceData[0].IMAMOTPDIVID
      setDivCode(serviceData[0].IMAMOTPDIVID)
      setClinicCode(serviceData[0].IMAMOTPCLNID)
      formData.IMAMOTPDONOR = serviceData[0].IMAMOTPDONOR
      formData.IMAMOTPORG = serviceData[0].IMAMOTPORG
      formData.IMAMOTPPROJECT = serviceData[0].IMAMOTPPROJECT
      formData.IMAMOTPCLNID = serviceData[0].IMAMOTPCLNID
      formData.IMAMOTPDIVID = serviceData[0].IMAMOTPDIVID
      formData.IMAMOTPTSPID = serviceData[0].IMAMOTPTSPID
      formData.IMAMOTPREGID = serviceData[0].IMAMOTPREGID
      formData.IMAMOTPREGNAME = serviceData[0].IMAMOTPREGNAME
      formData.IMAMOTPREGADDRESS = serviceData[0].IMAMOTPREGADDRESS
      formData.IMAMOTPREGAGE = serviceData[0].IMAMOTPREGAGE
      formData.IMAMOTPREGAGEUNIT = serviceData[0].IMAMOTPREGAGEUNIT

      formData.IMAMOTPREGSEX = serviceData[0].IMAMOTPREGSEX + ''

      formData.IMAMOTPENTYDATE = moment(serviceData[0].IMAMOTPENTYDATE).format("YYYY-MM-DD")
      formData.IMAMOTPENTYCAT = serviceData[0].IMAMOTPENTYCAT + ''
      formData.IMAMOTPENTYWT = serviceData[0].IMAMOTPENTYWT
      formData.IMAMOTPENTYHT = serviceData[0].IMAMOTPENTYHT
      formData.IMAMOTPENTYWFHL = serviceData[0].IMAMOTPENTYWFHL
      formData.IMAMOTPENTYMUAC = serviceData[0].IMAMOTPENTYMUAC
      formData.IMAMOTPENTYSAM = serviceData[0].IMAMOTPENTYSAM
      formData.IMAMOTPEXTDATE = serviceData[0].IMAMOTPEXTDATE ? moment(serviceData[0].IMAMOTPEXTDATE).format("YYYY-MM-DD") : ''
      formData.IMAMOTPEXTWT = (serviceData[0].IMAMOTPEXTWT === 999.9 || serviceData[0].IMAMOTPEXTWT === 1000)  ? '' : serviceData[0].IMAMOTPEXTWT
      formData.IMAMOTPEXTHT = (serviceData[0].IMAMOTPEXTHT === 999.9 || serviceData[0].IMAMOTPEXTHT === 1000) ? '' : serviceData[0].IMAMOTPEXTHT
      formData.IMAMOTPEXTWFHL = serviceData[0].IMAMOTPEXTWFHL === 999 ? '' : serviceData[0].IMAMOTPEXTWFHL
      formData.IMAMOTPEXTMUAC = (serviceData[0].IMAMOTPEXTMUAC  === 999.9 || serviceData[0].IMAMOTPEXTMUAC  === 1000) ? '' : serviceData[0].IMAMOTPEXTMUAC

      formData.IMAMOTPEXTODM = serviceData[0].IMAMOTPEXTODM === 999 ? '999' : serviceData[0].IMAMOTPEXTODM + ''

      formData.IMAMOTPLWTDATE = serviceData[0].IMAMOTPLWTDATE ? moment(serviceData[0].IMAMOTPLWTDATE).format("YYYY-MM-DD") : ''
      formData.IMAMOTPLWTCAT = serviceData[0].IMAMOTPLWTCAT === null ? '999' : serviceData[0].IMAMOTPLWTCAT + ''
      formData.IMAMOTPUSRLOGIN = serviceData[0].IMAMOTPUSRLOGIN
      formData.IMAMOTPSTATUS = '2'
      formData.IMAMOTPSYNC = serviceData[0].IMAMOTPSYNC +''
      formData.ID = serviceData[0].ID
      formData.IMAMOTPEXTMINWT = (serviceData[0].IMAMOTPEXTMINWT === 999.9 || serviceData[0].IMAMOTPEXTMINWT === 1000) ? '' : serviceData[0].IMAMOTPEXTMINWT
      formData.IMAMOTPEXTSAM = serviceData[0].IMAMOTPEXTSAM
    }
    setLoading(false)

  }, [])

  return (
    <div style={{ width: '100%', height: '180vh', background: '#fcf0f2' }}>
      <Modals open={loading} />
      {successSnack && <CustomizedSnackbars open={setSuccessSnackBarOpen} close={setSuccessSnackBarClose} alertMsg={success} type="success" />}
      {openSnack && <CustomizedSnackbars open={setSnackBarOpen} close={setSnackBarClose} alertMsg={error} type="warning" />}
      <Typography variant="h6" align="center" style={{ color: '#53344d', background: '#fcf0f2', fontWeight: 'bold', padding: '1%' }}>
        Actue Malnutrition Register</Typography>
      <CustomUnicefTable />
      <Grid container style={{ marginTop: '20px' }} >

        <Grid row container style={{ alignContent: 'center', alignItems: 'center' }}>
          <Grid item xs={12} sm={6} md={6}>
            <Grid row container style={{ marginLeft: '30%' }}>
              <Typography color="#482642">တိုင်းဒေသကြီး/ပြည်နယ် </Typography>
              <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomUnicefTextField
              variantText="filled"
              disabled
              value={divName}
              style={{ width: '80%' }} />
          </Grid>
        </Grid>

        <Grid row container style={{ alignContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
          <Grid item xs={12} sm={6} md={6}>
            <Grid row container style={{ marginLeft: '30%' }}>
              <Typography color="#482642">မြို့နယ် </Typography>
              <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomUnicefTextField
              variantText="filled"
              disabled
              value={tspName}
              style={{ width: '80%' }} />
          </Grid>
        </Grid>


        <Grid row container style={{ alignContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
          <Grid item xs={12} sm={6} md={6}>
            <Grid row container style={{ marginLeft: '30%' }}>
              <Typography color="#482642">ကျေးလက်ကျန်းမာရေးဌာနအမည် </Typography>
              <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomUnicefTextField
              select
              value={clinicCode}
              onChange={clinicHandleChange}
              variantText="filled"
              style={{ width: '80%' }}
              InputLabelProps={{
                style: { color: '#482642' },
                shrink: true
              }}
              SelectProps={{
                native: true
              }}>
              {clinicData.length &&
                clinicData.map((option) => (
                  <option key={option.CLN_ID} value={option.CLN_ID}>
                    {option.CLN_NAME}
                  </option>
                ))}
            </CustomUnicefTextField>
          </Grid>
        </Grid>

      </Grid>


      <Grid container style={{ marginTop: '20px' }} >
        <Grid row container style={{ alignContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
          <Grid item xs={12} sm={6} md={6}>
            <Grid row container style={{ marginLeft: '30%' }}>
              <Typography color="#482642">အာဟာရ မှတ်ပုံတင် အမှတ် </Typography>
              <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomUnicefTextField
              type="text"
              variantText="filled"
              disabled
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '80%' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPREGID: e.target.value })
              }}
              value={formData.IMAMOTPREGID} />
          </Grid>
        </Grid>
      </Grid>

      <Card
        variant="outlined"
        style={{
          background: "#ddd3d5",
          marginTop: '10px',
          marginLeft: '5%',
          width: '90%',
          justifyContent: 'center',
        }}>
        <Grid container style={{ marginTop: '20px' }} >
          <Grid item spacing={2} xs={12} sm={12} md={12} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
            <Typography align='center' variant="subtitle1" color="#482642" style={{ background: "#ddd3d5", fontWeight: 'bold' }} >လူနာ ရာဇ၀င်</Typography>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label={<Grid row container><Typography color="#482642" style={{ fontSize: '13px' }} >အမည်</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              type="text"
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPREGNAME: e.target.value })
              }}
              value={formData.IMAMOTPREGNAME} />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label={<Grid row container><Typography color="#482642" style={{ fontSize: '13px' }} >အသက်</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              type="number"
              inputProps={{ step: "1", min: 0, maxLength: 5 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                (e.target.value.length > 5) ? setFormData({ ...formData, IMAMOTPREGAGE: (e.target.value).slice(0, 5) })
                  : setFormData({ ...formData, IMAMOTPREGAGE: e.target.value })
              }}
              value={formData.IMAMOTPREGAGE}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <ThemeProvider theme={radioTheme}>
              <Card
                variant="outlined"
                style={{
                  background: "#fcf0f2",
                  width: '90%',
                  marginLeft: '17px',

                }}
                className={classes.cardStyle}>
                {<Grid row container style={{ justifyContent: 'center', marginTop: '4px' }}><Typography color="#482642">ကျား/မ</Typography>
                </Grid>}

                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                  onChange={e => { setFormData({ ...formData, IMAMOTPREGSEX: e.target.value }) }}
                  value={formData.IMAMOTPREGSEX}
                  row={true}
                >
                  <FormControlLabel
                    value="1"
                    labelPlacement="left"
                    label="ကျား"
                    style={{ height: "30px" }}
                    className={classes.fontSize}
                    control={<Radio size="small" color="primary"
                      onClick={sexHandleChange} onKeyDown={e => e.key === 'Enter' && sexHandleChange(e)} />}
                  />
                  <FormControlLabel
                    value="0"
                    labelPlacement="left"
                    style={{ height: "30px" }}
                    className={classes.fontSize}
                    control={<Radio size="small" color="primary"
                      onClick={sexHandleChange} onKeyDown={e => e.key === 'Enter' && sexHandleChange(e)} />}
                    label="မ"
                  />
                </RadioGroup>
              </Card>
            </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={12} style={{ alignContent: 'center', alignItems: 'center', color: '#fcf0f2' }}>
            <CustomUnicefTextField
              label='လိပ်စာ'
              type="text"
              variantText="filled"
              multiline
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '60%', marginBottom: '10px' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPREGADDRESS: e.target.value })
              }}
              value={formData.IMAMOTPREGADDRESS} />
          </Grid>




        </Grid>
      </Card>

      <Card
        variant="outlined"
        style={{
          background: "#ddd3d5",
          marginTop: '10px',
          marginLeft: '5%',
          width: '90%',
          justifyContent: 'center',
        }}>
        <Grid container style={{ marginTop: '20px', marginBottom: '20px' }} >
          <Grid item spacing={2} xs={12} sm={12} md={12} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
            <Typography align='center' variant="subtitle1" color="#482642" style={{ background: '#ddd3d5', fontWeight: 'bold' }}  >စာရင်းသွင်း ၀င်ခွင့်ပြုချိန် မှတ်တမ်း</Typography>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', alignSelf: 'center' }}>
            <CustomUnicefTextField
              variantText="filled"
              type="date"
              label={<Grid row container><Typography color="#482642" style={{ fontSize: '13px' }} >နေ့စွဲ</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              InputLabelProps={{ shrink: true }} style={{ width: '90%', backgroundColor: '#fcf0f2' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPENTYDATE: moment(e.target.value).format('YYYY-MM-DD') })
              }}
              value={formData.IMAMOTPENTYDATE} size="large" />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '90%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" style={{ fontSize: '13px' }} >စာရင်းသွင်းလူနာအုပ်စု</Typography>
                  <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.IMAMOTPENTYCAT}
                  onChange={e => { setFormData({ ...formData, IMAMOTPENTYCAT: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>လူနာသစ်များ</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>အာဟာရ ချို့တဲ့မူ ပြန်ဖြစ်သောလူနာများ</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'3'}>ပြန်လည် စာရင်းသွင်း၀င်ခွင့်ပြုသော လူနာများ</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'4'}>အမျိုးအစားတူ ကုသမူ အစီအစဥ်မှ လွှဲပြောင်းလက်ခံ</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
            <CustomUnicefTextField
              label='အလေးချိန်(kg)'
              type="number"
              inputProps={{ step: "0.1", min: 0, maxLength: 6 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                (e.target.value.length > 6) ? setFormData({ ...formData, IMAMOTPENTYWT: (e.target.value).slice(0, 6) })
                  : setFormData({ ...formData, IMAMOTPENTYWT: e.target.value })
              }}
              value={formData.IMAMOTPENTYWT}
            />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label='အရပ် (cm)'
              type="number"
              inputProps={{ step: "0.1", min: 0, maxLength: 6 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                (e.target.value.length > 6) ? setFormData({ ...formData, IMAMOTPENTYHT: (e.target.value).slice(0, 6) })
                  : setFormData({ ...formData, IMAMOTPENTYHT: e.target.value })
              }}
              value={formData.IMAMOTPENTYHT}
            />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label='WFH/WFL(z-score)'
              type="number"
              inputProps={{ step: "1", min: 0, maxLength: 6 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPENTYWFHL: e.target.value })
              }}
              value={formData.IMAMOTPENTYWFHL} />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label={<Grid row container><Typography color="#482642" style={{ fontSize: '13px' }} >MUAC (mm)</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              type="number"
              inputProps={{ step: "0.1", min: 0 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              FormHelperTextProps={{
                style: { textAlign: 'center' }

              }}
              style={{ width: '90%' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPENTYMUAC: e.target.value })
              }}
              value={formData.IMAMOTPENTYMUAC}
              helperText={(formData.IMAMOTPENTYMUAC <= 114 && formData.IMAMOTPENTYMUAC > 0) ?
                <Typography style={{ color: "#d91d4c", fontWeight: 'bold' }}  >SAM</Typography> :
                (formData.IMAMOTPENTYMUAC > 114 && formData.IMAMOTPENTYMUAC <= 124 && formData.IMAMOTPENTYMUAC > 0) ? <Typography style={{ color: "#F9D030", fontWeight: 'bold' }} >MAM</Typography> :
                  (formData.IMAMOTPENTYMUAC > 124 && formData.IMAMOTPENTYMUAC > 0) ?
                    <Typography style={{ color: "#478C5C", fontWeight: 'bold' }}  >Good</Typography> :
                    ''}
            />
          </Grid>


        </Grid>
      </Card>


      <Card
        variant="outlined"
        style={{
          background: "#ddd3d5",
          marginTop: '10px',
          marginLeft: '5%',
          width: '90%',
          justifyContent: 'center',
        }}>
        <Grid container style={{ marginTop: '20px', marginBottom: '20px' }} >
          <Grid item spacing={2} xs={12} sm={12} md={12} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
            <Typography align='center' variant="subtitle1" color="#482642" style={{ background: '#ddd3d5', fontWeight: 'bold' }}  >စာရင်းမှ နုတ်ပယ်ထွက်ခွင့်ပြုချိန် မှတ်တမ်း</Typography>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', alignSelf: 'center' }}>
            <CustomUnicefTextField
              variantText="filled"
              type="date"
              label='နေ့စွဲ'
              InputLabelProps={{ shrink: true }} style={{ width: '90%', backgroundColor: '#fcf0f2' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPEXTDATE: moment(e.target.value).format('YYYY-MM-DD') })
              }}
              value={formData.IMAMOTPEXTDATE} size="large" />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label='အလေးချိန်(kg)'
              type="number"
              inputProps={{ step: "0.1", min: 0, maxLength: 6 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                (e.target.value.length > 6) ? setFormData({ ...formData, IMAMOTPEXTWT: (e.target.value).slice(0, 6) })
                  : setFormData({ ...formData, IMAMOTPEXTWT: e.target.value })
              }}
              value={formData.IMAMOTPEXTWT}
            />
          </Grid>
          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label='အရပ် (cm)'
              type="number"
              inputProps={{ step: "0.1", min: 0, maxLength: 6 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                (e.target.value.length > 6) ? setFormData({ ...formData, IMAMOTPEXTHT: (e.target.value).slice(0, 6) })
                  : setFormData({ ...formData, IMAMOTPEXTHT: e.target.value })
              }}
              value={formData.IMAMOTPEXTHT}
            />
          </Grid>
          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label='WFH/WFL(z-score)'
              type="number"
              inputProps={{ step: "1", min: 0, maxLength: 6 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              style={{ width: '90%' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPEXTWFHL: e.target.value })
              }}
              value={formData.IMAMOTPEXTWFHL} />
          </Grid>
          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <CustomUnicefTextField
              label={<Grid row container><Typography color="#482642" style={{ fontSize: '13px' }} >MUAC (mm)</Typography>
                <Typography variant='subtitle2' style={{ color: '#d91d4c' }}>*</Typography></Grid>}
              type="number"
              inputProps={{ step: "0.1", min: 0 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' },
              }}
              FormHelperTextProps={{
                style: { textAlign: 'center' }

              }}
              style={{ width: '90%' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPEXTMUAC: e.target.value })
              }}
              value={formData.IMAMOTPEXTMUAC}
              helperText={(formData.IMAMOTPEXTMUAC <= 114 && formData.IMAMOTPEXTMUAC > 0) ?
                <Typography style={{ color: "#d91d4c", fontWeight: 'bold' }}  >SAM</Typography> :
                (formData.IMAMOTPEXTMUAC > 114 && formData.IMAMOTPEXTMUAC <= 124 && formData.IMAMOTPEXTMUAC > 0) ? <Typography style={{ color: "#F9D030", fontWeight: 'bold' }} >MAM</Typography> :
                  (formData.IMAMOTPEXTMUAC > 124 && formData.IMAMOTPEXTMUAC > 0) ?
                    <Typography style={{ color: "#478C5C", fontWeight: 'bold' }}  >Good</Typography> :
                    ''}
            />
          </Grid>
          <Grid item spacing={2} xs={12} sm={12} md={4} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
            <ThemeProvider theme={radioTheme}>
              <Card
                variant="outlined"
                style={{
                  background: "#fcf0f2",
                  width: '90%',
                  marginLeft: '25px',

                }}
                className={classes.cardStyle}>
                {<Grid row container style={{ justifyContent: 'center', marginTop: '4px' }}><Typography color="#482642">ဖောရောင်ခြင်း</Typography>
                </Grid>}

                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                  onChange={e => { setFormData({ ...formData, IMAMOTPEXTODM: e.target.value }) }}
                  value={formData.IMAMOTPEXTODM}
                  row={true}
                >
                  <FormControlLabel
                    value="0"
                    labelPlacement="left"
                    label="၀"
                    style={{ height: "30px" }}
                    className={classes.fontSize}
                    control={<Radio size="small" color="primary"
                                                                        /* onClick={lactHandleChange} onKeyDown={e => e.key === 'Enter' && lactHandleChange(e)} */ />}
                  />
                  <FormControlLabel
                    value="1"
                    labelPlacement="left"
                    style={{ height: "30px" }}
                    className={classes.fontSize}
                    control={<Radio size="small" color="primary"
                                                                        /* onClick={lactHandleChange} onKeyDown={e => e.key === 'Enter' && lactHandleChange(e)} */ />}
                    label="၁"
                  />

                  <FormControlLabel
                    value="2"
                    labelPlacement="left"
                    style={{ height: "30px" }}
                    className={classes.fontSize}
                    control={<Radio size="small" color="primary"
                                                                        /* onClick={lactHandleChange} onKeyDown={e => e.key === 'Enter' && lactHandleChange(e)} */ />}
                    label="၂"
                  />

                  <FormControlLabel
                    value="3"
                    labelPlacement="left"
                    style={{ height: "30px" }}
                    className={classes.fontSize}
                    control={<Radio size="small" color="primary"
                                                                        /* onClick={lactHandleChange} onKeyDown={e => e.key === 'Enter' && lactHandleChange(e)} */ />}
                    label="၃"
                  />
                </RadioGroup>
              </Card>
            </ThemeProvider>


          </Grid>

        </Grid>
      </Card>

      <Card
        variant="outlined"
        style={{
          background: "#ddd3d5",
          marginTop: '10px',
          marginLeft: '5%',
          width: '90%',
          justifyContent: 'center',
        }}>
        <Grid container style={{ marginTop: '10px', marginBottom: '15px' }} >
          <Grid item spacing={2} xs={12} sm={12} md={12} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
            <Typography align='center' variant="subtitle1" color="#482642" style={{ background: '#ddd3d5', fontWeight: 'bold' }}  >အနိမ့်ဆုံး ကိုယ်အလေးချိန်</Typography>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '10px', alignSelf: 'center' }}>
            <ThemeProvider theme={radioTheme}>
              <CustomUnicefTextField
                variantText="filled"
                type="date"
                label='နေ့စွဲ'
                InputLabelProps={{ shrink: true }} style={{ width: '90%', backgroundColor: '#fcf0f2' }}
                onChange={e => {
                  setFormData({ ...formData, IMAMOTPLWTDATE: moment(e.target.value).format('YYYY-MM-DD') })
                }}
                value={formData.IMAMOTPLWTDATE} size="large" /> </ThemeProvider>
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={3} style={{ alignContent: 'center', alignItems: 'center', marginTop: '11px' }}>
            <CustomUnicefTextField
              label='အလေးချိန်(kg)'
              type="number"
              inputProps={{ step: "0.1", min: 0 }}
              variantText="filled"
              InputLabelProps={{
                style: { color: '#482642', textAlign: 'center' }
              }}
              FormHelperTextProps={{
                style: { textAlign: 'center' }

              }}
              style={{ width: '98%' }}
              onChange={e => {
                setFormData({ ...formData, IMAMOTPEXTMINWT: e.target.value })
              }}
              value={formData.IMAMOTPEXTMINWT}

            />
          </Grid>

          <Grid item spacing={2} xs={12} sm={12} md={6} style={{ alignContent: 'center', alignItems: 'center', marginBottom: '15px', marginTop: '2px' }}>
            <ThemeProvider theme={radioTheme}>
              <FormControl variant="filled" className={classes.secondFormControl} style={{ width: '90%', backgroundColor: 'white' }}>
                <InputLabel id="demo-simple-select-filled-label">{<Grid row container><Typography color="#482642" style={{ fontSize: '13px' }} >နုတ်ပယ်ထွက်ခွင့် ပြုသော အကြောင်းအရာ</Typography>
                </Grid>}</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={formData.IMAMOTPLWTCAT}
                  onChange={e => { setFormData({ ...formData, IMAMOTPLWTCAT: e.target.value }) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    style: {
                      maxHeight: 300,
                    },
                    getContentAnchorEl: null,
                  }}>
                  <MenuItem classes={{ selected: classes.selected }} value={'999'}>-</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'1'}>ကုသပျောက်ကင်းသူများ</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'2'}>ပျောက်ကင်းအောင် မကုပဲ ပျက်ကွက်သူများ</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'3'}>သေဆုံးသူများ</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'4'}> နာလန်မထူ သူများ</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'5'}>အခြား အစီအစဥ်သို့ လွှဲပြောင်း ပေးလိုက်သူများ</MenuItem>
                  <MenuItem classes={{ selected: classes.selected }} value={'6'}> လွှဲပြောင်းစေလွှတ်သော လူနာများ (အခြားအမျိုးအစားတူ ကုသမူ အစီအစဥ်သို့ လွှဲပြောင်း စေလွှတ်သော လူနာများ)</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </Grid>


        </Grid>
      </Card>

      <Grid container spacing={4} alignItems="center" justifyContent="center" style={{ paddingTop: '25px', background: '#fcf0f2' }} row>
        <Grid item xs={'auto'} style={{ width: '15%' }}>
          <Button
            variant="contained"
            style={{ background: '#482642', color: '#fff', width: '100%' }}
            onClick={save} >Update</Button>
        </Grid>
        <Grid item xs={'auto'} style={{ width: '15%' }}>
          <Button
            variant="contained"
            style={{ background: '#482642', color: '#fff', width: '100%' }}
            onClick={cancle} >Cancel</Button>
        </Grid>

      </Grid>
      <Typography variant="body1" align="center" style={{ color: 'gray', padding: '0.5%', background: '#fcf0f2' }}>
     Copyright © HMIS Facility Base System 2018-{new Date().getFullYear()}. </Typography>
    </div>
  )
}